// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.utils_header-style__o16Ig {
  margin-top: 0px !important;
  font-weight: normal !important;
  text-align: left !important;
}

.utils_header-one__mWFMj {
  font-size: 24px;
}

.utils_header-two__aJvA8 {
  font-size: 20px !important;
}

.utils_header-three__ULmYv {
  font-size: 16px !important;
}

.utils_header-four__VlHBf {
  font-size: 14px !important;
}

.utils_header-five__lCVVy {
  font-size: 12px !important;
}

.utils_header-six__vH7jt {
  font-size: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/utils/utils.module.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,8BAAA;EACA,2BAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,0BAAA;AACJ","sourcesContent":[".header-style {\n    margin-top: 0px !important;\n    font-weight: normal !important;\n    text-align: left !important;\n}\n\n.header-one {\n    font-size: 24px;\n}\n\n.header-two {\n    font-size: 20px !important;\n}\n\n.header-three {\n    font-size: 16px !important;\n}\n\n.header-four {\n    font-size: 14px !important;\n}\n\n.header-five {\n    font-size: 12px !important;\n}\n\n.header-six {\n    font-size: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-style": `utils_header-style__o16Ig`,
	"header-one": `utils_header-one__mWFMj`,
	"header-two": `utils_header-two__aJvA8`,
	"header-three": `utils_header-three__ULmYv`,
	"header-four": `utils_header-four__VlHBf`,
	"header-five": `utils_header-five__lCVVy`,
	"header-six": `utils_header-six__vH7jt`
};
export default ___CSS_LOADER_EXPORT___;
