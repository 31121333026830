// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner_banner-container__JvnId {
  display: grid !important;
  background-repeat: no-repeat !important;
  background-size: cover;
  width: 100% !important;
  max-width: 100% !important;
  height: 200px;
}

.banner_padding-stack-xl__JXW6w {
  padding: 1% 12.5% 1% 12.5%;
}

.banner_padding-stack-not-xl__V8jvI {
  padding: 1% 0% 1% 0%;
}

.banner_padding-stack__lYcPp {
  padding: 1% 3% 1% 3%;
}

.banner_align-image-right__RgEFf {
  display: flex;
  justify-content: end;
}

.banner_image__eR7D7 {
  width: 90%;
}

.banner_image-variety-2__Bvbh\\+ {
  width: 300px;
  height: 200px;
  padding: 21px 0;
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/SolutionSearch/ListingProducts/Banner/banner.module.scss"],"names":[],"mappings":"AAEA;EACE,wBAAA;EACA,uCAAA;EACA,sBAAA;EACA,sBAAA;EACA,0BAAA;EACA,aAAA;AAGF;;AADA;EACE,0BAAA;AAIF;;AAFA;EACE,oBAAA;AAKF;;AAHA;EACE,oBAAA;AAMF;;AAJA;EACE,aAAA;EACA,oBAAA;AAOF;;AALA;EACE,UAAA;AAQF;;AANA;EACE,YAAA;EACA,aAAA;EACA,eAAA;EACA,YAAA;AASF","sourcesContent":["@import '../../../../variables.module.scss';\n\n.banner-container {\n  display: grid !important;\n  background-repeat: no-repeat !important;\n  background-size: cover;\n  width: 100% !important;\n  max-width: 100% !important;\n  height: 200px;\n}\n.padding-stack-xl {\n  padding: 1% 12.5% 1% 12.5%;\n}\n.padding-stack-not-xl {\n  padding: 1% 0% 1% 0%;\n}\n.padding-stack {\n  padding: 1% 3% 1% 3%;\n}\n.align-image-right {\n  display: flex;\n  justify-content: end;\n}\n.image {\n  width: 90%;\n}\n.image-variety-2 {\n  width: 300px;\n  height: 200px;\n  padding: 21px 0;\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"banner-container": `banner_banner-container__JvnId`,
	"padding-stack-xl": `banner_padding-stack-xl__JXW6w`,
	"padding-stack-not-xl": `banner_padding-stack-not-xl__V8jvI`,
	"padding-stack": `banner_padding-stack__lYcPp`,
	"align-image-right": `banner_align-image-right__RgEFf`,
	"image": `banner_image__eR7D7`,
	"image-variety-2": `banner_image-variety-2__Bvbh+`
};
export default ___CSS_LOADER_EXPORT___;
