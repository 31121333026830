const initialState = {
  orderStatus: [],
  orderDescription: {},
  statusChange: 0,
  comments: [],
  isLoading: false,
  imageUrl: '',
  billingAddress: {},
  errorState: false,
  postComment: 0,
};

const OrderFulfillmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDER_STATUS':
      return { ...state, orderStatus: action.payload };
    case 'GET_ORDER_SUMMARY':
      return { ...state, orderDescription: action.payload };
    case 'CHANGE_ORDER_STATUS':
      return { ...state, statusChange: state.statusChange + 1 };
    case 'GET_COMMENTS':
      return { ...state, comments: action.payload };
    case 'POST_COMMENT':
      return { ...state, postComment: state.postComment + action.payload };
    case 'SET_LOADER':
      return { ...state, isLoading: action.payload };
    case 'SET_BILLING_ADDRESS':
      return { ...state, billingAddress: action.payload };
    case 'CHANGE_ERROR_STATE':
      return { ...state, errorState: action.payload };
    default:
      return state;
  }
};

export default OrderFulfillmentReducer;
