import axios from 'axios';
import { CART_ACTION } from './configuration';

export const updateCartProduct = (payload) => {
  return (dispatch) =>
    axios({
      method: 'PUT',
      url: CART_ACTION.PRODUCT_CART,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    })
      .then((response) => {
        dispatch({
          type: 'ADD_OR_REMOVE_CART_QUANTITY',
          payload: payload
        });
        
        /* Fetch and update the cart items whenever the cart items is getting added, updated or deleted (Includes quantity) */
        if (payload.quantity === 0) {
          // Argument should be true to update the pricing details as cart item is deleted
          dispatch(getCartProduct(true));
        } else if(payload.isGetCartCount) {
          dispatch(getCartProduct(false));
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};

export const getCartProduct = (isFetchPricingDetails) => {
  return (dispatch) => {
    dispatch({
      type: 'CART_SHOW_LOADER',
      payload: true
    });
    return axios({
      method: 'GET',
      url: CART_ACTION.PRODUCT_CART,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    })
      .then((response) => {
        let productIds = '';
        let productArr = [];
        response?.data?.forEach((cartObj) => {
          cartObj.items?.forEach((product) => {
            productIds += `${product.productId},`;
            productArr.push({
              productId: product.productId,
              languageId: null
            });
          });
        });

        dispatch({
          type: 'GET_CART',
          payload: {
            data: response.data,
            count: response?.headers['total-entry-count']
          }
        });

        // Pricing Details API will be called if isFetchPricingDetails is true.
        if (response?.data?.length > 0) {

          if(!isFetchPricingDetails) return;

          Promise.all([
            dispatch(getPricingDetails(productArr))
          ]).then((responses) => {
            if (responses?.length > 0) {
              dispatch({
                type: 'CART_HIDE_LOADER',
                payload: false
              });
              dispatch({
                type: 'GET_CART',
                payload: {
                  data: response.data,
                  count: response?.headers['total-entry-count']
                }
              });
              dispatch({
                type: 'PRICING_DETAILS',
                payload: responses[0]
              });
            }
          });
        } else {
          dispatch({
            type: 'RESET_CART'
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        dispatch({
          type: 'CART_HIDE_LOADER',
          payload: false
        });
      });
  };
};

export const getPricingDetails = (payload) => {
  return (dispatch) =>
    axios({
      method: 'POST',
      url: CART_ACTION.PRICING_DETAILS,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    })
      .then((response) => {
        dispatch({
          type: 'PRICING_DETAILS',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};

export const resetCart = () => {
  return (dispatch) =>
    dispatch({
      type: 'RESET_CART'
    });
};

export const getMinimumOrderQuantity = (productId) => {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: CART_ACTION.GET_MINIMUM_ORDER_QUANTITY + productId,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    })
      .then((response) => {
        dispatch({
          type: 'GET_CART_PRODUCT_QUANTITY',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};
