import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Box, Modal, Grid, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import Loader from '../../Loader';
import i18next from 'i18next';
import { appTheme } from '../../../../../themes/appTheme';

const useStyles = () => ({
    typoStyle: {
        float: 'right'
    },
    divStyle: {
        position: 'absolute',
        backgroundColor: appTheme.palette.common.light,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '2rem'
    },
    okButtonStyle: {
        whiteSpace: 'nowrap !important',
        marginRight: '1em !important'
    },
    textSize: {
        fontSize: '0.9em',
        whiteSpace: 'nowrap !important',
        width: '100% !important'
    },
    iconStyle: {
        marginRight: '1rem',
        fontSize: '2rem',
        marginBottom: '-4px'
    },
    noAutoCapitalization: {
        '&.makeStyles-label-125': {
            whiteSpace: 'nowrap !important'
        }
    },
    alignRight: {
        justifyContent: 'flex-end'
    }
});

class ConfirmModal extends Component {

    constructor() {
        super();
        this.state = {
            open: true
        };
    }

    handleClose = () => {
        if (this.props.heading.includes(i18next.t('SNACKBAR.CANNOT_PLACE_ORDER'))) {
            if (!this.props?.location?.pathname.includes('/overview/') && !this.props?.location?.pathname.includes('/cart'))
                this.props.history.goBack();
        }
        this.setState({ open: false });
        if (this.props.plan || this.props.modelRef === 'payPerCallUnSubscribe') {
            this.props.handleCancelConfirmation();
        } else {
            this.props.callback();
        }
    }

    handleSuccess = () => {
        this.setState({ open: false });
        if (this.props.heading.includes(i18next.t('SNACKBAR.CANNOT_PLACE_ORDER'))) {
            if (!this.props?.location?.pathname.includes('/overview/') && !this.props?.location?.pathname.includes('/cart'))
                this.props.history.goBack();
        }
        if (this.props.modelRef === 'payPerCallUnSubscribe') {
            this.props.handleUnSubscribe();
        } else if (this.props.plan) {
            this.props.handleSubscribe(this.props.plan, true);
        } else {
            if (this.props.cancelBtn !== 'disable') {
                this.props.okOperation(this.props.index, this.props.type);
            }
            this.props.callback();
        }
    }
    render() {
        const { classes, dialogPosition } = this.props;
        let dropdownWrapper, currentFormat, dropdownComponent;
        (this.props.hasOwnProperty('dropdownWrapper')) ? (dropdownWrapper = this.props.dropdownWrapper) : (dropdownWrapper = {});
        (this.props.hasOwnProperty('currentFormat')) ? (currentFormat = this.props.currentFormat) : (currentFormat = '');
        (this.props.hasOwnProperty('dropdownComponent')) ? (dropdownComponent = this.props.dropdownComponent) : (dropdownComponent = false);

        return (
            <React.Fragment>

                <Modal
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            this.handleClose();
                        }
                    }}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                    open={this.state.open}
                    disableEscapeKeyDown={this.props.disableEscapeKeyDown}>
                    <Box className={`${classes.divStyle} ${this?.props?.responsive && 'modal-responsive'}`}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Box mb={2}>
                                    {this.props.category === 'warning' && <WarningIcon sx={{color : 'warning.main'}} className={classes.iconStyle} />}
                                    <Typography component='span' variant='mainTitleLight'>{this.props.heading}</Typography>
                                </Box>
                            </Grid>
                            {
                                !this.props.noCloseIcon &&
                                <Grid item xs={1}>
                                    <IconButton onClick={this.handleClose} size='large'><CloseIcon size='small' /></IconButton>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Box className={dropdownWrapper}>{dropdownComponent} </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='modalSubTitleLight'>{this.props.modalContent} </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <Typography variant='modalSubTitleLight'>{this.props.modalContent2} </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant='modalSubTitleLight'>{this.props.modalContent3} </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.alignRight}>
                            <Grid item sm>
                                <Box
                                    mt={1} display='flex'
                                    justifyContent={dialogPosition ? dialogPosition : 'flex-end'}
                                    className={classes.alignRight}
                                >
                                    {
                                        this.props.deletionOfAccounts ?
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Button fullWidth variant='filledBtn' className={`${classes.okButtonStyle} ${classes.textSize} ${this.props.noAutoCapitalization && classes.noAutoCapitalization}`} onClick={this.props.handleDeleteMSaccount}>{this.props.deleteMSaccountButton}</Button>
                                                </Grid>
                                                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Button fullWidth variant='borderedBtn' className={`${classes.textSize} ${this.props.noAutoCapitalization && classes.noAutoCapitalization}`} onClick={this.props.handleDeleteCIAMaccount}>{this.props.deleteCIAMaccount}</Button>
                                                </Grid> */}
                                            </Grid>
                                            :
                                            <>
                                                <Button variant='filledBtn' sx={{minWidth: '114px'}} className={`${classes.okButtonStyle} ${this.props.noAutoCapitalization && classes.noAutoCapitalization}`} onClick={this.handleSuccess} disabled={dropdownComponent && currentFormat.length === 0 ? (true) : (false)}>{this.props.submitLabel ? this.props.submitLabel : i18next.t('SHARED.OK')}</Button>
                                                {this.props.cancelBtn !== 'disable' && <Button variant='borderedBtn' className={this.props.noAutoCapitalization && classes.noAutoCapitalization} onClick={this.handleClose}>{this.props.cancelLabel ? this.props.cancelLabel : 'Cancel'}</Button>}
                                            </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Modal>
                {this.props.confirmationModalLoader && <Loader />}
            </React.Fragment>
        );
    }
}
export default withStyles(useStyles)(ConfirmModal);