// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cost-center-details_cost-center-popup__OwfcW {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none !important;
  z-index: 1001;
  min-width: 50%;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  overflow-y: auto;
  padding: 32px;
}

.cost-center-details_cost-center-popup__OwfcW .cost-center-details_cost-center-popup-close-btn__etEkZ {
  position: absolute;
  top: 25px;
  right: 16px;
}

@media only screen and (max-width: 600px) {
  .cost-center-details_cost-center-popup__OwfcW {
    min-width: 80%;
  }
}
@media only screen and (max-width: 300px) {
  .cost-center-details_cost-center-popup__OwfcW {
    min-width: 95%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/CostCenterDetails/cost-center-details.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,wBAAA;EACA,aAAA;EACA,cAAA;EACA,+BAAA;EAAA,4BAAA;EAAA,uBAAA;EACA,gBAAA;EACA,aAAA;AACJ;;AAGA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;AAAJ;;AAGA;EACC;IACC,cAAA;EAAA;AACF;AAEA;EACC;IACC,cAAA;EAAA;AACF","sourcesContent":[".cost-center-popup{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%,-50%);\n    outline: none !important;\n    z-index: 1001;\n    min-width: 50%;\n    min-height: fit-content;\n    overflow-y: auto;\n    padding:32px;\n    \n}\n\n.cost-center-popup .cost-center-popup-close-btn{\n    position: absolute;\n    top: 25px;\n    right: 16px;\n}\n\n@media only screen and (max-width: 600px) { \n\t.cost-center-popup {\n\t\tmin-width: 80%;\n\t}\n}\n@media only screen and (max-width: 300px) { \n\t.cost-center-popup {\n\t\tmin-width: 95%;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cost-center-popup": `cost-center-details_cost-center-popup__OwfcW`,
	"cost-center-popup-close-btn": `cost-center-details_cost-center-popup-close-btn__etEkZ`
};
export default ___CSS_LOADER_EXPORT___;
