import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { Grid, Typography, Button, Container, Stack, Box } from '@mui/material';
import i18next from 'i18next';
import styles from './listing-products.module.scss';
import { useHistory } from 'react-router-dom';
import { portalViewData, assetType, loadMoreConfigValue } from '../../../data/appConstants';
import MsCard from '../../Shared/ProductCard';
import Loader from '../../Shared/Utils/Loader';
import ConfirmModal from '../../Shared/Utils/Modal/ConfirmModal';
import idConstants from '../../../data/idConstants';
import Images from '../../../assets/img';
import Carousel from 'react-material-ui-carousel';
import SnackBar from '../../Shared/Utils/SnackBar';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import {
  getFilteredProductsListing,
} from '../../../actions/LandingAction';
import handleAAASignupLogin from '../../../actions/LoginAction';
import isEmpty from '../../../validator/isEmpty';

function ListingProducts(props) {
  const previousPropsReference = useRef(props);
  const [filtersSelectedPropsChanged, setFiltersSelectedPropsChanged] = useState(false);
  const [productsArray, setProductsArray] = useState([]);
  const [queryParams, setQueryParams] = useState('');
  const [productsOriginalArray, setProductsOriginalArray] = useState([]);
  const [showNoProduct, setShowNoProduct] = useState(false);
  const [sortOrder, setSortOrder] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDone, setSortDone] = useState(false);
  const [onLoad, setOnLoad] = useState(false);
  const [error, setError] = useState(null);
  const [revisedLoadMoreConfigValue, setRevisedLoadMoreConfigValue] = useState(loadMoreConfigValue);
  const history = useHistory();
  const { accessToken, userCompanyStatus } = useSelector((state) => {
    return {
      accessToken: state.loginReducer.accessToken,
      userCompanyStatus: state.CompanyDetailsReducer.userCompanyStatus
    };
  });

  const [showPopupMessage, setShowPopupMessage] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  function addQuote() {
    if (!accessToken) {
      handleAAASignupLogin();
      return;
    }

    //Get Quote Scenario 2
    if (
      userCompanyStatus?.companyRegisteredStatus !== 'approved' &&
      userCompanyStatus?.areCompanyDetailsCaptured === false
    ) {
      setShowPopupMessage(i18next.t('SNACKBAR.CANNOT_CREATE_QUOTE'));
      setShowConfirmModal(true);
      return;
    }

    if (
      userCompanyStatus?.companyRegisteredStatus !== 'approved' &&
      userCompanyStatus?.areCompanyDetailsCaptured === true
    ) {
      setShowPopupMessage(
        i18next.t('SNACKBAR.CANNOT_CREATE_QUOTE_POST_COMPANY_FORM')
      );
      setShowConfirmModal(true);
      return;
    }

    history.push({
      pathname: '/listing/add-quote',
      state: {
        productSubCategoryId: selectedSubcategory[0]
      }
    });
  }
  
  const errorCallback = (value) => {
    setError(value);
  };

  const resetError = () => {
    setError(null);
  };

  useEffect(() => {
    productsArray?.length &&
      !props?.showIndicators &&
      setRevisedLoadMoreConfigValue(
        Math.max(...productsArray?.map((item) => Number(item.totalCount)))
      );
  }, [productsArray, props?.showIndicators]);

  useEffect(() => {
    if (
      previousPropsReference?.current?.filtersSelected !==
      props?.filtersSelected
    )
      setFiltersSelectedPropsChanged(true);
  }, [props?.filtersSelected]);

  useEffect(() => {
    props?.showIndicators && setRevisedLoadMoreConfigValue(loadMoreConfigValue);
  }, [props?.showIndicators]);

  useEffect(() => {
    setOnLoad(false);
    setProductsArray([]);
    window.location.pathname === '/listing' &&
      localStorage.setItem('searchParams', window?.location?.search);
  }, []);

  const populateCards = (cardArray, categoryName) => {
    const cards = cardArray?.map((card) => {
      const thumbnailImgUrl =
        card?.docTypeToDocMetaInfoListMap &&
          card.docTypeToDocMetaInfoListMap[idConstants.documentType.thumbnailImg]
          ? card.docTypeToDocMetaInfoListMap[
            idConstants.documentType.thumbnailImg
          ][0].blobFileUrl
          : card?.productCategory.categoryId === idConstants.api.categoryId
            ? Images.api.thumbnail
            : card?.productCategory.categoryId ===
              idConstants.enterpriseLicensing.categoryId
              ? Images.services.enterpriseLicensing.thumbnail
              : card?.productCategory.categoryId ===
                idConstants.managedServices.categoryId
                ? Images.services.managedServices.thumbnail
                : card?.productCategory.categoryId ===
                  idConstants.cloudInfrastructure.categoryId
                  ? Images.services.cloudInfrastructure.thumbnail
                  : card?.productCategory.categoryId ===
                    idConstants.iotSoftwareServices.categoryId
                    ? Images.services.iotSoftwareServices.thumbnail
                    : card?.productCategory.categoryId ===
                      idConstants.professionalServices.categoryId
                      ? Images.services.professionalServices.thumbnail
                      : card?.productCategory.categoryId ===
                        idConstants.communicationServices.categoryId
                        ? Images.services.communicationServices.thumbnail
                        : card?.productCategory.categoryId ===
                          idConstants.solutions.categoryId
                          ? Images.solutions.thumbnail
                          : card?.productCategory.categoryId === idConstants.data.categoryId
                            ? Images.data.thumbnail
                            : card?.productCategory.categoryId === idConstants.hardware.categoryId
                              ? Images.hardware.thumbnail
                              : card?.productCategory.categoryId === idConstants.sdk.categoryId
                                ? Images.services.sdk.thumbnail
                                : card?.productCategory.categoryId === idConstants.saas.categoryId
                                  ? Images.tools.saas.thumbnail
                                  : card?.productCategory.categoryId === idConstants.paas.categoryId
                                    ? Images.tools.paas.thumbnail
                                    : card?.productCategory.categoryId === idConstants.iaas.categoryId
                                      ? Images.tools.iaas.thumbnail
                                      : card?.productCategory.categoryId === idConstants.preDefinedWorkflow.categoryId
                                        ? Images.tools.preDefinedWorkflow.thumbnail
                                        : card?.productCategory.categoryId === idConstants.customWorkflow.categoryId
                                          ? Images.tools.customWorkflow.thumbnail
                                          : card?.productCategory.categoryId === idConstants.methods.categoryId
                                            ? Images.tools.methods.thumbnail
                                            : '';

      return (
        <>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={props?.isCombinedListingPage ? 4 : 3}
            xl={4}
            key={card?.id}
          >
            <MsCard
              key={card?.id}
              card={card}
              categoryName={categoryName}
              filteredOptions={''}
              searchText={''}
              sortBy={''}
              sortOrder={''}
              sortVal={''}
              cardTitle={card?.productName}
              subTitle={card?.subtitle}
              desc={card?.productShortDesc}
              sellerName={card?.providerName}
              categoryId={card?.productCategory?.categoryId}
              thumbnailURL={thumbnailImgUrl}
              purchaseBenefit={card?.purchaseBenefit}
              className={`${styles['search-result-card']} ${styles['no-box-shadow']}`}
            ></MsCard>
          </Grid>
        </>
      );
    });
    return (
      <>
        {props.portalView === portalViewData.MOBILE ? (
          <>
            <Carousel
              className={styles['product-cards-carousel']}
              autoPlay={false}
              indicators={props?.showIndicators}
              animation='slide'
              navButtonsAlwaysVisible={true}
              navButtonsWrapperProps={{
                className: `${!props?.showIndicators && styles['top-margin-buttons']
                  } ${styles['carousel-buttons-wrapper']}`
              }}
              NavButton={({ onClick, next, prev }) => {
                return (
                  <Button onClick={onClick} sx={{ color: 'common.dark' }} className={styles['carousel-buttons']}>
                    {next && <ArrowForwardIosOutlinedIcon />}
                    {prev && <ArrowBackIosNewOutlinedIcon />}
                  </Button>
                );
              }}
            >
              {cards}
            </Carousel>
          </>
        ) : (
          <>{cards}</>
        )}
      </>
    );
  };

  const handleLoadMore = async (item) => {
    setOnLoad(true);
    let data = await getFilteredProductsListing('', item.categoryId, sortBy, sortOrder, queryParams, props, item.page + 1, item.size, errorCallback);
    let tempCategories = productsArray.map(item => ({ ...item }));
    productsArray.forEach((category, index) => {
      if (category.categoryId === item.categoryId) {
        tempCategories[index].products =
          tempCategories[index].products.concat(data.products);
        tempCategories[index].page =
          tempCategories[index].page + 1;
        tempCategories[index].index = assetType.findIndex(asset => asset.categoryId === tempCategories[index].categoryId);
      }
    });
    setProductsArray(tempCategories.sort((category1, category2) => category1.index - category2.index));
  };

  useEffect(() => {
    setOnLoad(true);
    if (props?.sortOrder)
      setSortOrder(props?.sortOrder);
    if (props?.sortBy)
      setSortBy(props?.sortBy);
  }, [props?.sortOrder, props?.sortBy]);

  useEffect(() => {
    let index = 0;
    let finalArray = [];
    setOnLoad(false);
    if ((props?.productCountsList?.length && Object.values(props?.filtersSelected).every(item => item === ''))) {
      props?.productCountsList.map(async (category) => {
        let data = await getFilteredProductsListing('', category.categoryId, sortBy, sortOrder, '', props, 0, revisedLoadMoreConfigValue, errorCallback);
        let tempArray = {};
        tempArray.heading = category?.productCategoryName;
        tempArray.categoryId = category?.categoryId;
        tempArray.products = data?.products;
        tempArray.page = 0;
        tempArray.size = revisedLoadMoreConfigValue;
        tempArray.totalCount = props?.publishedProductsCounts?.counts[category?.categoryId];
        tempArray.index = assetType.findIndex(asset => asset.categoryId === tempArray.categoryId);
        finalArray.push(tempArray);
        if (index === props?.productCountsList?.length - 1) {
          if (window?.location?.pathname === '/listing' && window?.location?.search === '' && localStorage?.searchParams === '') {
            setProductsArray(finalArray.sort((category1, category2) => category1.heading.localeCompare(category2.heading)));
          }
          else if (window?.location?.pathname !== '/listing' && filtersSelectedPropsChanged) {
            setProductsArray(finalArray.sort((category1, category2) => category1.heading.localeCompare(category2.heading)));
          }
          setProductsOriginalArray(finalArray.sort((category1, category2) => category1.heading.localeCompare(category2.heading)));
        }
        index = index + 1;
      });
    }
  }, [props?.productCountsList, sortBy, sortOrder, sortDone, filtersSelectedPropsChanged, revisedLoadMoreConfigValue]);

  useEffect(() => {
    let filterItems = [];
    let queryParams = '';
    if (props?.filtersSelected && Object.keys(props?.filtersSelected).length > 0 && Object.values(props?.filtersSelected).some(item => item !== '')) {
      for (const [key, value] of Object.entries(props?.filtersSelected)) {
        if (key === i18next.t('FILTER_SECTION.ASSET_TYPE') && value) {
          filterItems = value.split(',');
        } else {
          let modifiedKey = '';
          if (key === i18next.t('FILTER_SECTION.USE_CASE'))
            modifiedKey = 'businessCategory';
          if (key === i18next.t('FILTER_SECTION.CATEGORY'))
            modifiedKey = 'subcategory';
          if (value)
            queryParams =
              queryParams + modifiedKey + '=' + value + '&';
        }
      }
      if (filterItems?.length === 0) {
        filterItems = assetType?.map(item => item.categoryId);
      }
      setQueryParams(queryParams);
      let finalArray = [];
      let index = 0;
      filterItems.map(async (filteredItem) => {
        let data = await getFilteredProductsListing('', filteredItem, sortBy, sortOrder, queryParams, props, 0, revisedLoadMoreConfigValue, errorCallback);
        if (data?.counts) {
          let tempArray = {};
          tempArray.heading = props?.productCategories?.filter(item => item.categoryId === filteredItem)[0]?.productCategoryName ? props?.productCategories?.filter(item => item.categoryId === filteredItem)[0]?.productCategoryName : '';
          tempArray.categoryId = filteredItem;
          tempArray.products = data.products;
          tempArray.totalCount = data.counts[filteredItem];
          tempArray.page = 0;
          tempArray.size = revisedLoadMoreConfigValue;
          finalArray.push(tempArray);
          if (index === filterItems.length - 1) {
            setProductsArray(finalArray.sort((category1, category2) => category1.index - category2.index));
            setShowNoProduct(false);
            props?.setShowNoProduct(false);
          }
          index = index + 1;
        } else {
          if (index === filterItems.length - 1 && finalArray.length === 0) {
            setShowNoProduct(true);
            props?.setShowNoProduct(true);
          } else {
            if (index === filterItems.length - 1 && finalArray.length !== 0) setProductsArray(finalArray.sort((category1, category2) => category1.index - category2.index));
            setShowNoProduct(false);
            props?.setShowNoProduct(false);
          }
          index = index + 1;
        }
        return true;
      });
    } else {
      setQueryParams('');
      if (sortBy || sortOrder) {
        setSortDone(!sortDone);
      } else {
        setProductsArray(productsOriginalArray);
      }
      setShowNoProduct(false);
      props?.setShowNoProduct(false);
    }
  }, [props?.filtersSelected, sortBy, sortOrder, props?.productCategories, revisedLoadMoreConfigValue]);

  useEffect(() => {
    if ((!isEmpty(props?.filtersSelected) && productsArray.length === 1 && !props?.loader?.loader) || sortBy || sortOrder) {
      setOnLoad(true);
    }
    else {
      setOnLoad(false);
    }
  }, [props?.filtersSelected, sortBy, sortOrder]);

  
let selectedSubcategory = props?.filtersSelected?.Category;
if(selectedSubcategory?.length > 0) {
  selectedSubcategory = selectedSubcategory.split(',');
}

  return (
    <>
      {props?.loader?.loader && <Loader />}
      {showNoProduct && (

        <Stack direction={'column'}>
          <Box component='img' className={styles['no-results-image']} src={Images.noResultsFound} alt={i18next.t('FILTER_SECTION.NO_RESULTS_FOUND')} />
          <Typography
            component='h5'
            variant='h5'
            align='center'
            pb={8}
          >
            {i18next.t('FILTER_SECTION.NO_RESULTS_FOUND')}!
          </Typography>

        </Stack>


      )}
      {!showNoProduct && productsArray && (onLoad ? true : !props?.loader?.loader) &&
        productsArray.map((item) => {
          return (
            <Box
              sx={{ backgroundColor: 'secondaryBackground.bgBody' }}
              className={styles['product-category-view']}
            >
              <Container className={styles['search-result-view-container']}>
                <Box>
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                    pt={2}
                    pb={5}
                  >
                    <Grid item xs='auto' sx={{ display: 'flex' }}>
                      <Typography
                        component='h5'
                        variant='h5'
                        sx={{ marginBottom: '0px' }}
                      >
                        {item.heading}
                        {item.categoryId === idConstants.api.categoryId && 's'}
                        {' (' + item.totalCount + ')'}
                      </Typography>
                      <br />
                    </Grid>
                    {item.categoryId ===
                      idConstants.professionalServices.categoryId &&
                      selectedSubcategory?.length === 1 && (
                        <Button
                          data-testid={i18next.t('SHARED.GET_QUOTE')}
                          color='primary'
                          variant='filledBtn'
                          fontSize='small'
                          className={`${styles['btn-style']}`}
                          onClick={() => {
                            addQuote();
                          }}
                        >
                          {i18next.t('SHARED.GET_QUOTE')}
                        </Button>
                      )}
                  </Grid>
                  <Grid item xs={12} className={styles['card-align']}>
                    <Grid
                      container
                      spacing={
                        props.portalView === portalViewData.MOBILE ? 4 : 3
                      }
                      className={styles['card-align-body']}
                    >
                      {populateCards(item.products, item.heading)}
                    </Grid>
                  </Grid>
                  <Box sx={{ marginBottom: '20px !important' }}>
                    {item?.products?.length < item?.totalCount && (
                      <Grid container justifyContent='flex-end'>
                        {props.portalView === portalViewData.MOBILE ? (
                          <Grid item>
                            <Button
                              variant='borderedBtn'
                              className={styles['view-all-button']}
                              onClick={() => props?.setShowIndicators(false)}
                            >
                              {i18next.t('FILTER_SECTION.VIEW_ALL')}
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item>
                            <Button
                              variant='borderedBtn'
                              sx={{ backgroundColor: 'common.light' }}
                              className={styles['load-more-button']}
                              onClick={() => handleLoadMore(item)}
                            >
                              {i18next.t('SHARED.LOAD_MORE')}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Container>
            </Box>
          );
        })}
      {
        (error) && <SnackBar
          severity='error'
          changeState={() => resetError()}
          message={error}
        />
      }
      {showConfirmModal && (
        <ConfirmModal
            modalContent=''
            heading={showPopupMessage}
            category={'approve'}
            callback={() => { setShowConfirmModal(false); }}
            okOperation={() => { setShowConfirmModal(false); }}
            cancelBtn='disable'
        />
    )}
    </>
  );
}

const mapStateToProps = (redux_state) => ({
  loader: redux_state.LoaderReducer,
  productCategories: redux_state.LandingReducer.productCategories,
  publishedProductsCounts: redux_state.LandingReducer.publishedProductsCounts,
  portalView: redux_state.LandingReducer.portalView,
});

export default connect(
  mapStateToProps
)(ListingProducts);