import axios from 'axios';
import * as Constants from '../actions/configuration';

export const isObjectEmpty = (obj) => {
	for (var i in obj) {
		return false;
	}
	return true;
};

export const checkIfUserHasBuyerRole = (userProp) => {
	let hasBuyerRole = false;
	if (userProp) {
		hasBuyerRole = userProp.roles?.includes(Constants.AAA_CONFIG.BUYER_ROLE);
	}
	return hasBuyerRole;
};

export const saveOrderFulfilmentDetails = async (payload, productId) => {
	const saveOrderFulfilmentUrl = `${Constants.ORDER_SERVICE_URL}/${productId}/orderFulfilmentDetails`;
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};
	try {
		const response = await axios.post(`${saveOrderFulfilmentUrl}`, payload, {
			headers: headers
		});

		return response.status;
	} catch (error) {
		return 400;
	}
};

export const getSavedOrderFulfilmentDetails = async (productId) => {
	const getOrderFulfilmentUrl = `${Constants.ORDER_SERVICE_URL}/${productId}/orderFulfilmentDetails`;
	const headers = {
		'Content-Type': 'application/json'
	};
	try {
		const response = await axios.get(`${getOrderFulfilmentUrl}`, {
			headers: headers,
			data: {}
		});
		return response;
	} catch (error) {
		return 400;
	}
};

export const getProductDetails = async (productId) => {
	const getProductDetailsUrl = `${
		Constants.PRODUCT_DETAIL_ACTION.GET_PRODUCT_BY_ID + productId
	}`;
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};
	try {
		const response = await axios.get(`${getProductDetailsUrl}`, {
			headers: headers,
			data: {}
		});
		return response;
	} catch (error) {
		return 400;
	}
};

export const submitOrderToMiddleware = (payload, dispatch) => {
	axios
		.post(process.env.REACT_APP_MIDDLEWARE_ORDER_API_ENDPOINT, payload, {
			headers: {
				'Content-Type': 'application/json',
				'Ocp-Apim-Subscription-Key': process.env.REACT_APP_MIDDLEWARE_KEY,
				'X-Tenant': 'tos'
			}
		})
		.then((response) => {
			dispatch({
				type: 'UPDATE_ORDER_DETAILS',
				payload: { data: response.data }
			});
		})
		.catch((error) => {
			if (error.response !== undefined) {
				dispatch({
					type: 'UPDATE_PAYMENT_FAILURE_RESPONSE',
					payload: error.response
				});
			} else {
				dispatch({
					type: 'UPDATE_PAYMENT_FAILURE_RESPONSE',
					payload: {}
				});
			}
		});
};

export const submitOrderToMiddlewareWithOrderId = async (payload) => {
	const saveOrderFulfilmentUrl =
		process.env.REACT_APP_MIDDLEWARE_ORDER_API_ENDPOINT;
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': process.env.REACT_APP_MIDDLEWARE_KEY
	};
	try {
		const response = await axios.post(`${saveOrderFulfilmentUrl}`, payload, {
			headers: headers
		});

		return response;
	} catch (error) {
		return 400;
	}
};
