// Import necessary modules from the "@microsoft/applicationinsights-web" package
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

// Create an instance of the ReactPlugin
const reactPlugin = new ReactPlugin();

// Create an instance of ApplicationInsights and configure it
const appInsights = new ApplicationInsights({
  config: {
    // Set the instrumentation key from the environment variable
    //The key used to identify the Application Insights resource.
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    //connectionString: process.env.REACT_APP_CONNECTION_STRING, //connection string is the new preferred method

    // Attach the ReactPlugin as an extension
    extensions: [reactPlugin],

    // Enable automatic tracking of route changes
    enableAutoRouteTracking: true,

    // Automatically track the time users spend on each page
    autoTrackPageVisitTime: true,

    // Enable tracking of AJAX (Asynchronous JavaScript and XML) requests
    disableAjaxTracking: false,

    // Enable Cross-Origin Resource Sharing (CORS) correlation (if this is enabled, we get an error in Sharing Platform)
    enableCorsCorrelation: false,

    // Enable tracking of request headers
    enableRequestHeaderTracking: true,

    // Enable tracking of response headers
    enableResponseHeaderTracking: true,
  },
});

// Load Application Insights instance
appInsights.loadAppInsights();

// Add a telemetry initializer function to set a custom tag in telemetry items
appInsights.addTelemetryInitializer((env) => {
  // Ensure that the 'tags' property exists
  env.tags = env.tags || [];

  // Set a custom tag with key "ai.cloud.role" and value "testTag"
  env.tags['ai.cloud.role'] = 'Frontend';
});

// Export the ReactPlugin and ApplicationInsights instances for use in other parts of the code
export { reactPlugin, appInsights };
