import axios from 'axios';
import * as Constants from './configuration';
import errorConstants from '../data/errorConstants';

const headers = {
  'Content-Type': 'application/json',
  'X-tenant': Constants.TENANT.TOS
};

export function getProductCategories() {
  return (dispatch) =>
    axios
      .get(
        Constants.LANDING_ACTION.GET_PRODUCT_CATEGORIES,
        {
          headers: {
            ...headers,
            'X-tenant': Constants.TENANT.MKOSINT
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_PRODUCT_CATEGORIES',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}

export function getFilteredProducts(searchText, category, sortBy, sortOrder, headerParams, props, pageNumber, size) {
  let keyHeader = '';
  let categoryHeader = '';
  let sortByValue = '';
  let sortOrderValue = '';
  sortByValue = sortBy;
  sortOrderValue = sortOrder;

  if (!!searchText) {
    keyHeader = `key=${btoa(encodeURIComponent(searchText))}&`;
  }
  if (!!category) {
    categoryHeader = `category=${category}&`;
  }
  return (dispatch) =>
    axios
      .get(
        Constants.LANDING_ACTION.GET_FILTERED_PUBLISHED_PRODUCT +
        `?${keyHeader}${categoryHeader}page=${pageNumber}&size=${size}&sortBy=${sortByValue}&sortOrder=${sortOrderValue}` + (
          headerParams ? `&${headerParams}` : ``
        ),
        {
          headers: headers,
          data: {}
        }
      )
      .then((response) => {
        let data = response.data?.products;
        if (data !== undefined) {
          if (pageNumber !== 0) {
            data = props.filteredProducts?.concat(data);
          }
          dispatch({
            type: 'UPDATE_FILTERED_PRODUCTS',
            payload: data,
            count: { ...props.prodCount, ...response.data?.counts }
          });
        }
        else {
          data = props.filteredProducts;
          if (pageNumber === 0) {
            data = [];
          }
          dispatch({
            type: 'UPDATE_FILTERED_PRODUCTS',
            payload: data
          });
          dispatch({
            type: 'NO_PRODUCTS',
            payload: true
          });
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}

export const getFilteredProductsListing = async (searchText, category, sortBy, sortOrder, headerParams, props, pageNumber, size, errorCallback) => {
  let keyHeader = '';
  let categoryHeader = '';
  let sortByValue = '';
  let sortOrderValue = '';
  sortByValue = sortBy;
  sortOrderValue = sortOrder;
  if (!!searchText) {
    keyHeader = `key=${btoa(encodeURIComponent(searchText))}&`;
  }
  if (!!category) {
    categoryHeader = `category=${category}&`;
  }
  try {
    const response = await axios
      .get(
        Constants.LANDING_ACTION.GET_FILTERED_PUBLISHED_PRODUCT +
        `?${keyHeader}${categoryHeader}page=${pageNumber}&size=${size}&sortBy=${sortByValue}&sortOrder=${sortOrderValue}` + (
          headerParams ? `&${headerParams}` : ``
        ),
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      );
    if (response !== undefined) {
      return {
        products: response.data?.products,
        counts: response.data?.counts
      };

    }
  } catch (error) {
    if (error.response !== undefined) {
      errorCallback(error.response?.data?.message);
    } else {
      errorCallback(error.message ? error.message : error);
    }
  }
};

export function getAllProductsCount(loading) {
  return (dispatch) =>
    axios
      .get(
        Constants.LANDING_ACTION.GET_FILTERED_PUBLISHED_PRODUCT + '?&category=&groupBy=categories',
        {
          headers: headers,
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_PUBLISHED_PRODUCTS_COUNTS',
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}
export function getPublishedProducts(searchText, category, props, flagProductToFilter) {
  const categoryHeader = `category=${category}`;
  let searchTextHeader = '';
  if (!!searchText) {
    searchTextHeader = `key=${btoa(encodeURIComponent(searchText))}&`;
  }
  return (dispatch) =>
    axios
      .get(
        Constants.LANDING_ACTION.GET_FILTERED_PUBLISHED_PRODUCT + `?${searchTextHeader}&${categoryHeader}`,
        {
          headers: headers,
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_PUBLISHED_PRODUCTS',
          payload: response.data,
        });
        if (response.data?.error === errorConstants.searchError) {
          dispatch({
            type: 'NO_PRODUCTS',
            payload: true
          });
          if (flagProductToFilter === 'PRODUCTS_TO_FILTER_FLAG') {
            dispatch(
              {
                type: 'ERROR',
                error: { data: { message: 'PRODUCT NOT FOUND.' } },
              });
          }
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}

export function createApplication(reqObject) {
  return (dispatch) =>
    axios({
      method: 'POST',
      url:
        Constants.LANDING_ACTION.CREATE_APPLICATION,
      data: reqObject,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'CREATE_APPLICATION',
          payload: response.data,
        });
      })
      .catch((error) => {
        if (!!error.response) {
          dispatch({
            type: 'ERROR',
            error: error.response,
          });
        }
        else {
          dispatch({
            type: 'ERROR',
            error: error,
          });
        }
        dispatch({
          type: 'CREATE_APPLICATION',
          payload: undefined,
        });
      });
}

export function getFilterCategoriesGroupBy(type, categoryId) {
  let categoryHeader = ``;
  if (!!categoryId) {
    categoryHeader = `category=${categoryId}`;
  }
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: Constants.LANDING_ACTION.GET_FILTER_CATEGORIES_GROUP_BY + `?${categoryHeader}&groupBy=${type}`,
        headers: headers,
        data: {}
      })
        .then((response) => {
          resolve(response?.data);

          dispatch({
            type: 'GET_FILTER_CATEGORIES_GROUP_BY',
            payload: response?.data
          });
        })
        .catch((error) => {
          reject(error.response);
          dispatch({
            type: 'ERROR',
            error: error
          });
        });
    });
}

export function getFilterCategories(categoryId, countryList) {
  return (dispatch) =>
    axios
      .get(
        Constants.LANDING_ACTION.GET_FILTER_CATEGORIES + `?${categoryId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {},
        }
      )
      .then((response) => {
        const filterCategories = response.data;
        filterCategories?.forEach((filter, index) => {
          filter.filtered = false;
          filter.index = index;
          if (filter.filterValues) {
            filter.filterValues?.forEach(value => {
              value.checked = false;
            });
          }
        });

        dispatch({
          type: 'GET_FILTER_CATEGORIES',
          payload: filterCategories,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}

export function setFilterCategories(filterCategories) {
  return {
    type: 'GET_FILTER_CATEGORIES',
    payload: filterCategories,
  };
}

export function resetFilteredProducts() {
  return {
    type: 'UPDATE_FILTERED_PRODUCTS',
    payload: [],
  };
}

export function resetPublishedProducts() {
  return {
    type: 'GET_PUBLISHED_PRODUCTS',
    payload: {},
  };
}

export function resetSearchError() {
  return {
    type: 'NO_PRODUCTS',
    payload: false
  };
}

export function editApplication(reqObject) {
  return (dispatch) =>
    axios({
      method: 'PATCH',
      url:
        Constants.LANDING_ACTION.CREATE_APPLICATION + reqObject.consumerAppId,
      data: reqObject,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'EDIT_APPLICATION',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
        dispatch({
          type: 'EDIT_APPLICATION',
          payload: undefined,
        });
      });
}

export function deleteApplication(reqObject) {
  return (dispatch) =>
    axios({
      method: 'DELETE',
      url:
        Constants.LANDING_ACTION.CREATE_APPLICATION + reqObject.consumerAppId,
      data: reqObject,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'DELETE_APPLICATION',
          payload: response.status,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
        dispatch({
          type: 'DELETE_APPLICATION',
          payload: undefined,
        });
      });
}

export function setPortalView(value) {
  return {
    type: 'SET_PORTAL_VIEW',
    payload: value,
  };
}

