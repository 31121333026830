import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Box, Modal, Grid, Button } from '@mui/material';
import i18next from 'i18next';
import { appTheme } from '../../../../../themes/appTheme';

const useStyles = () => ({
    typoStyle: {
        float: 'right'
    },
    divStyle: {
        position: 'absolute',
        backgroundColor: appTheme.palette.common.light,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '2rem',
        minWidth: '40rem'
    }
});

const SessionModal = (props) => {
    const [open, setOpen] = useState(true);
    const { classes } = props;

    const handleOk = () => {
        setOpen(false);
        props.okOperation();
    };

    return (
        <React.Fragment>
            <Modal
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setOpen(false);
                    }
                }}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                open={this.state.open}>
                <Box className={classes.divStyle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography id='simple-modal-title' variant='mainTitleLight'>{this.props.heading}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>{props?.modalContent} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm>
                            <Box mt={1} display='flex' justifyContent='flex-end'>
                                <Button variant='filledBtn' sx={{ minWidth: '114px' }} onClick={() => handleOk()}>{i18next.t('SHARED.OK')}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default withStyles(useStyles)(SessionModal);
