import { Box, Stack, Typography, Dialog, DialogContent, Slide, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styles from './filters.module.scss';
import images from '../../../../assets/img';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { getFilterCategoriesGroupBy } from '../../../../actions/LandingAction';
import { getPromisedAllMasterData, getSubcategories } from '../../../../actions/MasterDataAction';
import { assetType } from '../../../../data/appConstants.js';
import i18next from 'i18next';
import DeviceBreakPoints from '../../../../deviceBreakPoints';
import StyledCheckbox from '../../../Shared/Inputs/StyledCheckbox/styledCheckbox';
import CloseIcon from '@mui/icons-material/Close';

let tempObjectCreatedForUrl = {};
let filtersSelectedCopy = {};

export const CheckedIcon = () => {
  return (
    <Box component='img' className={styles['svg-icon']} src={images.checkboxChecked} />
  );
};
export const UnCheckedIcon = () => {
  return (
    <Box component='img' className={styles['svg-icon']} src={images.checkboxUnchecked} />
  );
};
export const CheckboxItem = (props) => {
  const {
    item,
    classNameAsString,
    filterTypeIndex,
    filterValuesIndex,
    onItemClick
  } = props;

  return (
    <Stack className={styles[classNameAsString]} direction='row'>
      <Stack direction={'column'} className={styles['checkbox']}>
        <StyledCheckbox
          classNameProp={'no-padding'}
          onChangeProp={() => onItemClick(filterTypeIndex, filterValuesIndex)}
          iconProp={<UnCheckedIcon />}
          checkedIconProp={<CheckedIcon />}
          checkedProp={item.checked}
        />
      </Stack>
      <Typography variant='body1'>{item.filterValues}</Typography>
    </Stack>
  );
};

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='right' ref={ref} {...props} />;
});

const orderFilterDataSet = (filter, index) => {
  if (document.getElementById('container' + index))
    document.getElementById('container' + index).scrollTop = 0;
  filter.filterValues?.sort((filterValuesItem1, filterValuesItem2) => Number(filterValuesItem2?.checked) - Number(filterValuesItem1?.checked) || filterValuesItem1?.filterValues?.localeCompare(filterValuesItem2?.filterValues));
  return true;
};

const handleReset = (
  index,
  filtersDataSet,
  setFiltersDataSet,
  setSubCategoryCheckedList,
  subCategoryListCopy,
  setSubCategoryListCopy,
  searchValue,
  setSearchValue,
  setShowIndicators
) => {
  setShowIndicators(true);
  if (index === 0) {
    const indexToBeRemoved = findArrayIndex(filtersDataSet, 'name', i18next.t('FILTER_SECTION.CATEGORY'));
    indexToBeRemoved > -1 && filtersDataSet?.splice(indexToBeRemoved, 1);
    setSubCategoryCheckedList([]);
  }

  let reset = filtersDataSet[index]?.filterValues?.map((item) =>
    item.checked ? { ...item, checked: false } : item
  );
  let resultData = filtersDataSet.map((element, position) => {
    if (position === index) {
      let newDataInset = { ...element };
      newDataInset.filterValues = reset;
      return newDataInset;
    } else {
      return element;
    }
  });
  index === 2 && setSubCategoryListCopy(resultData[index]?.filterValues);
  if (searchValue && index === 2) {
    setSearchValue('');
    let tempSubCategoryListCopy = subCategoryListCopy?.map((item) =>
      item.checked ? { ...item, checked: false } : item
    );
    setSubCategoryListCopy(tempSubCategoryListCopy);
    resultData[index].filterValues = tempSubCategoryListCopy;
  }
  setFiltersDataSet(resultData);
};

const findArrayIndex = (array, key, toMatch) => {
  return array?.findIndex((item) => item[key] === toMatch);
};

const RenderFilterContent = (props) => {
  const {
    filtersDataSet,
    setFilters,
    onItemClick,
    isMediumDevice,
    searchValue,
    searchSubCategory,
    setFlagFilterDataSet,
    setFiltersDataSet,
    resetAllFilterData,
    setSubCategoryCheckedList,
    subCategoryListCopy,
    setSubCategoryListCopy,
    setSearchValue,
    isPhone,
    setShowIndicators
  } = props;

  const RenderCheckboxItem = ({ filter, item, index }) => {
    return (
      <CheckboxItem
        item={item}
        classNameAsString={
          isMediumDevice ? 'checkbox-container-small' : 'checkbox-container'
        }
        filterTypeIndex={filter?.index}
        filterValuesIndex={index}
        setFilters={setFilters}
        onItemClick={onItemClick}
      />
    );
  };

  return (
    <>
      {!!filtersDataSet?.length && (
        <Stack
          direction={'row'}
          justifyContent={'end'}
          className={
            isMediumDevice
              ? styles['reset-all-down-medium']
              : styles['reset-all']
          }
        >
          <AutorenewOutlinedIcon color='primary' className={styles['refresh-icon']} />
          <Typography
            onClick={resetAllFilterData}
            className={styles['cursor-style']}
            variant='p'
            color='primary'
          >
            {i18next.t('SHARED.RESET_ALL')}
          </Typography>
        </Stack>
      )}
      {filtersDataSet?.map((filter, index) => (
        <>
          <Typography
            variant='h6'
            className={
              isMediumDevice ? styles['heading-small'] : styles['heading']
            }
          >
            {filter.name}
          </Typography>
          {filter.name === i18next.t('FILTER_SECTION.CATEGORY') && (
            <Box m={2}>
              <TextField
                sx={{ backgroundColor: 'primaryBackground.bgBody' }}
                color='primary'
                className='search-field'
                InputProps={{
                  disableUnderline: true,
                  placeholder: i18next.t(
                    'REVAMPED_SEARCH_RESULT.SEARCH_CATEGORY'
                  ),
                  classes: {
                    root: styles['search-input']
                  }
                }}
                fullWidth
                variant='standard'
                size='small'
                onChange={(event) => searchSubCategory(event)}
                value={searchValue}
              />
            </Box>
          )}

          <Box id={'container' + index} className={styles['filter-container']} sx={{ maxHeight: isPhone ? '167px' : '220px' }}>
            {
              orderFilterDataSet(filter, index) 
            }{
              filter?.filterValues?.map((item, index) => (
                <RenderCheckboxItem filter={filter} item={item} index={index} />
              ))}
          </Box>
          <Stack
            justifyContent={'space-between'}
            direction={'row'}
            className={
              isMediumDevice
                ? styles['reset-container-small']
                : styles['reset-container']
            }
          >
            <Stack direction={'row'}>
              <AutorenewOutlinedIcon color='primary' className={styles['refresh-icon']} />
              <Typography
                className={styles['cursor-style']}
                color='primary'
                variant='p'
                onClick={() => {
                  if (document.getElementById('container' + index))
                    document.getElementById('container' + index).scrollTop = 0;
                  handleReset(
                    filter?.index,
                    filtersDataSet,
                    setFiltersDataSet,
                    setSubCategoryCheckedList,
                    subCategoryListCopy,
                    setSubCategoryListCopy,
                    searchValue,
                    setSearchValue,
                    setShowIndicators
                  );
                }
                }
              >
                {i18next.t('SHARED.RESET')}
              </Typography>
            </Stack>
          </Stack>
        </>
      ))}
    </>
  );
};

const Filters = (props) => {
  const urlDelimiter = '%26';
  const queryParams = 'queryParams';
  const [flagFilterDataSet, setFlagFilterDataSet] = useState(false);
  const { isMediumDevice, isSmallDevice, isPhone } = DeviceBreakPoints();
  const [searchValue, setSearchValue] = useState('');
  const [subCategoryListCopy, setSubCategoryListCopy] = useState([]);
  const [filtersDataSet, setFiltersDataSet] = useState([]);
  const [masterDataBusinessCategory, setMasterDataBusinessCategory] = useState(
    []
  );
  const [filterBusinessCategoryCounts, setFilterBusinessCategoryCounts] =
    useState({});
  const [filterBusinessCategoriesList, setFilterBusinessCategoriesList] =
    useState([]);
  const [subCategoryCheckedList, setSubCategoryCheckedList] = useState([]);
  const [fetchingDone, setFetchingDone] = useState(true);
  const [pathName, setPathName] = useState('');
  const [finalFilteredItem, setFinalFilteredItem] = useState({});
  const [searchBoolean, setSearchBoolean] = useState(false);
  const [isUrlFetchDone, setIsUrlFetchDone] = useState(false);
  const allMasterDataStore = useSelector((state) => state?.MasterDataReducer?.allMasterData);

  useEffect(() => {
    if (!window.location.search) {
      resetAllFilterData();
    }
  }, [window.location.search]);

  useEffect(() => {
    const navigatedFrom =
      props?.history?.location?.state?.prePopulateFrom?.url?.substring(1);
    let pathName = navigatedFrom
      ?.substring(navigatedFrom?.lastIndexOf('/'))
      ?.replace(/-/g, ' ');
    const locationPathNameArr = location?.pathname?.split('/');
    if (!pathName) {
      const applicableIndex = ['cloud-services', 'services'].includes(
        locationPathNameArr[1]
      )
        ? locationPathNameArr[2]
        : locationPathNameArr[1];
      pathName = applicableIndex?.replace(/-/g, ' ');
    }
    setFetchingDone(true);
    if (window.location.pathname === '/listing') {
      resetAllFilterData();
    }
    !(window.location.search.includes(urlDelimiter) || window.location.search.includes('?')) && setPathName(pathName);
  }, [window.location.pathname]);

  const [subCategoryFetchingDone, setSubCategoryFetchingDone] = useState(false);
  const [navigatedFromId, setNavigatedFromId] = useState(
    props?.history?.location?.state?.prePopulateFrom?.id
  );

  useEffect(() => {
    if (pathName && props?.subCategoryList?.length) {
      if (navigatedFromId === undefined) {
        const foundIndex = props?.subCategoryList?.findIndex((item) =>
          item?.label?.toLowerCase()?.includes(pathName)
        );
        if (props?.subCategoryList[foundIndex]?.subCategoryList?.length) {
          const locationPathNameArr = location?.pathname?.split('/');
          const subCategoryName = locationPathNameArr[
            locationPathNameArr?.length - 2
          ]?.replace(/-/g, ' ');
          const subCategoryId = props?.subCategoryList[
            foundIndex
          ]?.subCategoryList?.find(
            (item) => item?.name?.toLowerCase() === subCategoryName
          )?.id;
          subCategoryId && setNavigatedFromId(subCategoryId);
        }
      }
    }
  }, [props?.subCategoryList, pathName, navigatedFromId]);

  const prePopulateSubCategory = () => {
    if (
      filtersDataSet
        ?.map((item) =>
          Object.values(item)?.some((item) => item === i18next.t('FILTER_SECTION.CATEGORY'))
        )
        ?.includes(true)
    ) {
      const foundIndex = filtersDataSet
        ?.find((item) => item.name === i18next.t('FILTER_SECTION.CATEGORY'))
        ?.filterValues.findIndex(
          (item) => item.filterValuesId === navigatedFromId
        );
      foundIndex > -1 && onItemClick(2, foundIndex, false);
      setSubCategoryFetchingDone(false);
      setNavigatedFromId('');
      setFlagFilterDataSet(false);
    }
  };

  useEffect(() => {
    if (navigatedFromId && subCategoryFetchingDone) {
      prePopulateSubCategory();
    }
  }, [filtersDataSet, subCategoryFetchingDone, navigatedFromId]);

  const setSubCategory = (tempFiltersDataSet, tempSubCategoryList) => {
    let uniqueCategoryItems = [...new Set(tempSubCategoryList)];
    setSubCategoryCheckedList(uniqueCategoryItems);
    callSubCategoryFilterFunc(tempFiltersDataSet, uniqueCategoryItems);
    if (!uniqueCategoryItems.length) {
      const indexToBeRemoved = findArrayIndex(
        tempFiltersDataSet,
        'name',
        i18next.t('FILTER_SECTION.CATEGORY')
      );
      indexToBeRemoved > -1 &&
        setFiltersDataSet(tempFiltersDataSet?.splice(indexToBeRemoved, 1));
    }
    setFiltersDataSet(tempFiltersDataSet);
  };

  const setFilters = (
    tempFiltersDataSet,
    filterTypeIndex,
    filterValuesIndex,
    prePopulate,
    value
  ) => {
    if (!flagFilterDataSet) {
      setFlagFilterDataSet(false);
      if (tempFiltersDataSet[filterTypeIndex]?.name === i18next.t('FILTER_SECTION.ASSET_TYPE')) {
        if (
          tempFiltersDataSet[filterTypeIndex].filterValues[filterValuesIndex]
            .checked
        ) {
          let tempSubCategoryList = [];
          if (prePopulate) {
            tempSubCategoryList = [
              tempFiltersDataSet[filterTypeIndex].filterValues[
                filterValuesIndex
              ].filterValuesId
            ];
          } else {
            if (!navigatedFromId)
              tempFiltersDataSet[2]?.filterValues.map(
                (item) => (item.checked = false)
              );
            tempSubCategoryList = [
              ...subCategoryCheckedList,
              tempFiltersDataSet[filterTypeIndex].filterValues[
                filterValuesIndex
              ].filterValuesId
            ];
          }
          setSubCategory(tempFiltersDataSet, tempSubCategoryList);
        } else if (
          tempFiltersDataSet[filterTypeIndex]?.name === i18next.t('FILTER_SECTION.ASSET_TYPE') &&
          !tempFiltersDataSet[filterTypeIndex].filterValues[filterValuesIndex]
            .checked
        ) {
          setSubCategory(
            tempFiltersDataSet,
            subCategoryCheckedList?.filter(
              (item) =>
                item !==
                tempFiltersDataSet[filterTypeIndex]?.filterValues[
                  filterValuesIndex
                ]?.filterValuesId
            )
          );
        }
      } else {
        setFiltersDataSet(tempFiltersDataSet);
      }
      if (tempFiltersDataSet[filterTypeIndex]?.name === i18next.t('FILTER_SECTION.ASSET_TYPE')) {
        let tempSubCategory = [];
        tempFiltersDataSet[filterTypeIndex]?.filterValues?.forEach((item) => {
          if (item.checked) {
            tempSubCategory.push(item.filterValuesId);
          }
        });
        setSubCategoryCheckedList(tempSubCategory);
      }
      setFiltersDataSet(tempFiltersDataSet);
    }
  };

  useEffect(() => {
    !props?.showIndicators && setFiltersDataSet([...filtersDataSet]);
  }, [props?.showIndicators]);

  const onItemClick = (filterTypeIndex, filterValuesIndex, prePopulate) => {
    props?.setShowIndicators(true);
    let tempFiltersDataSet = [...filtersDataSet];
    if (prePopulate) {
      tempFiltersDataSet[0]?.filterValues?.map(
        (item) => (item.checked = false)
      );
    }
    if (tempFiltersDataSet[filterTypeIndex]?.filterValues[filterValuesIndex])
      tempFiltersDataSet[filterTypeIndex].filterValues[
        filterValuesIndex
      ].checked =
        !tempFiltersDataSet[filterTypeIndex]?.filterValues[filterValuesIndex]
          ?.checked;
    setFilters(
      tempFiltersDataSet,
      filterTypeIndex,
      filterValuesIndex,
      prePopulate
    );
  };

  const resetAllFilterData = () => {
    props?.setShowIndicators(true);
    const indexToBeRemoved = findArrayIndex(filtersDataSet, 'name', i18next.t('FILTER_SECTION.CATEGORY'));
    if (searchValue && indexToBeRemoved === 2) {
      setSearchValue('');
      let tempSubCategoryListCopy = subCategoryListCopy?.map((item) =>
        item.checked ? { ...item, checked: false } : item
      );
      setSubCategoryListCopy(tempSubCategoryListCopy);
    }
    indexToBeRemoved > -1 && filtersDataSet?.splice(indexToBeRemoved, 1);
    let resultData = filtersDataSet.map((element, index) => {
      let newDataInset = { ...element };
      newDataInset.filterValues = filtersDataSet[index]?.filterValues?.map(
        (item) => (item.checked ? { ...item, checked: false } : item)
      );
      return newDataInset;
    });
    setSubCategoryCheckedList([]);
    setFiltersDataSet(resultData);
  };

  useEffect(() => {
    if (window.location.search.includes(urlDelimiter)) {
      window.location.search.split(urlDelimiter)?.forEach((item) => {
        if( item.split('=')[0].replace('?', '') === 'asset-type' || item.split('=')[0].replace('?', '') === 'category' || item.split('=')[0].replace('?', '') === 'use-case' ){
        tempObjectCreatedForUrl[item.split('=')[0].replace('?', '')] = item.split('=')[1].split(',');
        }else {
          props.history.push('/page-not-found');
        }
      });
    } else if (window.location.search.includes('?')) {
      if(window.location.search.split('?')[1]?.split('=')[0] === 'asset-type' || window.location.search.split('?')[1]?.split('=')[0] === 'use-case' ){
      tempObjectCreatedForUrl[window.location.search.split('?')[1]?.split('=')[0]] = window.location.search.split('?')[1]?.split('=')[1].split(',');
      } else {
        props.history.push('/page-not-found');
      }
    }
  }, [window.location.pathname]);


  const setFiltersAndSubcategoryCopy = (filtersDataCopy, applyChanges) => {
    if (
      filtersDataCopy.findIndex(
        (item) => item?.name === i18next.t('FILTER_SECTION.CATEGORY')
      ) !== -1
    ) {
      setSubCategoryListCopy(
        filtersDataCopy[
          filtersDataCopy.findIndex(
            (item) => item?.name === i18next.t('FILTER_SECTION.CATEGORY')
          )
        ].filterValues
      );
    }
    if (applyChanges) {
      setFiltersDataSet(filtersDataCopy);
      props?.setFiltersSelected(filtersSelectedCopy);
    }
  };

  const setIndexForUrl = (tempObject, type, filtersDataCopy) => {
    let typeIds = '';
    let tempFiltersDataCopy = filtersDataCopy;
    const concatenatedIds = tempObject
      ?.map((item) =>
        filtersDataCopy[0]?.filterValues?.find(
          (inItem) => inItem.filterValues?.toLowerCase() === item
        )
      )
      ?.map((item) => item?.filterValuesId)
      ?.filter((item) => item)
      ?.join(',');

    let totalMasterData = [];
    let finalData = [];
    return new Promise((resolve) => {
      props?.getSubcategories()?.then((subcategories) => {
        props
          ?.getFilterCategoriesGroupBy('subcategory', concatenatedIds)
          ?.then((response) => {
            Object.keys(response?.counts)?.map((item) => {
              const foundItem = subcategories?.find(
                (innerItem) => innerItem?.id === item
              );
              foundItem &&
                !totalMasterData?.some((item) => item?.id === foundItem?.id) &&
                totalMasterData?.push(foundItem);
              return true;
            });
            totalMasterData?.map((item) => {
              let object = {};
              object['checked'] = false;
              object['filterValues'] = item?.name;
              object['filterValuesId'] = item?.id;
              finalData.push(object);
              return true;
            });
            tempObject && Object.values(tempObject)?.forEach((categoryItem) => {          
              let firstIndex = tempFiltersDataCopy.findIndex(
                (item) => item.name === type
              );
              let secondIndex = tempFiltersDataCopy[
                firstIndex
              ]?.filterValues?.findIndex(
                (innerItem) =>
                  innerItem.filterValues.toLowerCase() ===
                  categoryItem.replaceAll('-', ' ')
              );
              if (firstIndex > -1 && secondIndex > -1) {
                tempFiltersDataCopy[firstIndex].filterValues[
                  secondIndex
                ].checked = true;
                typeIds =
                  typeIds +
                  (typeIds && ',') +
                  tempFiltersDataCopy[firstIndex]?.filterValues[secondIndex]
                    ?.filterValuesId;
                if (type === i18next.t('FILTER_SECTION.ASSET_TYPE')) {
                  if (
                    tempFiltersDataCopy.findIndex(
                      (item) =>
                        item.name === i18next.t('FILTER_SECTION.CATEGORY')
                    ) === -1
                  ) {
                    tempFiltersDataCopy.push({
                      name: i18next.t('FILTER_SECTION.CATEGORY'),
                      filterValues: finalData,
                      index: 2
                    });
                  }
                  setSubCategoryCheckedList(typeIds.split(','));
                }
              } else {
                props.history.push('/page-not-found');
                tempObjectCreatedForUrl = {};
              }
              filtersSelectedCopy[type] = typeIds;
              tempFiltersDataCopy[
                tempFiltersDataCopy.findIndex((item) => item.name === type)
              ].filterValues.sort((category1, category2) =>
                category1.filterValues.localeCompare(category2.filterValues)
              );
              resolve(tempFiltersDataCopy);
            });
          });
      });
    });
  };

  useEffect(() => {
    props?.getFilterCategoriesGroupBy('businessCategory').then((response) => {
      setFilterBusinessCategoryCounts(response?.counts);
    });

    if (      
      localStorage[queryParams] &&
      JSON.parse(localStorage[queryParams])?.filtersDataSet?.length &&
      !isUrlFetchDone
    ) {
      setIsUrlFetchDone(true);
      let filtersDataCopy = JSON.parse(
        localStorage[queryParams]
      )?.filtersDataSet;
      if (tempObjectCreatedForUrl['asset-type']) {
        setIndexForUrl(
          tempObjectCreatedForUrl['asset-type'],
          i18next.t('FILTER_SECTION.ASSET_TYPE'),
          filtersDataCopy
        ).then((response) => {
          if (tempObjectCreatedForUrl['category']) {
            setFiltersAndSubcategoryCopy(response, false);
            setIndexForUrl(
              tempObjectCreatedForUrl['category'],
              i18next.t('FILTER_SECTION.CATEGORY'),
              filtersDataCopy
            ).then((response) => {
              setFiltersAndSubcategoryCopy(response, false);
              if (tempObjectCreatedForUrl['use-case']) {
                setIndexForUrl(
                  tempObjectCreatedForUrl['use-case'],
                  i18next.t('FILTER_SECTION.USE_CASE'),
                  filtersDataCopy
                ).then((response) => {
                  setFiltersAndSubcategoryCopy(response, true);
                });
              } else{
                setFiltersAndSubcategoryCopy(response, true);
              }
            });
          } else {
            if (tempObjectCreatedForUrl['use-case']) {
              setFiltersAndSubcategoryCopy(response, false);
              setIndexForUrl(
                tempObjectCreatedForUrl['use-case'],
                i18next.t('FILTER_SECTION.USE_CASE'),
                filtersDataCopy
              ).then((response) => {
                setFiltersAndSubcategoryCopy(response, true);
              });
            } else {
              setFiltersAndSubcategoryCopy(response, true);
            }
          }
        });
      } else {
        setIndexForUrl(
          tempObjectCreatedForUrl['use-case'],
          i18next.t('FILTER_SECTION.USE_CASE'),
          filtersDataCopy
        ).then((response) => {
          setFiltersAndSubcategoryCopy(response, true);
        });
      }
    }
  }, [localStorage[queryParams]]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(queryParams);
      tempObjectCreatedForUrl = {};
      filtersSelectedCopy = {};
    };
  }, []);

  useEffect(() => {
    if (allMasterDataStore) {
      setMasterDataBusinessCategory(
        allMasterDataStore?.businesscategory?.filter((item) => item.isActive)
      );
    }
  }, [allMasterDataStore]);

  useEffect(() => {
    setFilterBusinessCategoriesList(
      getModifiedFilterValues(
        filterBusinessCategoryCounts,
        masterDataBusinessCategory
      )
    );
  }, [masterDataBusinessCategory, filterBusinessCategoryCounts]);

  useEffect(() => {
    if (filterBusinessCategoriesList?.length) {
      const assetTypeFilterValues = [];
      assetType?.map((item) => {
        let eachItem = {};
        eachItem['filterValuesId'] = item?.categoryId;
        eachItem['filterValues'] = item?.label;
        eachItem['checked'] = false;
        assetTypeFilterValues?.push(eachItem);
        return true;
      });
  
      const sortedAssetTypeFilterValues = assetTypeFilterValues.sort(
        (category1, category2) =>
          category1.filterValues.localeCompare(category2.filterValues)
      );
  
      if (!filtersDataSet.find((item) => item.name === i18next.t('FILTER_SECTION.USE_CASE'))) {
        const tempFiltersDataSet = [
          ...filtersDataSet,
          {
            name: i18next.t('FILTER_SECTION.ASSET_TYPE'),
            filterValues: sortedAssetTypeFilterValues,
            index: 0
          },
          {
            name: i18next.t('FILTER_SECTION.USE_CASE'),
            filterValues: filterBusinessCategoriesList.sort(
              (category1, category2) =>
                category1.filterValues.localeCompare(category2.filterValues)
            ),
            index: 1
          }
        ];
        setFiltersDataSet(tempFiltersDataSet);
        localStorage.setItem(
          queryParams,
          JSON.stringify({
            filtersDataSet: tempFiltersDataSet
          })
        );
      }
    }
  }, [filterBusinessCategoriesList]);
  

  useEffect(() => {
    let filtersSelected = {};
    filtersDataSet.forEach((item) => {
      let filteredItem = item.filterValues.filter(
        (innerItem) => innerItem.checked
      );
      if (filteredItem && filteredItem.length > 0) {
        filtersSelected[item.name] = filteredItem
          .map((innerItem) => innerItem.filterValuesId)
          .join(',');
      }
    });
    if (JSON.stringify(finalFilteredItem) !== JSON.stringify(filtersSelected)) {
      if (searchBoolean) {
        filtersSelected[i18next.t('FILTER_SECTION.CATEGORY')] = subCategoryListCopy
          ?.filter((item) => item.checked)
          ?.map((innerItem) => innerItem.filterValuesId)
          ?.join(',');
      }
      if (!flagFilterDataSet) {
        setFinalFilteredItem(filtersSelected);
        props?.setFiltersSelected(filtersSelected);
      }
    }
    isUrlFetchDone && frameQueryParams(filtersSelected);
  }, [filtersDataSet, flagFilterDataSet]);

  useEffect(() => {
    if (
      !!pathName &&
      fetchingDone &&
      !!filtersDataSet?.length
    ) {
      const clickIndex = filtersDataSet[0]?.filterValues
        ?.map((item) => item?.filterValues?.toLowerCase())
        .findIndex((item) => item.includes(pathName));

      setFetchingDone(false);
      clickIndex > -1 && onItemClick(0, clickIndex, true);
      return;
    }
  }, [filtersDataSet, fetchingDone, pathName]);

  const frameQueryParams = (filtersSelected) => {
    let urlQueryParams = '';
    if (Object.keys(filtersSelected)?.length) {
      for (const [key, value] of Object.entries(filtersSelected)) {
        const valueArray = value.split(',');
        const modifiedKey = key?.toLowerCase()?.replace(/ /g, '-');
        let modifiedValue = '';
        valueArray?.map((item) => {
          if (key === i18next.t('FILTER_SECTION.CATEGORY')) {
            modifiedValue =
              modifiedValue +
              subCategoryListCopy
                ?.find((innItem) => innItem.filterValuesId === item)
                ?.filterValues?.toLowerCase()
                ?.replace(/ /g, '-') +
              ',';
          } else {
            modifiedValue =
              modifiedValue +
              filtersDataSet
                ?.find((item) => item.name === key)
                ?.filterValues?.find(
                  (innItem) => innItem.filterValuesId === item
                )
                ?.filterValues?.toLowerCase()
                ?.replace(/ /g, '-') +
              ',';
          }
          return true;
        });
        if (!modifiedValue.includes('undefined'))
          urlQueryParams =
            urlQueryParams + modifiedKey + '=' + modifiedValue + urlDelimiter;
        const delimiterRegex = new RegExp(',' + urlDelimiter, 'g');
        urlQueryParams = urlQueryParams.replace(delimiterRegex, urlDelimiter);
      }
    }
    props?.history?.push({
      search: urlQueryParams.substring(
        0,
        urlQueryParams.lastIndexOf(urlDelimiter)
      )
    });
  };

  const getModifiedFilterValues = (list, masterData) => {
    const countsList = masterData?.filter((item) =>
      list?.hasOwnProperty(item?.id)
    );
    const filterValues = [];
    countsList?.map((item) => {
      let eachItem = {};
      eachItem['filterValuesId'] = item?.id;
      eachItem['filterValues'] = item?.name;
      eachItem['checked'] = false;
      filterValues?.push(eachItem);
      return true;
    });
    return filterValues;
  };

  const callSubCategoryFilterFunc = (checkedListArray, uniqueCategoryItems) => {
    let totalCounts = {};
    let totalMasterData = [];
    if (uniqueCategoryItems?.length) {
      props
        ?.getFilterCategoriesGroupBy(
          'subcategory',
          uniqueCategoryItems?.join(',')
        )
        .then((response) => {
          totalCounts = Object.assign(totalCounts, response?.counts);          
          Object.keys(totalCounts)?.map((item) => {
            const foundItem = props?.subcategories?.find(
              (innerItem) => innerItem?.id === item
            );
            foundItem &&
              !totalMasterData?.some((item) => item?.id === foundItem?.id) &&
              totalMasterData?.push(foundItem);
            return true;
          });  
          let tempFiltersDataSet = [...checkedListArray];
          let filterValues = getModifiedFilterValues(
            totalCounts,
            totalMasterData
          );
          setSubCategoryListCopy([...filterValues]);
          if (
            filtersDataSet.find(
              (item) => item.name === i18next.t('FILTER_SECTION.CATEGORY')
            )
          ) {
            if (filterValues?.length) {
              tempFiltersDataSet.filter(
                (item) => item.name === i18next.t('FILTER_SECTION.CATEGORY')
              )[0].filterValues = filterValues.sort((category1, category2) =>
                category1.filterValues.localeCompare(category2.filterValues)
              );
            } else {
              const indexToBeRemoved = findArrayIndex(
                tempFiltersDataSet,
                'name',
                i18next.t('FILTER_SECTION.CATEGORY')
              );
              indexToBeRemoved > -1 &&
                tempFiltersDataSet?.splice(indexToBeRemoved, 1);
            }
            setFiltersDataSet(tempFiltersDataSet);
          } else {
            if (filterValues?.length)
              tempFiltersDataSet = [
                ...checkedListArray,
                {
                  name: i18next.t('FILTER_SECTION.CATEGORY'),
                  filterValues: filterValues.sort((category1, category2) =>
                    category1.filterValues.localeCompare(category2.filterValues)
                  ),
                  index: tempFiltersDataSet.length
                }
              ];
            setFiltersDataSet(tempFiltersDataSet);
          }
          setSubCategoryFetchingDone(true);
        });
    }
  };

  const searchSubCategory = (event) => {
    if (event?.target?.value === '') setSearchBoolean(false);
    else setSearchBoolean(true);

    setSearchValue(event.target.value);
    let filterSet = [...filtersDataSet];

    let filteredSubCategories = subCategoryListCopy?.filter((item) =>
      item.filterValues.toLowerCase().includes(event.target.value.toLowerCase())
    );
    let subCategoryObj = filterSet?.find((item) => item.name === i18next.t('FILTER_SECTION.CATEGORY'));
    subCategoryObj.filterValues = filteredSubCategories;
  };

  return (
    <>
      <Box
        sx={{ backgroundColor: 'primaryBackground.bgBody' }}
        className={
          isMediumDevice
            ? isSmallDevice
              ? `${styles['container-small']} ${props?.isFiltersOpen
                ? styles['active-style']
                : styles['inactive-style']
              }`
              : `${styles['container-medium']} ${props?.isFiltersOpen
                ? styles['active-style']
                : styles['inactive-style']
              }`
            : styles['container']
        }
      >
        {isMediumDevice && (
          <React.Fragment>
            <Dialog
              TransitionComponent={SlideTransition}
              classes={
                isSmallDevice
                  ? { paper: styles['dialog-paper-small'] }
                  : { paper: styles['dialog-paper-rest'] }
              }
              open={props?.isFiltersOpen}
              square={true}
            >              
              <CloseIcon sx={{color:'secondary.dark'}} title={i18next.t('FORM.CLOSE')} className={`${styles['dialog-close-icon']}`} onClick={() => props?.setIsFiltersOpen(false)} />
              <DialogContent>
                <RenderFilterContent
                  filtersDataSet={filtersDataSet}
                  setFilters={setFilters}
                  onItemClick={onItemClick}
                  isMediumDevice={isMediumDevice}
                  searchValue={searchValue}
                  searchSubCategory={searchSubCategory}
                  setFlagFilterDataSet={setFlagFilterDataSet}
                  setFiltersDataSet={setFiltersDataSet}
                  resetAllFilterData={resetAllFilterData}
                  setSubCategoryCheckedList={setSubCategoryCheckedList}
                  subCategoryListCopy={subCategoryListCopy}
                  setSubCategoryListCopy={setSubCategoryListCopy}
                  setSearchValue={setSearchValue}
                  isPhone={isPhone}
                  setShowIndicators={props?.setShowIndicators}
                />
              </DialogContent>
            </Dialog>
          </React.Fragment>
        )}
        {!isMediumDevice && (
          <RenderFilterContent
            filtersDataSet={filtersDataSet}
            setFilters={setFilters}
            onItemClick={onItemClick}
            isMediumDevice={isMediumDevice}
            searchValue={searchValue}
            searchSubCategory={searchSubCategory}
            setFlagFilterDataSet={setFlagFilterDataSet}
            setFiltersDataSet={setFiltersDataSet}
            resetAllFilterData={resetAllFilterData}
            setSubCategoryCheckedList={setSubCategoryCheckedList}
            subCategoryListCopy={subCategoryListCopy}
            setSubCategoryListCopy={setSubCategoryListCopy}
            setSearchValue={setSearchValue}
            isPhone={isPhone}
            setShowIndicators={props?.setShowIndicators}
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (redux_state) => ({
  filterCategories: redux_state.LandingReducer.filterCategories,
  filterCategoriesGroupBy:
    redux_state.LandingReducer.getFilterCategoriesGroupBy,
  allMasterData: redux_state.MasterDataReducer.allMasterData,
  subcategories: redux_state.MasterDataReducer.subcategories,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPromisedAllMasterData,
      getFilterCategoriesGroupBy,
      getSubcategories
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Filters));
