import { React, NavLink } from 'react-router-dom';
import { cloudSubCategories, blogResourceContent, servicesSubCategories, services, workflows, frameworks } from './HomePage/categoriesList';
import idConstants from './idConstants';

export const allText = [
   {
      label: 'All'
   }];

export const assetType = [
   // MS-15450 : Hiding other Asset Types as part of BGSW tools POC
   // {
   //    label: idConstants.cloudInfrastructure.productCategoryName,
   //    categoryId: idConstants.cloudInfrastructure.categoryId,
   //    subCategoryList: [],
   //    index: 1
   // },
   // {
   //    label: idConstants.data.productCategoryName,
   //    categoryId: idConstants.data.categoryId,
   //    subCategoryList: [],
   //    index: 2
   // },
   // {
   //    label: idConstants.hardware.productCategoryName,
   //    categoryId: idConstants.hardware.categoryId,
   //    subCategoryList: [],
   //    index: 3
   // },
   // {
   //    label: idConstants.enterpriseLicensing.productCategoryName,
   //    categoryId: idConstants.enterpriseLicensing.categoryId,
   //    subCategoryList: [],
   //    index: 4
   // },
   // {
   //    label: idConstants.managedServices.productCategoryName,
   //    categoryId: idConstants.managedServices.categoryId,
   //    subCategoryList: [],
   //    index: 5
   // },
   // {
   //    label: idConstants.iotSoftwareServices.productCategoryName,
   //    categoryId: idConstants.iotSoftwareServices.categoryId,
   //    subCategoryList: [],
   //    index: 6
   // },
   // {
   //    label: idConstants.professionalServices.productCategoryName,
   //    categoryId: idConstants.professionalServices.categoryId,
   //    subCategoryList: [],
   //    index: 7
   // },
   // {
   //    label: idConstants.communicationServices.productCategoryName,
   //    categoryId: idConstants.communicationServices.categoryId,
   //    subCategoryList: [],
   //    index: 8
   // },
   // {
   //    label: idConstants.solutions.productCategoryName,
   //    categoryId: idConstants.solutions.categoryId,
   //    subCategoryList: [],
   //    index: 9
   // },
   // {
   //    label: idConstants.sdk.productCategoryName,
   //    categoryId: idConstants.sdk.categoryId,
   //    subCategoryList: [],
   //    index: 10
   // },
   {
      label: idConstants.api.productCategoryName + 's',
      categoryId: idConstants.api.categoryId,
      subCategoryList: [],
      index: 0
   },
   {
      label: idConstants.saas.productCategoryName,
      categoryId: idConstants.saas.categoryId,
      subCategoryList: [],
      index: 1
   },
   {
      label: idConstants.paas.productCategoryName,
      categoryId: idConstants.paas.categoryId,
      subCategoryList: [],
      index: 2
   },
   {
      label: idConstants.iaas.productCategoryName,
      categoryId: idConstants.iaas.categoryId,
      subCategoryList: [],
      index: 3
   },
   {
      label: idConstants.preDefinedWorkflow.productCategoryName,
      categoryId: idConstants.preDefinedWorkflow.categoryId,
      subCategoryList: [],
      index: 4
   },
   {
      label: idConstants.customWorkflow.productCategoryName,
      categoryId: idConstants.customWorkflow.categoryId,
      subCategoryList: [],
      index: 5
   },
   {
      label: idConstants.methods.productCategoryName,
      categoryId: idConstants.methods.categoryId,
      subCategoryList: [],
      index: 6
   }];
export const myProposalSortOptions = [
   {
      id: 'recent',
      name: 'Recently Added',
   },
   {
      id: 'nameAsc',
      name: 'Name (A to Z)',
   },
   {
      id: 'nameDesc',
      name: 'Name (Z to A)',
   },
];

export const myProposalPageOptions = [
   {
      id: '10',
      value: 10,
   },
   {
      id: '20',
      value: 20,
   },
   {
      id: '30',
      value: 30,
   },
];

export const sortData = [
   {
      id: 'recent',
      name: 'FILTER_SECTION.SORT_OPTIONS.RECENTLY_ADDED',
   },
   {
      id: 'asc',
      name: 'FILTER_SECTION.SORT_OPTIONS.NAME_A_TO_Z',
   },
   {
      id: 'desc',
      name: 'FILTER_SECTION.SORT_OPTIONS.NAME_Z_TO_A',
   },
];

export const contentTypeData = [
   {
      id: 'application/json',
      name: 'application/json'
   },
   {
      id: 'application/xml',
      name: 'application/xml'
   }
];

export const accessRoles = {
   buyer: 'BUYER',
   seller: 'SELLER'
};

export const rowPerPageMenu = [
   {
      name: '5',
      value: 5,
   },
   {
      name: '10',
      value: 10,
   },
   {
      name: '15',
      value: 15,
   },
   {
      name: '20',
      value: 20,
   }
];
export const headersData = [
   {
      label: 'SHARED.ABOUT_US',
      link: '/about-us',
      isActive: false,
      isDisplay: true,
      hasSubMenu: false,
      id: 1

   },
   {
      label: 'SHARED.CLOUD_SERVICES',
      link: '/cloud-services',
      isActive: true,
      hasSubMenu: true,
      subMenuList: [...cloudSubCategories],
      id: 2

   },
   {
      label: 'HOME_PAGE.HEADER_FOOTER.SERVICES',
      link: '/services',
      isActive: true,
      hasSubMenu: true,
      subMenuList: [...servicesSubCategories],
      id: 3

   },
   {
      label: 'SHARED.APIS',
      link: '/api',
      isActive: true,
      hasSubMenu: false,
      id: 4,
      categoryId: idConstants.api.categoryId
   },
   {
      label: 'SHARED.SOLUTIONS',
      link: '/solutions',
      isActive: false,
      hasSubMenu: false,
      id: 5,
      categoryId: idConstants.solutions.categoryId
   },
   {
      label: 'SHARED.DATA',
      link: '/data',
      isActive: false,
      hasSubMenu: false,
      id: 6,
      categoryId: idConstants.data.categoryId
   },
   {
      label: 'SHARED.HARDWARE',
      link: '/hardware',
      isActive: false,
      hasSubMenu: false,
      id: 7,
      categoryId: idConstants.hardware.categoryId
   },
   {
      label: 'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCES',
      link: '/blogs-resources',
      isActive: true,
      hasSubMenu: true,
      subMenuList: [...blogResourceContent],
      id: 8
   },
   {
      label: 'HOME_PAGE.HEADER_FOOTER.SERVICES',
      link: '/services',
      isActive: true,
      isDisplay: true,
      hasSubMenu: true,
      subMenuList: [...services],
      id: 9

   },
   {
      label: 'HOME_PAGE.HEADER_FOOTER.WORKFLOWS',
      link: '/workflows',
      isActive: true,
      isDisplay: true,
      hasSubMenu: true,
      subMenuList: [...workflows],
      id: 10

   },
   {
      label: 'HOME_PAGE.HEADER_FOOTER.FRAMEWORKS',
      link: '/frameworks',
      isActive: true,
      isDisplay: true,
      hasSubMenu: true,
      subMenuList: [...frameworks],
      id: 11

   },
   {
      label: 'HOME_PAGE.HEADER_FOOTER.EXPLORE_ALL',
      link: '/listing',
      isActive: false,
      isDisplay: true,
      hasSubMenu: false,
      id: 9
   }
];

export const orderHistoryTabs = [
   { name: 'MY_ORDERS_PAGE.ORDERS_HISTORY', value: 'orderHistory', index: 0 },
   { name: 'MY_ORDERS_PAGE.MY_SUBSCRIPTIONS', value: 'mySubscriptions', index: 1, disabled: true },
   { name: 'MY_ORDERS_PAGE.BILLING_INVOICE_HISTORY', value: 'billing', index: 2 , disabled: true }
];

export const masterDataConstants = {
   sellerContactNumber: 'seller_contact_number',
   sellerContactEmail: 'seller_contact_mail',
   sellerSalesContactEmail: 'seller_sales_contact_mail'
};

export const subscriptionStatus = {
   subscribed: 'SUBSCRIBED',
   pending: 'PENDING',
   cancellationInProgress: 'CANCELLATIONINPROGRESS'
};

export const consentAlert = {
   consentMessage: ['Clicking this link redirects you to an authentication page and post acceptance you will be able to browse/ use the website. And also by continuing to browse or by clicking “Accept” you agree to the storing of cookies on your device to enhance your site experiences and for analytical purposes. To learn more about how we use the cookies, please see our ', <NavLink to='/data-protection-policy'>cookies policy</NavLink>, '.'],
   consentTitle: 'Redirect and Cookie Usage Consent'
};

export const orderStatus = {
   failed: 'ORDER_FAILED'
};

export const portalViewData = {
   MOBILE: 'mobile',
   TAB: 'tab',
   DESKTOP: 'desktop'
};

export const preferredModeToReachOptions = [
   {
      'value': 'phone',
      'name': 'GET_IN_TOUCH_PAGE.PREFERRED_MODE_OPTONS.BY_PHONE'
   },
   {
      'value': 'email',
      'name': 'GET_IN_TOUCH_PAGE.PREFERRED_MODE_OPTONS.BY_EMAIL'
   }
];

export const enquiryTypeOptions = [
   {
      'value': 'Cloud Infrastructure',
      'name': 'HOME_PAGE.HEADER_FOOTER.INFRASTRUCTURE'
   },
   {
      'value': 'Managed Services',
      'name': 'SHARED.CLOUD_MANAGED_SERVICES'
   },
   {
      'value': 'Enterprise Licensing',
      'name': 'SHARED.ENTERPRISE_LICENSING'
   },
   {
      'value': 'IOT Software Services',
      'name': 'HOME_PAGE.HEADER_FOOTER.IOT_SOFTWARE_SERVICES'
   },
   {
      'value': 'Professional Services',
      'name': 'HOME_PAGE.HEADER_FOOTER.PROFESSIONAL_SERVICES'
   },
   {
      'value': 'Communication Services',
      'name': 'HOME_PAGE.HEADER_FOOTER.COMMUNICATION_SERVICES'
   },
   {
      'value': 'APIs',
      'name': 'SHARED.APIS'
   },
   {
      'value': 'Solutions',
      'name': 'SHARED.SOLUTIONS'
   },
   {
      'value': 'Data',
      'name': 'SHARED.DATA'
   },
   {
      'value': 'Hardware',
      'name': 'SHARED.HARDWARE'
   },
   {
      'value': 'Others',
      'name': 'SHARED.OTHERS'
   },
   {
      'value': 'SDK',
      'name': 'HOME_PAGE.HEADER_FOOTER.SDKS'
   }
];

export const selectPurposeOptions = [
   {
      'value': 'Product Support',
      'name': 'GET_IN_TOUCH_PAGE.SELECT_A_PURPOSE.PRODUCT_SUPPORT'
   },
   {
      'value': 'Product Questions',
      'name': 'GET_IN_TOUCH_PAGE.SELECT_A_PURPOSE.PRODUCT_QUESTIONS'
   },
   {
      'value': 'Pricing Information',
      'name': 'GET_IN_TOUCH_PAGE.SELECT_A_PURPOSE.PRICING_INFORMATION'
   },
   {
      'value': 'Feedback/Complaints',
      'name': 'GET_IN_TOUCH_PAGE.SELECT_A_PURPOSE.FEEDBACK_COMPLAINTS'
   },
   {
      'value': 'General Questions',
      'name': 'GET_IN_TOUCH_PAGE.SELECT_A_PURPOSE.GENERAL_QUESTIONS'
   }
];
export const categoryOfApplications = [
   {
      'value': 'points of interests',
      'name': 'GET_IN_TOUCH_PAGE.CATEGORY_APPLICATIONS.POINTS_OF_INTERESTS'
   },
   {
      'value': 'data',
      'name': 'SHARED.DATA'
   },
   {
      'value': 'transportation',
      'name': 'GET_IN_TOUCH_PAGE.CATEGORY_APPLICATIONS.TRANSPORTATION'
   },
   {
      'value': 'parking',
      'name': 'GET_IN_TOUCH_PAGE.CATEGORY_APPLICATIONS.PARKING'
   },
   {
      'value': 'vehicle',
      'name': 'GET_IN_TOUCH_PAGE.CATEGORY_APPLICATIONS.VEHICLE'
   },
   {
      'value': 'logistics',
      'name': 'GET_IN_TOUCH_PAGE.CATEGORY_APPLICATIONS.LOGISTICS'
   },
   {
      'value': 'navigation',
      'name': 'Navigation'
   },
   {
      'value': 'telematics',
      'name': 'GET_IN_TOUCH_PAGE.CATEGORY_APPLICATIONS.TELEMATICS'
   },
   {
      'value': 'electrification',
      'name': 'SHARED.ELECTRIFICATION'
   },
   {
      'value': 'others',
      'name': 'SHARED.OTHERS'
   }
];

export const queryTypeData = [
   {
      id: '1',
      name: 'Product Information',
   },
   {
      id: '2',
      name: 'Pricing Information',
   },
   {
      id: '3',
      name: 'Terms & Conditions',
   },
   {
      id: '4',
      name: 'Other',
   },
];
export const supportData = {
   GENERAL: 'general',
   BUYER: 'buyer',
   SELLER: 'seller',
   DEFAULT_FAQ_TO_SHOW: 6
};

export const loginActionData = {
   TOKEN_ERROR: 'Error in generating tokens',
   REFRESH_TOKEN: 'auth.refresh_token',
   ACCESS_TOKEN: 'auth.access_token'
};

export const payloadConstants = {
   request: 'request',
   response: 'response'
};

export const contentTypeConstants = {
   notApplicable: 'Not Applicable',
   urlEncoded: 'application/x-www-form-urlencoded',
   multipartData: 'multipart/form-data',
   applicationJson: 'application/json',
   applicationXml: 'application/xml'
};

export const samplePayloads = {
   json: '{"key1": "value","key2": "value"}',
   xml: '<?xml version="1.0" encoding="UTF-8"?><example><heading>XML example</heading><body>example!</body></example>'
};

export const loadMoreConfigValue = 15;

export const DynamicFieldConstants = {
   TEXT: 'TEXT',
   TEXT_AREA: 'TEXTAREA',
   SINGLE_SELECT: 'SINGLESELECT',
   NUMBER: 'NUMBER',
 };
 
 export const quotationStatus = {
   DRAFT: 'DRAFT',
   APPROVED: 'APPROVED',
   SUBMITTED: 'SUBMITTED',
   REJECTED: 'REJECTED',
   EXPIRED: 'EXPIRED',
   REQUESTED: 'REQUESTED'
 };

 export const currencySymbols = {
   INR: '\u20B9 '
 };