import React, { useState } from 'react';
import {
  Box,
  Modal,
  Typography,
  IconButton,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import i18next from 'i18next';
import DeviceBreakPoints from '../../../deviceBreakPoints';
import { validateCostCenter } from '../../../validator/customValidator';
import styles from './cost-center-details.module.scss';
const CostCenterDetails = (props) => {
  const { theme } = DeviceBreakPoints();
  const [costCenter, setCostCenter] = useState('');
  const COST_CENTER_LENGTH = 6;

  const handleCostCenterInput = (event) => {
    const userInput = event.target.value.toUpperCase();
    if (validateCostCenter(userInput)) {
      setCostCenter(userInput);
    }
  };

  const handleSubmit = () => {
    props.handlePlaceOrder(costCenter);
    props.closeCostCenterPopUp();
  };

  return (
    <Modal open={props.open} sx={{ width: '100%', height: '100%' }}>
      <Box
        sx={{
          backgroundColor: 'primaryBackground.bgBody',
        }}
        className={styles['cost-center-popup']}
      >
        <Box>
          <Typography variant='h5' fontWeight='bold'>
            {i18next.t('SHARED.COST_CENTER.TITLE')}{' '}
          </Typography>
          <IconButton
            onClick={props.closeCostCenterPopUp}
            sx={{ color: 'common.dark' }}
            className={styles['cost-center-popup-close-btn']}
            size='small'
          >
            <CloseIcon
              title={i18next.t('FORM.CLOSE')}
              sx={{
                fontSize: '2rem',
                stroke: theme.palette.common.light,
                strokeWidth: 1,
              }}
            />
          </IconButton>
        </Box>
        <Typography fontWeight='bold'>
          {i18next.t('SHARED.COST_CENTER.DETAILS')}
        </Typography>
        <Grid container sx={{ paddingTop: '10px' }}>
          <Grid item xs={7} md={9} sm={8} lg={10}>
            <TextField
              required
              label={i18next.t('SHARED.COST_CENTER.TITLE')}
              variant='filled'
              value={costCenter}
              borderRadius='0px'
              onInput={handleCostCenterInput}
              placeholder={i18next.t(
                'SHARED.COST_CENTER.TEXTFIELD_PLACEHOLDER'
              )}
              sx={{
                width: '100%',
                paddingBottom: '15px',
                '& .MuiInputBase-root.MuiFilledInput-root': {
                  height: '3rem',
                },
                '& .MuiInputBase-root.MuiFilledInput-root:after': {
                  borderBottomColor: 'common.dark',
                },
                '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                  color: 'common.dark',
                },
              }}
            />
          </Grid>
          <Grid item xs={2} md={2} sm={2} lg={2}>
            <Button
              sx={{
                color: 'common.light',
                backgroundColor: 'primary.dark',
                height: '3rem',
                width: '5rem',
                borderRadius: '0px',
                position: 'absolute',
                right: '30px',
                textTransform: 'none',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                  cursor: 'pointer',
                },
                '&:disabled': {
                  color: 'common.light',
                  backgroundColor: 'primary.matt',
                  opacity: 1,
                  cursor: 'default',
                },
              }}
              disabled
            >
              {i18next.t('SHARED.COST_CENTER.BUTTON_CHECK')}
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12} md={12}>
          <Button
            onClick={() => handleSubmit()}
            sx={{
              color: 'common.light',
              backgroundColor: 'primary.dark',
              height: '3rem',
              width: '5rem',
              borderRadius: '0px',
              textTransform: 'none',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: 'primary.dark',
                cursor: 'pointer',
              },
              '&:disabled': {
                color: 'common.light',
                backgroundColor: 'primary.matt',
                opacity: 1,
                cursor: 'default',
              },
            }}
            disabled={costCenter.length !== COST_CENTER_LENGTH}
          >
            {i18next.t('FORM.SUBMIT')}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};
export default CostCenterDetails;
