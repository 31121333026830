// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-icons_icon-button-container__bu7Xa {
  display: flex !important;
  flex-direction: column !important;
  position: fixed;
  top: 70% !important;
  right: 0 !important;
  z-index: 999;
  transform: translateY(-50%);
}

.sticky-icons_getintouch-button__kkC8O {
  border-radius: 0 !important;
  min-width: 45px !important;
  min-height: 36px;
  margin-bottom: 2px !important;
  padding: 0 !important;
}

.sticky-icons_cart-button__xo2cZ {
  border-radius: 0 !important;
  min-width: 45px !important;
  min-height: 36px;
  margin-bottom: 2px !important;
  padding: 0 !important;
}

.sticky-icons_cart-icon__d1mhR {
  font-size: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/StickyIcons/sticky-icons.module.scss"],"names":[],"mappings":"AAEA;EACI,wBAAA;EACA,iCAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EAEA,2BAAA;AAGJ;;AAAA;EACI,2BAAA;EACA,0BAAA;EACA,gBAAA;EACA,6BAAA;EACA,qBAAA;AAGJ;;AAAA;EACI,2BAAA;EACA,0BAAA;EACA,gBAAA;EACA,6BAAA;EACA,qBAAA;AAGJ;;AADA;EACI,0BAAA;AAIJ","sourcesContent":["@import '../../../variables.module.scss';\n\n.icon-button-container{\n    display: flex !important;\n    flex-direction: column !important;\n    position: fixed;\n    top:70% !important;\n    right: 0 !important;\n    z-index: 999;\n    -webkit-transform: translateY(-50%);\n    transform: translateY(-50%);\n}\n\n.getintouch-button {\n    border-radius: 0 !important;\n    min-width: 45px !important;\n    min-height: 36px;\n    margin-bottom: 2px !important;\n    padding: 0 !important;\n}\n\n.cart-button {\n    border-radius: 0 !important;\n    min-width: 45px !important;\n    min-height: 36px;\n    margin-bottom: 2px !important;\n    padding: 0 !important;\n}\n.cart-icon {\n    font-size: 24px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"icon-button-container": `sticky-icons_icon-button-container__bu7Xa`,
	"getintouch-button": `sticky-icons_getintouch-button__kkC8O`,
	"cart-button": `sticky-icons_cart-button__xo2cZ`,
	"cart-icon": `sticky-icons_cart-icon__d1mhR`
};
export default ___CSS_LOADER_EXPORT___;
