import { DOCS } from '../actions/configuration';
import idConstants from './idConstants';
import i18next from 'i18next';

export const internalBuyerUserOnboardingForm = (props) => {
    return ({
        submitButtonTitle: i18next.t('INTERNAL_BUYER.USER.REGISTER'),
        title: {
            heading: i18next.t('INTERNAL_BUYER.USER.REGISTER')
        },
        steps: [
            {
                name: '',
                order: 1,
                displaySections: [
                    {
                        isAccordion: false,
                        sectionName: i18next.t('INTERNAL_BUYER.USER.FILL_DETAILS_MESSAGE'),
                        fields: [
                            {
                                fieldLabel: i18next.t('INTERNAL_BUYER.USER.FIRST_NAME'),
                                fieldName: 'firstName',
                                fieldType: 'text',
                                mandatory: true,
                                colXsSize: 12,
                                colLgSize: 12,
                                charLimit: 100,
                                isVisible: true,
                                regexPattern: /^[a-zA-Z ]*$/,
                                disabled: true,
                                fieldError: '',
                                value: props?.firstName
                            },
                            {
                                fieldLabel: i18next.t('INTERNAL_BUYER.USER.LAST_NAME'),
                                fieldName: 'lastName',
                                fieldType: 'text',
                                mandatory: true,
                                colXsSize: 12,
                                colLgSize: 12,
                                charLimit: 100,
                                isVisible: true,
                                regexPattern: /^[a-zA-Z ]*$/,
                                disabled: true,
                                fieldError: '',
                                value: props?.lastName
                            },
                            {
                                fieldLabel: i18next.t('INTERNAL_BUYER.USER.E_MAIL'),
                                fieldName: 'emailId',
                                fieldType: 'text',
                                mandatory: true,
                                colXsSize: 12,
                                colLgSize: 12,
                                isVisible: true,
                                disabled: true,
                                fieldError: '',
                                regexPattern: /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/,
                                value: props?.emailId
                            },
                            {
                                fieldLabel: i18next.t('SHARED.COUNTRY'),
                                fieldName: 'country',
                                fieldType: 'select',
                                mandatory: true,
                                colXsSize: 12,
                                colLgSize: 12,
                                isVisible: true,
                                disabled: true,
                                value: 'India',
                                selectOptions: [{
                                    displayName: 'India',
                                    value: 'India'
                                }]
                            },
                            [{
                                fieldLabel: i18next.t('INTERNAL_BUYER.USER.COUNTRY_CODE'),
                                fieldName: 'isdCode',
                                fieldType: 'text',
                                mandatory: true,
                                colXsSize: 12,
                                colLgSize: 4,
                                isVisible: true,
                                disabled: true,
                                parentField: 'country',
                                value: '+91'
                            },
                            {
                                fieldLabel: i18next.t('INTERNAL_BUYER.USER.PHONE_NUMBER'),
                                fieldName: 'phoneNo',
                                fieldType: 'text',
                                mandatory: true,
                                colXsSize: 12,
                                colLgSize: 7.5,
                                charLimit: 10,
                                isVisible: false,
                                disabled: false,
                                regexPattern: /^\d{1,10}$/,
                                parentField: 'country',
                                dependantAttributes: ['isVisible'],
                                fieldError: '',
                                minLength: 10,
                                value: props?.contactNo?.split(' ')[1]
                            }]
                        ]
                    }
                ],
                consentSection: [{
                    accepted: false,
                    displayName: '',
                    documentName: 'terms-document',
                    documentTypeId: idConstants.documentType.termsConditionsDocument,
                    documentUrl: DOCS.USER_MANUAL
                }]
            }
        ]
    });
  };