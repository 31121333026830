// SYSTEM_ACCOUNT_AUTH_TOGGLE boolean, keep it true for system account auth on
export const SYSTEM_ACCOUNT_AUTH_TOGGLE =
	process.env.REACT_APP_SYSTEM_ACCOUNT_AUTH_TOGGLE;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
const a = document.createElement('a');
a.href = window.location.href;
export const BUYER_BASE_URL = a.origin?.includes('localhost')
	? a.origin
	: process.env.REACT_APP_BUYER_BASE_URL;

export const KEYCLOAK_LOGIN_URI = process.env.CIAM_KEYCLOAK_LOGIN_URI;
export const KEYCLOAK_REALM = process.env.CIAM_KEYCLOAK_REALM;

// Authentication url for login and refresh token
export const AUTHENTICATION_URL = process.env.REACT_APP_AUTHENTICATION_URL;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;

// Google Analytics Tracking ID
export const GOOGLE_ANALYTICS_TRACKING_ID =
	process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

// Google ReCaptcha Site Key
export const GOOGLE_RECAPTCHA_SITE_KEY =
	process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

export const REACT_APP_ENVIRONMENT = {
	DEV: 'DEV',
	DEV_BEST: 'DEV-BEST',
	IMT: 'IMT',
	QA: 'QA',
	QA_INT: 'QA-INT',
	QA_EU: 'QA-EU',
	QA_BEST: 'QA-BEST',
	STAGE: 'STAGE',
	STAGE_BEST: 'STAGE-BEST',
	PROD: 'PROD'
};

export const SERVICE_VERSION = 'api/v1';
export const SERVICE_VERSION_2 = 'api/v2';
export const API_CATEGORY = 'apicategory';
export const PRODUCT_SERVICE = 'product';
export const PROPOSAL_SERVICE = 'proposal';
export const USER_SERVICE = 'userservice';
export const DOCUMENT_SERVICE = 'document';
export const NOTIFICATION_SERVICE = 'notification';
export const AUDIT_LOG_SERVICE = 'audit-log';
export const ORDER_SERVICE = 'order';
export const PRICING_SERVICE = 'pricing';
export const RATING_SERVICE = 'rating';
export const CATEGORY_SERVICE = 'category';
export const PRODUCT_SEARCH = 'productsearch';
export const ORDER_FULFILLMENT_SERVICE = `orderfulfillment`;
export const PRODUCT_SERVICE_URL = `${BASE_URL}/${PRODUCT_SERVICE}/${SERVICE_VERSION}`;
export const PRODUCT_SERVICE_URL_2 = `${BASE_URL}/${PRODUCT_SERVICE}/${SERVICE_VERSION_2}`;
export const PROPOSAL_SERVICE_URL = `${BASE_URL}/${PROPOSAL_SERVICE}/${SERVICE_VERSION}`;
export const USER_SERVICE_URL = `${BASE_URL}/${USER_SERVICE}/${SERVICE_VERSION}`;
export const DOCUMENT_SERVICE_URL = `${BASE_URL}/${DOCUMENT_SERVICE}/${SERVICE_VERSION}`;
export const NOTIFICATION_SERVICE_URL = `${BASE_URL}/${NOTIFICATION_SERVICE}/${SERVICE_VERSION}`;
export const ORDER_SERVICE_URL = `${BASE_URL}/${ORDER_SERVICE}/${SERVICE_VERSION}`;
export const ORDER_SERVICE_URL_2 = `${BASE_URL}/${ORDER_SERVICE}/${SERVICE_VERSION_2}`;
export const PRICING_SERVICE_URL = `${BASE_URL}/${PRICING_SERVICE}/${SERVICE_VERSION}`;
export const RATING_SERVICE_URL = `${BASE_URL}/${RATING_SERVICE}/${SERVICE_VERSION}`;
export const CATEGORY_SERVICE_URL = `${BASE_URL}/${CATEGORY_SERVICE}/${SERVICE_VERSION}`;
export const AUDIT_LOG_SERVICE_URL = `${BASE_URL}/${AUDIT_LOG_SERVICE}/${SERVICE_VERSION}`;
export const CART_SERVICE_URL = `${BASE_URL}/${ORDER_SERVICE}/${SERVICE_VERSION}`;
export const ORDER_FULFILLMENT_SERVICE_URL = `${BASE_URL}/${ORDER_FULFILLMENT_SERVICE}/${SERVICE_VERSION}`;

export const DCS_CONSTANTS = {
	KEYCLOAK_URI: process.env.REACT_APP_CIAM_KEYCLOAK_LOGIN_URI,
	ENDPOINT_SERVER: process.env.REACT_APP_DCS_SERVER,
	STORE_ID: process.env.REACT_APP_DCS_STORE_ID,
	DCS_IDP: process.env.REACT_APP_DCS_IDP
};

export const AAA_CONFIG = {
	SERVER_URI: process.env.REACT_APP_AAA_SERVER_URI,
	CLIENT_ID: process.env.REACT_APP_AAA_CLIENT_ID,
	ROLEUPDATE_URI: process.env.REACT_APP_ROLE_UPDATE_URI,
	ROLEUPDATE_KEY: process.env.REACT_APP_ROLE_UPDATE_ACCESS_KEY,
	BUYER_ROLE: 'BUYER',
	SELLER_ROLE: 'SELLER'
};

export const DCS_CONFIG = {
	CLIENT_ID: process.env.REACT_APP_DCS_AUTH_CLIENT_ID
};

export const DOCS_URL = {
	USER_MANUAL: `${process.env.REACT_APP_BLOB_URL}/qa-prod-docs/938082de77aae0dd0177aec3a0fd02cb/6e952be4-12b0-4753-a0da-7591c618f51c`,
	VIDEO: `${process.env.REACT_APP_BLOB_URL}/qa-prod-docs/938082de77aae0dd0177aec3a0fd02cb/d5e6c74b-8246-4cbb-9401-aeae8c98760b`
};

export const SEND_EMAIL_WITH_KEY_BODY = `${NOTIFICATION_SERVICE_URL}/notification/email?key=`;

export const FILE_UPLOAD = {
	UPLOADFILE: `${DOCUMENT_SERVICE_URL}/docs/`
};

export const LANDING_ACTION = {
	GET_FILTER_CATEGORIES: `${BASE_URL}/${PRODUCT_SEARCH}/filters`,
	GET_FILTER_CATEGORIES_GROUP_BY: `${BASE_URL}/${PRODUCT_SEARCH}/search`,
	GET_PRODUCT_CATEGORIES: `${PRODUCT_SERVICE_URL}/categories`,
	GET_FILTERED_PUBLISHED_PRODUCT: `${BASE_URL}/${PRODUCT_SEARCH}/search`,
	CREATE_APPLICATION: `${ORDER_SERVICE_URL}/${API_CATEGORY}/user-application`,
	GET_POPULAR_PRODUCTS: `${ORDER_SERVICE_URL}/product/popular/`,
	GET_SELLER_PRODUCTS: `${PRODUCT_SERVICE_URL}/productAndCompany/`
};

export const MASTER_DATA_ACTION = {
	GET_PRODUCT_MASTERDATA: `${PRODUCT_SERVICE_URL}/masterdata`,
	GET_SUBCATEGORIES: `${PRODUCT_SERVICE_URL}/subCategories`,
	GET_PRICING_MASTERDATA: `${PRICING_SERVICE_URL}/masterdata`,
	GET_ORDER_MASTERDATA: `${ORDER_SERVICE_URL}/masterdata`
};

export const PRODUCT_DETAIL_ACTION = {
	GET_GATEWAY_PATH: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/gateway/`,
	GET_PRODUCT_BY_ID: `${PRODUCT_SERVICE_URL_2}/additionalInfo/`,
	GET_PRODUCT_REVIEW_RATING: `${RATING_SERVICE_URL}/review/`,
	GET_PRODUCT_DOCS_LIST_BY_ID: `${DOCUMENT_SERVICE_URL}/docs/productDocMetaInfo/`,
	GET_GRANTTYPE_REQ_ATTRIBUTE: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/grantType/reqAttributes`,
	USER_APPLICATION: `${ORDER_SERVICE_URL}/${API_CATEGORY}/user-application`,
	SEND_EMAIL: `${NOTIFICATION_SERVICE_URL}/notification/email?key=CustomPlan`,
	SWAGGER_FILE_DOWNLOAD: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/`,
	GET_PRODUCT_QTY: `${CATEGORY_SERVICE_URL}/quantity/`,
	GET_PRODUCT_DISPLAY_NAMES: `${CATEGORY_SERVICE_URL}/form?productCategoryId=`,
	GET_TRYOUT_STATUS: `${ORDER_SERVICE_URL}/plandetails?`
};

export const PRICING_PLAN_ACTION = {
	VALIDATE_PLAN_NAME_UNIQUENESS: `${PRICING_SERVICE_URL}/`,
	SUBSCRIBE_PLAN: `${ORDER_SERVICE_URL}/`,
	SUBSCRIPTIONS: `${ORDER_SERVICE_URL}/subscription`,
	GENERATE_WSO2_ACCESS_TOKEN: `${ORDER_SERVICE_URL}/${API_CATEGORY}/user-application/`,
	ENABLE_TRYOUT: `${ORDER_SERVICE_URL}/${API_CATEGORY}/tryout/`,
	GET_USER_CONSENT: `${ORDER_SERVICE_URL_2}/productUserConsent/`
};

export const API_STATS_ACTION = {
	USAGE_TRACKING: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/tracking/analytics`,
	USAGE_METRICS: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/tracking/metrics`
};

export const sellerAccountRedirect = {
	sellerAccountLink: `${process.env.REACT_APP_SELLER_BASE_URL}/switch-portal`
};

export const USER_DETAILS_ACTION = {
	USER_DETAILS: `${USER_SERVICE_URL}/user`,
	CURRENT_USER_DETAILS: `${DCS_CONSTANTS.ENDPOINT_SERVER}/services/user/api/b2b-account-management/storefront/v3/${DCS_CONSTANTS.STORE_ID}/my-user`
};

export const REGISTER_USER_ACTION = {
	GET_COUNTRY_LIST: `${USER_SERVICE_URL}/address/country`
};

export const REGISTER_ACTION = {
	CHECK_USER_AGREEMENT: `${USER_SERVICE_URL}/user/consent`,
	ACCEPT_USER_AGREEMENT_BY_MASTER_ID: `${USER_SERVICE_URL}/user/agreementByMasterId?agreementMasterId=`,
	CREATE_USER_ENTRY: `${USER_SERVICE_URL}/user`,
	DIRECT_REGISTER_LINK: `${process.env.REACT_APP_CIAM_SERVER}/ui/web/registration`,
	//MS-19292 Adding api calls for BEST marketplace user registration.
	SAVE_REGISTER_USER_DETAILS: `${USER_SERVICE_URL}/user/register?role=${AAA_CONFIG.BUYER_ROLE}`,
	ACCEPT_USER_COMPANY_TERMS: `${USER_SERVICE_URL}/user/companyTermsAgreements?role=${AAA_CONFIG.BUYER_ROLE}&retrieveCompanydetails=false`,
	CREATE_COMPANY: `${USER_SERVICE_URL}/user/company?role=${AAA_CONFIG.BUYER_ROLE}`
};

export const LOGIN_ACTION = {
	CHECK_EULA_ACCEPTANCE: `${USER_SERVICE_URL}/user/eulaAcceptance`,
	ACCEPT_EULA: `${USER_SERVICE_URL}/user/acceptEula`,
	FORGOT_PASSWORD: `${USER_SERVICE_URL}/user/forgotPassword`,
	ACCESS_TOKEN_DATA: `${USER_SERVICE_URL}/tokenDetails`,

	GET_AAA_AUTH_CODE: `${AAA_CONFIG.SERVER_URI}/auth`,
	GET_AAA_ACCESS_TOKEN: `${AAA_CONFIG.SERVER_URI}/token`,
	GET_DCS_AUTH_CODE: `${DCS_CONSTANTS.KEYCLOAK_URI}/auth`,
	GET_DCS_ACCESS_TOKEN: `${DCS_CONSTANTS.KEYCLOAK_URI}/token`,
	LOGOUT_AUTH_CODE: `${AAA_CONFIG.SERVER_URI}/logout`,
	DCS_LOGOUT_AUTH_CODE: `${DCS_CONSTANTS.KEYCLOAK_URI}/logout`,
	USER_DEREGISTRATION: `${process.env.REACT_APP_CIAM_SERVER}/ui/web/deregistration?styleId=${process.env.REACT_APP_CIAM_STYLE_ID}`
};

export const DELETE_ACTION = {
	ACCOUNT_DELETION_CHECK: `${USER_SERVICE_URL}/user/checkStatus`,
	REQUEST_FOR_ACCOUT_DELETION: `${USER_SERVICE_URL}/user`,
	DELETE_MS_ACCOUNT: `${USER_SERVICE_URL}/user/deregister?role=${AAA_CONFIG.BUYER_ROLE}`
};

export const IMAGE_CDN = `${process.env.REACT_APP_CDN_LINK}/assets/img/`;

export const DOCS = {
	USER_ONBOARDING_MANUAL: `${process.env.REACT_APP_CDN_LINK}/assets/docs/user-onboarding-manual-v2.pdf`,
	USER_MANUAL: `${process.env.REACT_APP_CDN_LINK}/ms-public-images/mss-support-docs/user-manual-v2.pdf`,
	API_ONBOARDING_MANUAL: `${process.env.REACT_APP_CDN_LINK}/assets/docs/api-onboarding-manual-v2.pdf`,
	VIDEO: `${process.env.REACT_APP_CDN_LINK}/ms-public-images/MobilityMarketplaceDemo.mp4`
};

export const REDIRECT_URL = `${BUYER_BASE_URL}/callback`;

export const COMPANY = {
	GET_MYCOMPANY: `${DCS_CONSTANTS.ENDPOINT_SERVER}/services/user/api/b2b-account-management/storefront/v3/${DCS_CONSTANTS.STORE_ID}/my-company`,
	UPDATE_DCS_DETAILS: `${USER_SERVICE_URL}/user/dcsCompanyRegistrationDetails`,
	COMPANY_STATUS: `${USER_SERVICE_URL}/user/companyRegistrationStatus?role=${AAA_CONFIG.BUYER_ROLE}`,
	GET_MS_COMPANY_DETAILS: `${USER_SERVICE_URL}/user/companyRegistrationDetails?role=${AAA_CONFIG.BUYER_ROLE}`,
	GET_MYCOMPANY_STATUS: `${USER_SERVICE_URL}/user/status`,
	FETCH_STORE_ID: `${ORDER_SERVICE_URL_2}/store`
};

export const CALLBACKS = {
	DCS_AUTH_CALLBACK: `${BUYER_BASE_URL}/callback-dcs-token`,
	AAA_AUTH_CALLBACK: `${BUYER_BASE_URL}/callback-token`,
	DEREGISTRATION_CALLBACK: `${BUYER_BASE_URL}/deregistration`,
	FEDERATED_CALLBACK: `${BUYER_BASE_URL}/federated-callback`
};

export const REGISTER_DOCUMENT = {
	DOWNLOAD_DOCUMENTS: `${DOCUMENT_SERVICE_URL}/user/`
};

export const MANAGE_PRODUCTS = {
	PRODUCT_META_DATA: `${PRODUCT_SERVICE_URL}/productSeo`
};

export const CONTACT_US = {
	USER_QUERY: `${USER_SERVICE_URL}/user/query`
};

export const REQUEST_INFORMATION = {
	REQ_INFO: `${USER_SERVICE_URL}/user/requestInfo`
};

export const ORDER_HISTORY = {
	GET_ORDERS: `${ORDER_SERVICE_URL_2}/order/product`
};

export const PRODUCT = {
	GET_PRODUCT_DETAILS: `${DCS_CONSTANTS.ENDPOINT_SERVER}/services/product/api/product-catalog/storefront/v1/${DCS_CONSTANTS.STORE_ID}/variants`,
	GET_PRODUCT_SKU_IDS: `${PRICING_SERVICE_URL}`
};

export const MYPROPOSAL = {
	GET_PROPOSAL_LIST: `${PROPOSAL_SERVICE_URL}/proposal/`,
	GET_PROPOSAL_DOC: `${PROPOSAL_SERVICE_URL}/proposalOverview?proposalId=`,
	STATUS_PROPOSAL_API: `${PROPOSAL_SERVICE_URL}/proposal/status/`
};

export const CALLBACK_PARAM = {
	AAA_CALLBACK: 'callback-token',
	DCS_CALLBACK: 'callback-dcs-token',
	SWITCH_CALLBACK: 'switch-portal',
	DEREGISTRATION_CALLBACK: 'deregistration',
	FEDERATED_CALLBACK: 'federated-callback'
};

export const COOKIE_ACTION = {
	SET_COOKIE: `${PRODUCT_SERVICE_URL}/cookie?domain=${process.env.REACT_APP_DOMAIN}&cookieName=`,
	DELETE_COOKIE: `${PRODUCT_SERVICE_URL}/cookie?domain=${process.env.REACT_APP_DOMAIN}&cookieName=`,
	GET_COOKIE: `${PRODUCT_SERVICE_URL}/cookie/`
};

export const AUDIT = {
	LOG_DATA: `${AUDIT_LOG_SERVICE_URL}/audit`
};

export const SELLER_NAMES = {
	NAMES: `${USER_SERVICE_URL}/user/registrationApprovals?status=approved&role=SELLER`
};

export const CAMPAIGN_PAGE = {
	CAMPAIGN_PRODUCTS: process.env.REACT_APP_CAMPAIGN_PRODUCTS_CONFIG_FILE
};

export const TENANT = {
	TOS: 'tos',
	MKOSINT: 'mkosint'
};

export const ORDER_FULFILLMENT = {
	GET_ORDER_SUMMARY: `${ORDER_FULFILLMENT_SERVICE_URL}/orderSummary`,
	GET_ORDER_STATUS: `${ORDER_FULFILLMENT_SERVICE_URL}/order/orderTransition`,
	CHANGE_ORDER_STATUS: `${ORDER_FULFILLMENT_SERVICE_URL}/order/orderState`,
	GET_COMMENTS: `${ORDER_FULFILLMENT_SERVICE_URL}/comment`,
	GET_USER_BILLING_ADDRESS: `${USER_SERVICE_URL}/user/companyRegistrationDetails?role=${AAA_CONFIG.BUYER_ROLE}`
};

export const GET_PRODUCT_IMAGE = `${CATEGORY_SERVICE_URL}/product/image`;

export const TOOLS = {
	DOCHUB_DEPLOYMENT:
		'https://api.github.com/repos/bosch-bgsw-top99-ops/dochub-deployment/actions/workflows/deploy-dochub-instances.yaml/dispatches'
};

export const CART_ACTION = {
	PRODUCT_CART: `${CART_SERVICE_URL}/productCart`,
	PRICING_DETAILS: `${PRICING_SERVICE_URL}/policies/pricingPolicies`,
	GET_MINIMUM_ORDER_QUANTITY: `${PRODUCT_SERVICE_URL}/quantity/`
};

export const QUOTATION_ACTION = {
	GET_QUOTATION_FORM: `${PROPOSAL_SERVICE_URL}/quotation/form`,
	CREATE_QUOTATION: `${PROPOSAL_SERVICE_URL}/quotation/request`,
	GET_QUOTATION_DOCUMENTS: `${DOCUMENT_SERVICE_URL}/quotation/docs`,
	GET_FILE_UPLOAD_CONFIG: `${DOCUMENT_SERVICE_URL}/config`,
	GET_QUOTATION_PRICE: `${PROPOSAL_SERVICE_URL}/quotationPrice`,
	GET_QUOTATION: `${PROPOSAL_SERVICE_URL}/getQuotation`,
	QUOTATION_BUYER_ACTION: `${PROPOSAL_SERVICE_URL}/quotation/buyerAction`,
	QUOTATION_LISTING: `${PROPOSAL_SERVICE_URL}/getQuotationRequests`,
	GET_QUOTATION_RESPONSE: `${PROPOSAL_SERVICE_URL}/getQuotationResponses`,
	QUOTATION_CONTACT_SALES: `${PROPOSAL_SERVICE_URL}/quotation/contactsales`
};

export const ORDER = {
	SAVE_ORDER: `${ORDER_SERVICE_URL_2}/order/product`
};
