import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import reactGA from 'react-ga';
import i18next from 'i18next';
import { Box, Button, Typography, Stack } from '@mui/material';
import styles from './animated-text.module.scss';
import DeviceBreakPoints from '../../../../deviceBreakPoints';

const AnimatedText = (props) => {
  const { isSmallDevice, isTablet, isPhone, isMediumDeviceOnly } =
    DeviceBreakPoints();

  const allProdUrl = {
    pathname: '/listing',
  };

  const handleExploreMoreClick = () => {
    // reactGA.event({
    //   category: 'Explore More',
    //   action: 'User Clicked on Explore More',
    //   label: 'Explore More'
    // });
  };

  return (
    <Box
      py={4}
      textAlign={isSmallDevice ? 'center' : 'left'}
      sx={{ color: 'primary.contrastText' }}
    >
      {isPhone && (
        <React.Fragment>
          <Box mt={6}>
            <Stack direction='column' justifyContent='center'>
              <Typography
                noWrap
                component='div'
                variant='h6'
                className={styles['banner-text-small']}
              >
                {i18next.t(
                  'HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.ONE_STOP_PLATFORM'
                )}
              </Typography>
              <Box className={styles['banner-text-container-phone']}>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-small']}
                >
                  {i18next.t('SHARED.SAAS')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-small']}
                >
                  {i18next.t('SHARED.PAAS')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-small']}
                >
                  {i18next.t('SHARED.IAAS')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-small']}
                >
                  {i18next.t('SHARED.PRE_DEFINED_WORKFLOW')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-small']}
                >
                  {i18next.t('SHARED.CUSTOM_WORKFLOW')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-small']}
                >
                  {i18next.t('SHARED.METHODS')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-small']}
                >
                  {i18next.t('SHARED.EVERYTHING')}
                </Typography>
              </Box>
              {/* MS-15456 : Hiding Mobility Domain text as part of BGSW tools POC */}
              {/* <Typography
                noWrap
                component='div'
                variant='h6'
                className={styles['banner-text-small']}
              >
                {i18next.t(
                  'HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.MOBILITY_DOMAIN'
                )}
              </Typography> */}
            </Stack>
          </Box>
        </React.Fragment>
      )}
      {isTablet && (
        <React.Fragment>
          <Box>
            <Stack direction='column' justifyContent='center'>
              <Typography noWrap variant='mainHeading'>
                {i18next.t(
                  'HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.ONE_STOP_PLATFORM'
                )}
              </Typography>
              <Box className={styles['banner-text-container-tablet']}>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-tablet']}
                >
                  {i18next.t('SHARED.SAAS')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-tablet']}
                >
                  {i18next.t('SHARED.PAAS')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-tablet']}
                >
                  {i18next.t('SHARED.IAAS')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-tablet']}
                >
                  {i18next.t('SHARED.PRE_DEFINED_WORKFLOW')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-tablet']}
                >
                  {i18next.t('SHARED.CUSTOM_WORKFLOW')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-tablet']}
                >
                  {i18next.t('SHARED.METHODS')}
                </Typography>
                <Typography
                  noWrap
                  component='div'
                  variant='h3'
                  className={styles['banner-changing-text-tablet']}
                >
                  {i18next.t('SHARED.EVERYTHING')}
                </Typography>
              </Box>
              {/* MS-15456 : Hiding Mobility Domain text as part of BGSW tools POC */}
              {/* <Typography
                noWrap
                component='div'
                variant='h5'
                className={styles['banner-text']}
              >
                {i18next.t('HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.MOBILITY_DOMAIN')}
              </Typography> */}
            </Stack>
          </Box>
        </React.Fragment>
      )}
      {isMediumDeviceOnly && (
        <React.Fragment>
          <Typography noWrap variant='h4' className={styles['banner-text']}>
            {i18next.t('HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.ONE_STOP_PLATFORM')}
          </Typography>
          <Box className={styles['banner-text-container']}>
            <Typography
              noWrap
              component='div'
              variant='h3'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.SAAS')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h3'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.PAAS')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h3'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.IAAS')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h3'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.PRE_DEFINED_WORKFLOW')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h3'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.CUSTOM_WORKFLOW')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h3'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.METHODS')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h3'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.EVERYTHING')}
            </Typography>
          </Box>
          {/* MS-15456 : Hiding Mobility Domain text as part of BGSW tools POC */}
          {/* <Typography noWrap variant='h4' className={styles['banner-text']}>
            {i18next.t('HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.MOBILITY_DOMAIN')}
          </Typography> */}
        </React.Fragment>
      )}
      {!isMediumDeviceOnly && !isTablet && !isPhone && (
        <React.Fragment>
          <Typography noWrap variant='mainHeading'>
            {i18next.t('HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.ONE_STOP_PLATFORM')}
          </Typography>
          <Box className={styles['banner-text-container']}>
            <Typography
              noWrap
              component='div'
              variant='h4'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.SAAS')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h4'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.PAAS')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h4'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.IAAS')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h4'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.PRE_DEFINED_WORKFLOW')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h4'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.CUSTOM_WORKFLOW')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h4'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.METHODS')}
            </Typography>
            <Typography
              noWrap
              component='div'
              variant='h4'
              className={styles['banner-changing-text']}
            >
              {i18next.t('SHARED.EVERYTHING')}
            </Typography>
          </Box>
          {/* MS-15456 : Hiding Mobility Domain text as part of BGSW tools POC */}
          {/* <Typography
            noWrap
            variant='mainHeading'
          >
            {i18next.t('HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.MOBILITY_DOMAIN')}
          </Typography> */}
        </React.Fragment>
      )}
      {props.page !== i18next.t('CAMPAIGN_PAGE.CAMPAIGN') && (
        <Box mt={2}>
          <Button
            variant='filledBtn'
            disableElevation
            sx={{ padding: '8px 16px !important' }}
            component={RouterLink}
            to={allProdUrl}
            onClick={handleExploreMoreClick}
          >
            {i18next.t('HOME_PAGE.REVAMPED_HOMEPAGE_BANNER.EXPLORE_MORE')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AnimatedText;
