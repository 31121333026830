// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-support-dialog_dialog-grid-container__u0rFK {
  margin: 8px;
  padding: 20px 30px;
  border: 1px solid;
}

.contact-support-dialog_dialog-close-icon__DkDK4 {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  z-index: 1000;
}

.contact-support-dialog_text-center-alignment__E1PUM {
  text-align: center;
  min-width: 550px;
}

.contact-support-dialog_left-content__Pba5j {
  overflow-wrap: anywhere;
  padding-right: 10px;
}

.contact-support-dialog_right-content__IFaOF {
  text-align: end;
}

@media only screen and (max-width: 900px) {
  .contact-support-dialog_left-content__Pba5j {
    margin-bottom: 10px;
    padding-right: 0;
  }
  .contact-support-dialog_right-content__IFaOF {
    text-align: justify;
  }
  .contact-support-dialog_text-center-alignment__E1PUM {
    min-width: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Utils/ContactSupportDialog/contact-support-dialog.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI;IACI,mBAAA;IACA,gBAAA;EACN;EACE;IACI,mBAAA;EACN;EAEE;IACI,YAAA;EAAN;AACF","sourcesContent":[".dialog-grid-container {\n    margin: 8px;\n    padding: 20px 30px;\n    border: 1px solid;\n}\n\n.dialog-close-icon {\n    position: absolute;\n    top: 1rem;\n    right: 0.5rem;\n    z-index: 1000;\n}\n\n.text-center-alignment {\n    text-align: center;\n    min-width: 550px;\n}\n\n.left-content {\n    overflow-wrap: anywhere;\n    padding-right: 10px;\n}\n\n.right-content {\n    text-align: end;\n}\n\n@media only screen and (max-width: 900px) {\n    .left-content{\n        margin-bottom: 10px;\n        padding-right: 0;\n    }\n    .right-content {\n        text-align: justify;\n    }\n\n    .text-center-alignment {\n        min-width: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog-grid-container": `contact-support-dialog_dialog-grid-container__u0rFK`,
	"dialog-close-icon": `contact-support-dialog_dialog-close-icon__DkDK4`,
	"text-center-alignment": `contact-support-dialog_text-center-alignment__E1PUM`,
	"left-content": `contact-support-dialog_left-content__Pba5j`,
	"right-content": `contact-support-dialog_right-content__IFaOF`
};
export default ___CSS_LOADER_EXPORT___;
