// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_footer__l89AG {
  font-size: 0.94em;
}

.footer_other-links__V-AtE {
  font-size: 15px;
  cursor: pointer;
}

.footer_footer-link__k2Eih {
  font-size: 0.75rem;
  text-align: center;
}

.footer_cursor-pointer-link__Rk8l8 {
  cursor: pointer;
}

.footer_arrow-top__cmPc\\+ {
  width: 3rem;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 8px;
  z-index: 1;
  transform: rotate(180deg);
  filter: brightness(0.5);
}

.footer_footer-margin__eCXDT {
  margin-bottom: 10px;
}

.footer_content-alignment__x34Su {
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/components/BaseLayout/Footer/footer.module.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAIF;;AADA;EACE,eAAA;EACA,eAAA;AAIF;;AADA;EACE,kBAAA;EACA,kBAAA;AAIF;;AADA;EACE,eAAA;AAIF;;AADA;EACE,WAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,UAAA;EACA,UAAA;EACA,yBAAA;EACA,uBAAA;AAIF;;AADA;EACE,mBAAA;AAIF;;AADA;EACE,mBAAA;AAIF","sourcesContent":["@import '../../../variables.module.scss';\n.footer {\n  font-size: 0.94em;\n}\n\n.other-links {\n  font-size: 15px;\n  cursor: pointer;\n}\n\n.footer-link {\n  font-size: 0.75rem;\n  text-align: center;\n}\n\n.cursor-pointer-link {\n  cursor: pointer;\n}\n\n.arrow-top {\n  width: 3rem;\n  cursor: pointer;\n  position: fixed;\n  bottom: 20px;\n  right: 8px;\n  z-index: 1;\n  transform: rotate(180deg);\n  filter: brightness(0.5);\n}\n\n.footer-margin {\n  margin-bottom: 10px;\n}\n\n.content-alignment {\n  vertical-align: top;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"footer": `footer_footer__l89AG`,
	"other-links": `footer_other-links__V-AtE`,
	"footer-link": `footer_footer-link__k2Eih`,
	"cursor-pointer-link": `footer_cursor-pointer-link__Rk8l8`,
	"arrow-top": `footer_arrow-top__cmPc+`,
	"footer-margin": `footer_footer-margin__eCXDT`,
	"content-alignment": `footer_content-alignment__x34Su`
};
export default ___CSS_LOADER_EXPORT___;
