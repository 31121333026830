import axios from 'axios';
import jwt_decode from 'jwt-decode';
import * as Constants from './configuration';
import base64url from 'base64url';
import qs from 'qs';
import { isExpired } from 'react-jwt';
// import reactGA from 'react-ga';
import { loginActionData } from '../data/appConstants';
import idConstants from '../data/idConstants';

const crypto = require('crypto');
let fetchDCSTokensPromise = null;

export const sessionExpiryLogOut = (pathname) => async (dispatch) => {
  const prevUserName = localStorage.userName;
  await dispatch(clearLoginStorage());

  dispatch({
    type: 'UPDATE_COMPANY_ERROR',
    payload: {},
  });
  dispatch({
    type: 'UPDATE_COMPANY_DETAILS',
    payload: {},
  });
  localStorage.setItem('prevUserName', prevUserName);
  localStorage.setItem('prevPathName', pathname);
  federatedAuthCode();
};

export const clearLoginStorage = () => (dispatch) => {
  localStorage.removeItem('userName');
  localStorage.removeItem(idConstants.tokens.aaaIdToken);
  localStorage.removeItem(idConstants.tokens.aaaCode);
  localStorage.removeItem(idConstants.tokens.aaaVerifier);
  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
    localStorage.removeItem(idConstants.tokens.dcsVerifier);
    localStorage.removeItem(idConstants.tokens.dcsJwtToken);
    localStorage.removeItem(idConstants.tokens.dcsIdToken);
    localStorage.removeItem(idConstants.tokens.dcsCode);
    localStorage.removeItem(idConstants.tokens.dcsRefreshToken);
  }
  localStorage.removeItem(idConstants.tokens.aaaJwtToken);
  localStorage.removeItem(idConstants.tokens.aaaRefreshToken);

  dispatch({
    type: 'SET_CURRENT_USER',
    payload: false,
  });
};

/**
 * Step1 - On click of login call AAA auth url -> login/register/forgot password
 * Step2 - After login manage callback-token route will start,  get auth code store in localStorge
 * Step3 - Use authcode and client_id&client_secret to get access_token/... from AAA /token and store in localStorage
 * Step4 - Since DCS doesn't recognize AAA token, we need to call DCS keycloak server to get DCS authcode and tokens in the same format as AAA
 *
 */

const generateCodeVerifier = (verifierType) => {
  let verifier = '';
  const length = 128;
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  const bytes = crypto.randomBytes(length);

  for (let index = 0; index < length; index++) {
    verifier += characters.charAt(bytes[index] % charactersLength);
  }
  localStorage.setItem(verifierType, verifier);
  return verifier;
};

export const handleAAASignupLogin = () => {
  // To redirect, to the same current path after login, We are setting 'pathBeforeLogin' variable to the current path.
  localStorage.setItem(
    'pathBeforeLogin',
    window?.location?.pathname + window?.location?.hash
  );

  const params = new URLSearchParams();
  const hash = crypto
    .createHash('sha256')
    .update(generateCodeVerifier(idConstants.tokens.aaaVerifier))
    .digest();
  const code_challenge = base64url.encode(hash);

  params.append('client_id', Constants.AAA_CONFIG.CLIENT_ID);
  params.append('redirect_uri', Constants.CALLBACKS.AAA_AUTH_CALLBACK);
  params.append('response_type', 'code');
  params.append('scope', 'openid email profile offline_access');
  params.append('code_challenge', code_challenge);
  params.append('code_challenge_method', 'S256');
  params.append('styleId', process.env.REACT_APP_CIAM_STYLE_ID);
  params.append('style_id', process.env.REACT_APP_SKID_STYLE_ID);
  process.env.REACT_APP_SKID_STYLE_IDP_HINT &&
    params.append('kc_idp_hint', process.env.REACT_APP_SKID_STYLE_IDP_HINT);
  const authCodeUrl = `${Constants.LOGIN_ACTION.GET_AAA_AUTH_CODE}?${params}`;
  window.location.href = authCodeUrl;
};

export const getAccessTokenData = (authCode) => {
  return async (dispatch) =>
    await fetch(`${Constants.LOGIN_ACTION.ACCESS_TOKEN_DATA}`, {
      credentials: 'same-origin',
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + authCode,
      },
    })
      .then((response) => response?.json())
      .then((data) => {
        localStorage.setItem('userName', data.name);
        dispatch({
          type: 'SET_CURRENT_USER',
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_LOGIN',
          error: error?.response?.status || error,
        });
        dispatch(logout());
      });
};

export const generateAAAaccessToken = (authCode) => {
  const requestObj = {
    code: authCode,
    grant_type: 'authorization_code',
    client_id: Constants.AAA_CONFIG.CLIENT_ID,
    redirect_uri: Constants.CALLBACKS.AAA_AUTH_CALLBACK,
    code_verifier: localStorage.aaaVerifier,
    scope: 'openid',
  };
  const formBody = encodeFormBody(requestObj);
  return async (dispatch) =>
    await fetch(`${Constants.LOGIN_ACTION.GET_AAA_ACCESS_TOKEN}`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    })
      .then((response) => response?.json())
      .then(async (data) => {
        const { access_token, refresh_token, id_token } = data;
        if (!access_token || !refresh_token || !id_token) {
          throw loginActionData.TOKEN_ERROR;
        }
        // store the token in the localStorage
        localStorage.setItem(idConstants.tokens.aaaJwtToken, access_token);
        localStorage.setItem(idConstants.tokens.aaaRefreshToken, refresh_token);
        localStorage.setItem(idConstants.tokens.aaaIdToken, id_token);
        localStorage.setItem('newLogin', true);
        await dispatch({
          type: 'REFRESH_TOKEN',
          payload: refresh_token,
        });

        await dispatch({
          type: 'ACCESS_TOKEN',
          payload: id_token,
        });

        await dispatch(getAccessTokenData(id_token));

        // reactGA.event({
        //   category: 'Sign In',
        //   action: 'User Successfully loggedIn',
        //   label: 'Sign In'
        // });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_LOGIN',
          error: error?.response?.status || error,
        });
        dispatch(logout());
      });
};

export const regenerateAAATokens = () => {
  const requestObj = {
    code: localStorage.getItem(idConstants.tokens.aaaCode),
    grant_type: 'refresh_token',
    client_id: Constants.AAA_CONFIG.CLIENT_ID,
    code_verfier: localStorage.aaaVerifier,
    refresh_token: localStorage.getItem(idConstants.tokens.aaaRefreshToken),
    redirect_uri: Constants.CALLBACKS.AAA_AUTH_CALLBACK,
    scope: 'openid',
  };

  return async (dispatch) =>
    await fetch(Constants.LOGIN_ACTION.GET_AAA_ACCESS_TOKEN, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(requestObj),
    })
      .then((response) => response?.json())
      .then(async (data) => {
        const { access_token, refresh_token, id_token } = data;
        if (!access_token || !refresh_token || !id_token) {
          throw loginActionData.TOKEN_ERROR;
        }
        // store the token in the localStorage
        localStorage.setItem(idConstants.tokens.aaaJwtToken, access_token);
        localStorage.setItem(idConstants.tokens.aaaRefreshToken, refresh_token);
        localStorage.setItem(idConstants.tokens.aaaIdToken, id_token);

        await dispatch({
          type: 'REFRESH_TOKEN',
          payload: refresh_token,
        });

        // decode token on React
        await dispatch(getAccessTokenData(id_token));
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_LOGIN',
          error: error?.response?.status || error,
        });
        dispatch(logout());
      });
};

export const regenerateDCSTokens = (companyId) => (dispatch) => {
  const requestObj = {
    code: localStorage.getItem(idConstants.tokens.dcsCode),
    grant_type: 'refresh_token',
    client_id: Constants.DCS_CONFIG.CLIENT_ID,
    code_verfier: localStorage.aaaVerifier,
    refresh_token: localStorage.getItem(idConstants.tokens.dcsRefreshToken),
    redirect_uri: Constants.CALLBACKS.AAA_AUTH_CALLBACK,
  };

  if (
    (!fetchDCSTokensPromise &&
      isExpired(localStorage.getItem(idConstants.tokens.dcsJwtToken))) ||
    companyId
  ) {
    fetchDCSTokensPromise = fetch(Constants.LOGIN_ACTION.GET_DCS_ACCESS_TOKEN, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(requestObj),
    })
      .then((response) => response?.json())
      .then((data) => {
        const { access_token, refresh_token, id_token } = data;

        if (!access_token || !refresh_token || !id_token) {
          throw loginActionData.TOKEN_ERROR;
        }
        // store the token in the localStorage
        fetchDCSTokensPromise = null;
        localStorage.setItem(idConstants.tokens.dcsJwtToken, access_token);
        localStorage.setItem(idConstants.tokens.dcsRefreshToken, refresh_token);
        localStorage.setItem(idConstants.tokens.dcsIdToken, id_token);
      })
      .catch((error) => {
        fetchDCSTokensPromise = null;
        dispatch({
          type: 'ERROR_LOGIN',
          error: error?.response?.status || error,
        });
      });
    return fetchDCSTokensPromise;
  } else {
    return fetchDCSTokensPromise;
  }
};

export const federatedAuthCode = () => {
  const params = new URLSearchParams();
  const dcsParams = new URLSearchParams();
  params.append('client_id', Constants.AAA_CONFIG.CLIENT_ID);
  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
    dcsParams.append('client_id', Constants.DCS_CONFIG.CLIENT_ID);
    dcsParams.append('redirect_uri', Constants.CALLBACKS.FEDERATED_CALLBACK);
    const dcsLogoutURL = `${Constants.LOGIN_ACTION.DCS_LOGOUT_AUTH_CODE}?federated&${dcsParams}`;
    params.append('redirect_uri', dcsLogoutURL);
  } else {
    params.append('redirect_uri', Constants.CALLBACKS.FEDERATED_CALLBACK);
  }
  const logoutURL = `${Constants.LOGIN_ACTION.LOGOUT_AUTH_CODE}?federated&${params}`;
  window.location.href = logoutURL;
};

export const invalidateRefreshTokens = () => (dispatch) => {
  const toggledFetchArray = [
    fetch(Constants.LOGOUT_URL, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      body: qs.stringify({
        client_id: Constants.AAA_CONFIG.CLIENT_ID,
        refresh_token: localStorage.getItem(idConstants.tokens.aaaRefreshToken),
      }),
    }),
  ];

  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
    toggledFetchArray.push(
      fetch(`${Constants.DCS_CONSTANTS.KEYCLOAK_URI}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
        body: qs.stringify({
          client_id: Constants.DCS_CONFIG.CLIENT_ID,
          refresh_token: localStorage.getItem(
            idConstants.tokens.dcsRefreshToken
          ),
        }),
      })
    );
  }

  Promise.all(toggledFetchArray).finally(() => {
    if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false')
      localStorage.removeItem(idConstants.tokens.dcsRefreshToken);
    dispatch({
      type: 'TOKEN_INVALIDATED',
      payload: true,
    });
  });
};

const encodeFormBody = (requestObj) => {
  let formBody = [];
  for (const property in requestObj) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(requestObj[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');
  return formBody;
};

export const handleDCSLoginSignup = () => {
  const params = new URLSearchParams();
  const hash = crypto
    .createHash('sha256')
    .update(generateCodeVerifier(idConstants.tokens.dcsVerifier))
    .digest();
  const code_challenge = base64url.encode(hash);
  params.append('client_id', Constants.DCS_CONFIG.CLIENT_ID);
  params.append('redirect_uri', Constants.CALLBACKS.DCS_AUTH_CALLBACK);
  params.append('response_type', 'code');
  params.append('scope', 'openid profile email');
  params.append('kc_idp_hint', Constants.DCS_CONSTANTS.DCS_IDP);
  params.append('code_challenge', code_challenge);
  params.append('code_challenge_method', 'S256');
  const authCodeUrl = `${Constants.LOGIN_ACTION.GET_DCS_AUTH_CODE}?${params}`;

  window.location.href = authCodeUrl;
};

export const generateDCSAccessToken = (authCode) => {
  const requestObj = {
    code: authCode,
    grant_type: 'authorization_code',
    client_id: Constants.DCS_CONFIG.CLIENT_ID,
    redirect_uri: Constants.CALLBACKS.DCS_AUTH_CALLBACK,
    code_verifier: localStorage.getItem(idConstants.tokens.dcsVerifier),
  };

  const formBody = encodeFormBody(requestObj);
  return async (dispatch) =>
    await fetch(Constants.LOGIN_ACTION.GET_DCS_ACCESS_TOKEN, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    })
      .then((response) => response?.json())
      .then((data) => {
        const { access_token, refresh_token, id_token } = data;
        if (!access_token || !refresh_token || !id_token) {
          throw loginActionData.TOKEN_ERROR;
        }

        localStorage.setItem(idConstants.tokens.dcsJwtToken, access_token);
        localStorage.setItem(idConstants.tokens.dcsRefreshToken, refresh_token);
        localStorage.setItem(idConstants.tokens.dcsIdToken, id_token);
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_LOGIN',
          error: error?.response?.status || error,
        });
        dispatch(logout());
      });
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem('prevUserName');
  localStorage.removeItem('prevPathName');
  localStorage.removeItem('userSubscriptions');
  localStorage.removeItem('selectedApp');
  localStorage.removeItem('pathName');
  localStorage.removeItem('newLogin');
  await dispatch(clearLoginStorage());
  dispatch({
    type: 'UPDATE_COMPANY_ERROR',
    payload: {},
  });
  dispatch({
    type: 'UPDATE_COMPANY_DETAILS',
    payload: {},
  });
  federatedAuthCode();
};

export const verifyRole = () => (dispatch) => {
  const aaaToken = localStorage.aaaJwtToken;
  if (aaaToken && aaaToken.replace('bearer ', '')) {
    const token = aaaToken.replace('bearer ', '');
    const tokenInfo = jwt_decode(token);
    if (tokenInfo?.realm_access?.roles) {
      if (
        tokenInfo.realm_access?.roles.includes(Constants.AAA_CONFIG.SELLER_ROLE)
      ) {
        dispatch(clearLoginStorage());
        window.location.href =
          Constants.sellerAccountRedirect.sellerAccountLink +
          '?role=' +
          Constants.AAA_CONFIG.SELLER_ROLE;
        return false;
      }
    }
  }
  return true;
};

export const checkUserAgreementAcceptance = () => async (dispatch) => {
  await axios
    .get(Constants.REGISTER_ACTION.CHECK_USER_AGREEMENT)
    .then((response) => {
      dispatch({
        type: 'CHECK_USER_AGREEMENT',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        error: error,
      });
    });
};

export const acceptUserAgreementByMasterId =
  (agreementMasterId) => (dispatch) => {
    axios
      .post(
        Constants.REGISTER_ACTION.ACCEPT_USER_AGREEMENT_BY_MASTER_ID +
          agreementMasterId,
        {},
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      .then((response) => {
        dispatch({
          type: 'CHECK_USER_AGREEMENT',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
  };

export const deregisterUser = () => async (dispatch) => {
  localStorage.removeItem('prevUserName');
  localStorage.removeItem('prevPathName');
  localStorage.removeItem('userSubscriptions');
  localStorage.removeItem('selectedApp');
  localStorage.removeItem('pathName');

  await dispatch(clearLoginStorage());

  dispatch({
    type: 'UPDATE_COMPANY_ERROR',
    payload: {},
  });
  dispatch({
    type: 'UPDATE_COMPANY_DETAILS',
    payload: {},
  });
  const params = new URLSearchParams();
  const dcsParams = new URLSearchParams();
  params.append('client_id', Constants.AAA_CONFIG.CLIENT_ID);
  if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
    dcsParams.append('client_id', Constants.DCS_CONFIG.CLIENT_ID);
    dcsParams.append(
      'redirect_uri',
      Constants.CALLBACKS.DEREGISTRATION_CALLBACK
    );
    const dcsLogoutURL = `${Constants.LOGIN_ACTION.DCS_LOGOUT_AUTH_CODE}?federated&${dcsParams}`;
    params.append('redirect_uri', dcsLogoutURL);
  } else {
    params.append('redirect_uri', Constants.CALLBACKS.DEREGISTRATION_CALLBACK);
  }
  const logoutURL = `${Constants.LOGIN_ACTION.LOGOUT_AUTH_CODE}?federated&${params}`;
  window.location.href = logoutURL;
};

export const registerMarketplaceAccountPage = () => {
  const params = new URLSearchParams();
  const hash = crypto
    .createHash('sha256')
    .update(generateCodeVerifier(idConstants.tokens.aaaVerifier))
    .digest();
  const code_challenge = base64url.encode(hash);
  // reactGA.event({
  //   category: 'Register',
  //   action: 'User Clicked on Register',
  //   label: 'Register'
  // });
  params.append('client_id', Constants.AAA_CONFIG.CLIENT_ID);
  params.append('redirect_uri', Constants.CALLBACKS.AAA_AUTH_CALLBACK);
  params.append('response_type', 'code');
  params.append('scope', 'openid email profile offline_access');
  params.append('code_challenge', code_challenge);
  params.append('code_challenge_method', 'S256');

  const authCodeUrl = `${Constants.LOGIN_ACTION.GET_AAA_AUTH_CODE}?${params}`;
  const authbase64 = Buffer.from(authCodeUrl).toString('base64');
  const encodedKey = encodeURIComponent(authbase64);
  const linktoRegister =
    `${Constants.REGISTER_ACTION.DIRECT_REGISTER_LINK}?ssoLoginUrl=` +
    encodedKey +
    `&styleId=` +
    process.env.REACT_APP_CIAM_STYLE_ID +
    `&style_id=` +
    process.env.REACT_APP_SKID_STYLE_ID;

  window.location.href = linktoRegister;
};

export default handleAAASignupLogin;
