// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ie-alert_snackbar-container__1kZPm {
  top: 5.5px !important;
}
.ie-alert_snackbar-container__1kZPm .ie-alert_snackbar-content__jtH9p {
  min-width: 965px !important;
  border-radius: 0% !important;
  opacity: 0.8 !important;
  font-weight: 700 !important;
  padding: 0 10px;
  border: #ebc52d 2.3px solid;
}`, "",{"version":3,"sources":["webpack://./src/components/IEAlert/ie-alert.module.scss"],"names":[],"mappings":"AAEA;EACI,qBAAA;AAGJ;AAFI;EACI,2BAAA;EACA,4BAAA;EACA,uBAAA;EACA,2BAAA;EACA,eAAA;EACA,2BAAA;AAIR","sourcesContent":["@import '../../variables.module.scss' ;\n\n.snackbar-container {\n    top:5.5px !important;\n    .snackbar-content {\n        min-width: 965px !important;\n        border-radius: 0% !important;\n        opacity:0.8 !important;\n        font-weight: 700 !important;\n        padding: 0 10px;\n        border: #ebc52d 2.3px solid;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"snackbar-container": `ie-alert_snackbar-container__1kZPm`,
	"snackbar-content": `ie-alert_snackbar-content__jtH9p`
};
export default ___CSS_LOADER_EXPORT___;
