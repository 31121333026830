// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialogModal_div-style__Fo-zz {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35% !important;
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .DialogModal_div-style__Fo-zz {
    width: 60% !important;
  }
}
@media screen and (max-width: 600px) {
  .DialogModal_div-style__Fo-zz {
    width: 80% !important;
  }
}
.DialogModal_icon-style__tTB6k {
  margin-right: 1rem;
  font-size: 2rem;
  margin-bottom: -4px;
}

.DialogModal_dialog-box__SobcU {
  align-items: center;
  display: flex;
  justify-content: center;
}
.DialogModal_dialog-box__image-container__UVhIo {
  display: flex;
  width: 12.5rem;
  height: 9.3125rem;
  padding-top: 0.5rem;
}
.DialogModal_dialog-box__alert-icon__tDHVg {
  width: 2rem;
  position: absolute;
  top: 1rem;
  left: 9.9rem;
}
.DialogModal_dialog-box__description__-oGQe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.DialogModal_description-container__Q-na- {
  padding: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Utils/Modal/DialogModal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,qBAAA;AACF;;AAEA;EACE;IACE,qBAAA;EACF;AACF;AAEA;EACE;IACE,qBAAA;EAAF;AACF;AAGA;EACE,kBAAA;EACA,eAAA;EACA,mBAAA;AADF;;AAIA;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;AADF;AAGE;EACE,aAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAIE;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAHJ;;AAOA;EACE,eAAA;AAJF","sourcesContent":[".div-style {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 35% !important;\n}\n\n@media screen and (min-width: 601px) and (max-width: 900px) {\n  .div-style {\n    width: 60% !important;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .div-style {\n    width: 80% !important;\n  }\n}\n\n.icon-style {\n  margin-right: 1rem;\n  font-size: 2rem;\n  margin-bottom: -4px;\n}\n\n.dialog-box {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n\n  &__image-container {\n    display: flex;\n    width: 12.5rem;\n    height: 9.3125rem;\n    padding-top: 0.5rem;\n  }\n\n  &__alert-icon {\n    width: 2rem;\n    position: absolute;\n    top: 1rem;\n    left: 9.9rem;\n  }\n\n  &__description {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n  }\n}\n\n.description-container {\n  padding: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div-style": `DialogModal_div-style__Fo-zz`,
	"icon-style": `DialogModal_icon-style__tTB6k`,
	"dialog-box": `DialogModal_dialog-box__SobcU`,
	"dialog-box__image-container": `DialogModal_dialog-box__image-container__UVhIo`,
	"dialog-box__alert-icon": `DialogModal_dialog-box__alert-icon__tDHVg`,
	"dialog-box__description": `DialogModal_dialog-box__description__-oGQe`,
	"description-container": `DialogModal_description-container__Q-na-`
};
export default ___CSS_LOADER_EXPORT___;
