const initialState = {
    countryList: []
};

const CountryListReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'COUNTRY_LIST':
            return Object.assign({}, state, { countryList: action.payload });
        default:
            return state;
    }
};

export default CountryListReducer;