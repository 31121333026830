// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes animated-sprite-image_animateBannerImage__kBrEQ {
  100% {
    background-position: -700% top;
  }
}
.animated-sprite-image_banner-sprite-image-aboutus__0DDEK {
  background-position-y: top;
  background-size: 700%;
  background-repeat: repeat-x;
  animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  -webkit-animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  -moz-animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  height: 0;
  display: block !important;
  padding-bottom: 70%;
}

.animated-sprite-image_banner-sprite-image__MNuNl {
  background-position-y: top;
  background-size: 700%;
  background-repeat: repeat-x;
  animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  -webkit-animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  -moz-animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  height: 0;
  display: block !important;
  width: 31.5%;
  padding-bottom: 21.5%;
}

.animated-sprite-image_banner-sprite-image-small__p3rfa {
  background-position-y: top;
  background-size: 700%;
  background-repeat: repeat-x;
  animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  -webkit-animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  -moz-animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  height: 0;
  display: block !important;
  width: 100% !important;
  padding-bottom: 80% !important;
}

.animated-sprite-image_banner-sprite-image-small-aboutus__UsDdx {
  background-position-y: top;
  background-size: 700%;
  background-repeat: repeat-x;
  animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  -webkit-animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  -moz-animation: animated-sprite-image_animateBannerImage__kBrEQ 15s steps(7) infinite;
  height: 0;
  display: block !important;
  width: 100% !important;
  padding-bottom: 80% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Landing/HomepageBanner/AnimatedSpriteImage/animated-sprite-image.module.scss"],"names":[],"mappings":"AAEA;EACE;IACE,8BAAA;EAGF;AACF;AAWA;EAVE,0BAAA;EACA,qBAAA;EACA,2BAAA;EACA,gFAAA;EACA,wFAAA;EACA,qFAAA;EACA,SAAA;EACA,yBAAA;EAKA,mBAAA;AAFF;;AAKA;EAfE,0BAAA;EACA,qBAAA;EACA,2BAAA;EACA,gFAAA;EACA,wFAAA;EACA,qFAAA;EACA,SAAA;EACA,yBAAA;EAUA,YAAA;EACA,qBAAA;AAKF;;AAFA;EArBE,0BAAA;EACA,qBAAA;EACA,2BAAA;EACA,gFAAA;EACA,wFAAA;EACA,qFAAA;EACA,SAAA;EACA,yBAAA;EAgBA,sBAAA;EACA,8BAAA;AAYF;;AATA;EA3BE,0BAAA;EACA,qBAAA;EACA,2BAAA;EACA,gFAAA;EACA,wFAAA;EACA,qFAAA;EACA,SAAA;EACA,yBAAA;EAsBA,sBAAA;EACA,8BAAA;AAmBF","sourcesContent":["@import '../../../../variables.module.scss';\n\n@keyframes animateBannerImage {\n  100% {\n    background-position: -700% top;\n  }\n}\n\n@mixin sprite-image {\n  background-position-y: top;\n  background-size: 700%;\n  background-repeat: repeat-x;\n  animation: animateBannerImage 15s steps(7) infinite;\n  -webkit-animation: animateBannerImage 15s steps(7) infinite;\n  -moz-animation: animateBannerImage 15s steps(7) infinite;\n  height: 0;\n  display: block !important;\n}\n\n.banner-sprite-image-aboutus {\n  @include sprite-image;\n  padding-bottom: 70%;\n}\n\n.banner-sprite-image {\n  @include sprite-image;\n  width: 31.5%;\n  padding-bottom: 21.5%;\n}\n\n.banner-sprite-image-small {\n  @include sprite-image;\n  width: 100% !important;\n  padding-bottom: 80% !important;\n}\n\n.banner-sprite-image-small-aboutus {\n  @include sprite-image;\n  width: 100% !important;\n  padding-bottom: 80% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"banner-sprite-image-aboutus": `animated-sprite-image_banner-sprite-image-aboutus__0DDEK`,
	"animateBannerImage": `animated-sprite-image_animateBannerImage__kBrEQ`,
	"banner-sprite-image": `animated-sprite-image_banner-sprite-image__MNuNl`,
	"banner-sprite-image-small": `animated-sprite-image_banner-sprite-image-small__p3rfa`,
	"banner-sprite-image-small-aboutus": `animated-sprite-image_banner-sprite-image-small-aboutus__UsDdx`
};
export default ___CSS_LOADER_EXPORT___;
