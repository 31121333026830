// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sprite-image-animated-text-slide_banner-container__v47OU {
  background-repeat: no-repeat !important;
  width: 100% !important;
  max-width: 100% !important;
}

.sprite-image-animated-text-slide_padding-to-items__FnkZ3 {
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.sprite-image-animated-text-slide_padding-to-items-xl__N2\\+-Y {
  padding-left: 3.5em !important;
  padding-right: 3.5em !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Landing/HomepageBanner/SpriteImageAnimatedTextSlide/sprite-image-animated-text-slide.module.scss"],"names":[],"mappings":"AAEA;EACE,uCAAA;EACA,sBAAA;EACA,0BAAA;AAGF;;AAAA;EACE,4BAAA;EACA,6BAAA;AAGF;;AAAA;EACE,8BAAA;EACA,+BAAA;AAGF","sourcesContent":["@import '../../../../variables.module.scss';\n\n.banner-container {\n  background-repeat: no-repeat !important;\n  width: 100% !important;\n  max-width: 100% !important;\n}\n\n.padding-to-items {\n  padding-left: 2em !important;\n  padding-right: 2em !important;\n}\n\n.padding-to-items-xl {\n  padding-left: 3.5em !important;\n  padding-right: 3.5em !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"banner-container": `sprite-image-animated-text-slide_banner-container__v47OU`,
	"padding-to-items": `sprite-image-animated-text-slide_padding-to-items__FnkZ3`,
	"padding-to-items-xl": `sprite-image-animated-text-slide_padding-to-items-xl__N2+-Y`
};
export default ___CSS_LOADER_EXPORT___;
