import React from 'react';
import { Box, Typography } from '@mui/material';
import i18next from 'i18next';
import styles from './workflow-details-card.module.scss';
const WorkflowDetailsCard = ({icon, title, description}) => {
    return (
    <Box>
        <Box className={styles['workflow-details-card']}>
            <Box className={styles['workflow-details-card__icon']} sx={{backgroundColor:'primary.main'}}>
            <Box component={'img'} src={icon} alt={i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.CARD_ICON_ALT_TEXT')} /></Box>
            <Typography sx={{display:'flex', justifyContent:'center', fontSize:'1.2rem'}}>{title}</Typography>
            <Typography sx={{display:'contents'}}>{description}</Typography>
        </Box>
    </Box>
    );
};
export default WorkflowDetailsCard;