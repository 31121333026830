// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-left-panel_panel__\\+TvZf {
  width: 220px !important;
  border: 1px solid lightgray;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 1rem 0.5rem 1rem 0.8rem;
}

.filter-left-panel_filter-item-grid__aKowa {
  display: block !important;
}

.filter-left-panel_filtered-text__ZdQhc {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.filter-left-panel_view-more-grid__F6sMK {
  display: flex;
  justify-content: flex-end;
}

.filter-left-panel_apply-filter-grid__HfSxM {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 !important;
}

.filter-left-panel_reset-button__m1f7n {
  font-size: 18px !important;
  text-transform: none !important;
}

.filter-left-panel_reset-icon__qCbyd {
  margin-left: 5px !important;
  width: 18px !important;
}

@media only screen and (max-width: 900px) {
  .filter-left-panel_panel__\\+TvZf {
    width: 300px !important;
  }
  .filter-left-panel_apply-filter-button__E\\+1Ha {
    min-width: 90px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductGuideVideoPage/FilterLeftPanel/filter-left-panel.module.scss"],"names":[],"mappings":"AAEA;EACI,uBAAA;EACA,2BAAA;EACA,2BAAA;EACA,2BAAA;EACA,gCAAA;AAGJ;;AAAA;EACI,yBAAA;AAGJ;;AAAA;EACI,0BAAA;EACA,2BAAA;AAGJ;;AAAA;EACI,aAAA;EACA,yBAAA;AAGJ;;AAAA;EACI,aAAA;EACA,8BAAA;EACA,yBAAA;AAGJ;;AAAA;EACI,0BAAA;EACA,+BAAA;AAGJ;;AAAA;EACI,2BAAA;EACA,sBAAA;AAGJ;;AAAA;EAEI;IACI,uBAAA;EAEN;EACE;IACI,0BAAA;EACN;AACF","sourcesContent":["@import '../../../variables.module.scss';\n\n.panel {\n    width: 220px !important;\n    border: 1px solid lightgray;\n    border-radius: 0 !important;\n    box-shadow: none !important;\n    padding: 1rem 0.5rem 1rem 0.8rem;\n}\n\n.filter-item-grid{\n    display: block !important;\n}\n\n.filtered-text {\n    font-size: 13px !important;\n    font-weight: 500 !important;\n}\n\n.view-more-grid{\n    display: flex;\n    justify-content: flex-end;\n}\n\n.apply-filter-grid{\n    display: flex;\n    justify-content: space-between;\n    margin: 10px 0 !important;\n}\n\n.reset-button{\n    font-size: 18px !important;\n    text-transform: none !important;\n}\n\n.reset-icon{\n    margin-left: 5px !important;\n    width: 18px !important;\n}\n\n@media only screen and (max-width: 900px) {\n\n    .panel {\n        width: 300px !important;\n    }\n\n    .apply-filter-button{\n        min-width: 90px !important;\n    }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"panel": `filter-left-panel_panel__+TvZf`,
	"filter-item-grid": `filter-left-panel_filter-item-grid__aKowa`,
	"filtered-text": `filter-left-panel_filtered-text__ZdQhc`,
	"view-more-grid": `filter-left-panel_view-more-grid__F6sMK`,
	"apply-filter-grid": `filter-left-panel_apply-filter-grid__HfSxM`,
	"reset-button": `filter-left-panel_reset-button__m1f7n`,
	"reset-icon": `filter-left-panel_reset-icon__qCbyd`,
	"apply-filter-button": `filter-left-panel_apply-filter-button__E+1Ha`
};
export default ___CSS_LOADER_EXPORT___;
