import React from 'react';
import CustomAccordion from '../../Shared/CustomAccordion';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Container, IconButton, Tooltip, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import styles from './what-we-offer.module.scss';
import { categoryList } from '../../../data/HomePage/categoriesList';
import i18next from 'i18next';
import DeviceBreakPoints from '../../../deviceBreakPoints';

function WhatWeOffer() {
    const { isMediumDevice, theme } = DeviceBreakPoints();
    const history = useHistory();
    const [openAccordion, setOpenAccordion] = React.useState(false);
    const [activeCategory, setActiveCategory] = React.useState(null);
    const [subCategories, setSubCategories] = React.useState([]);
    const rowCount = isMediumDevice ? 4 : 8; //This count refers the maximum number to categories to be displayed in mobile view;
    // This is handled for mobile responsiveness to divide the category into 4 in each row dynamically as and when the categories are modified.
    function spliceCategory(arr, chunkSize) {
        const splicedItems = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            splicedItems.push(chunk);
        }
        return splicedItems;
    }
    const splicedCategories = spliceCategory([...categoryList], rowCount);

    const displayAccordion = (category) => {
        if (category.hasSubcategories) {
            if (category.id === activeCategory) {
                setOpenAccordion(false);
                setActiveCategory(null);
            }
            else if (openAccordion && category.id !== activeCategory) {
                setOpenAccordion(false);
                const timer = setTimeout(() => {
                    setOpenAccordion(true);
                    setActiveCategory(category.id);
                    setSubCategories([...category.subCategoriesList]);
                }, 800);
                return () => clearTimeout(timer);
            }
            else if (!openAccordion) {
                setSubCategories([...category.subCategoriesList]);
                setOpenAccordion(true);
                setActiveCategory(category.id);
            }
        }
        else {
            setOpenAccordion(false);
            if (!category?.link) return;
            history.push(category.link);
        }
    };

    const handleRoute = (category) => {
        if (!category?.link) return;
        history.push(category.link);
        localStorage.removeItem('queryParams');
    };

    const closeAccordion = () => {
        setOpenAccordion(false);
    };

    const getAccordionContent = (categories) => {
        return (
            <CustomAccordion type='accordion' isOpen={openAccordion && categories.some(category => category.id === activeCategory)}>
                <Grid container>
                    {subCategories.map(subCategory => {
                        return (
                            <Grid key={subCategory?.id} item xs sm md lg xl>
                                <Box className={styles['sub-row-content']}>
                                    <Box className={styles['sub-img-container']}>
                                        <Box component='img' className={styles['sub-img-icon']} src={subCategory.img} alt={i18next.t(subCategory.name)} onClick={() => handleRoute(subCategory)} />
                                    </Box>
                                    <Box className={styles['sub-category-text']}><Typography variant='p' component={'smallText'} color='primary'>{i18next.t(subCategory.name)}</Typography></Box>
                                </Box>
                            </Grid>
                        );
                    })}
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Box className={styles['close-icon']}><Tooltip title={i18next.t('FORM.CLOSE')} ><IconButton disableRipple onClick={closeAccordion} size='large'>
                        <CloseOutlinedIcon sx={{strokeWidth:1}}/>
                        </IconButton></Tooltip></Box>
                    </Grid>
                </Grid>
            </CustomAccordion>
        );
    };

    const getCategoryDisplayList = (categories) => {
        return (
            <Grid container>
                {categories.map((category) => {
                    return (
                        <>
                            <Grid item xs sm md lg xl>
                                <Box className={styles['row-content']}>
                                    <Box 
                                    className={styles['img-container']} 
                                    backgroundColor='common.light'  
                                    sx={{
                              '&:hover': {
                                backgroundColor: 'secondary.light',
                              }
                            }}>
                                        <Box component='img' className={styles['img-icon']} src={category.img} alt={i18next.t(category.name)} onClick={() => displayAccordion(category)} />
                                    </Box>
                                    <Box><Typography variant='body1' align='center' fontSize={isMediumDevice ? '0.75rem' : '1rem'}>{i18next.t(category.name)}</Typography></Box>
                                </Box>
                                {activeCategory === category.id && openAccordion ? <Box className={styles['triangle-arrow']}></Box> : <Box sx={{ borderBottom: `25px solid ${theme.palette.primaryBackground.main}` }}></Box>}
                            </Grid>
                        </>
                    );
                })}
            </Grid>
        );
    };

    return (
        <>
            <Box sx={{ backgroundColor: 'primaryBackground.main', color: 'common.light' }}>
                {!isMediumDevice &&
                    <>
                        <Container>
                            <Box className={styles['header']}><Typography variant='p' component='smallText'>{i18next.t('HOME_PAGE.WHAT_WE_OFFER')}</Typography></Box>
                        </Container>
                        {splicedCategories.map(largeCategoryList => {
                            return (
                                <>
                                    <Container className={styles['what-we-offer-wrapper']}>
                                        {getCategoryDisplayList(largeCategoryList)}
                                    </Container>
                                    {getAccordionContent(largeCategoryList)}
                                </>
                            );
                        })}
                    </>
                }
                {isMediumDevice &&
                    <>
                        <Box className={styles['header']}><Typography variant='h5'>{i18next.t('HOME_PAGE.WHAT_WE_OFFER')}</Typography></Box>
                        {splicedCategories.map(smallCategoryList => {
                            return (
                                <Box key={smallCategoryList?.id} mt={1}>
                                    {getCategoryDisplayList(smallCategoryList)}
                                    {getAccordionContent(smallCategoryList)}
                                </Box>
                            );
                        })}
                    </>
                }
            </Box>
        </>
    );
}

export default WhatWeOffer;