import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// import SellerSection from './SellerSection';
import { Snackbar, Alert, Box } from '@mui/material';
import BreadCrumb from '../BaseLayout/BreadCrumb';
import { RegisterCompany } from '@mobilitystore/user-onboarding-interface';
import { getEnvironment } from '../../utils';
import * as Constants from '../../actions/configuration';
import idConstants from '../../data/idConstants';
import { internalBuyerUserOnboardingForm } from '../../data/internalBuyerUserOnboardingForm';
import isEmpty from '../../validator/isEmpty';
import { setUserDetails, saveUserDetails, submitTermsConsent } from '../../actions/UserRegisterAction';
import { getUserDetails } from '../../actions/api/ApiStatisticsAction';
import ConfirmModal from '../Shared/Utils/Modal/ConfirmModal';
import { logout } from '../../actions/LoginAction';
import Loader from '../Shared/Utils/Loader';
import AllYouNeed from './AllYouNeed';
// import CustomerTestimonials from './CustomerTestimonials';
// import Testimonials from './Testimonials';
// import CaseStudies from './CaseStudies';
import CustomerJourney from './CustomerJourney';
import HomepageBanner from './HomepageBanner/homepageBanner';
// import UseCases from './UseCases';
import UniqueSection from './UniqueSection';
import WhatWeOffer from './WhatWeOffer';

const Landing = (props) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmationModalLoader, setConfirmationModalLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const reduxStore = useSelector((state) => state);
  const mismatchInDcsAndMsData =
    reduxStore?.CompanyDetailsReducer?.mismatchInDcsAndMsData;

  const dispatchRedux = useDispatch();

  const handleRegisterModalClose = () => {
    localStorage.removeItem('popUpOpen');
    localStorage.removeItem('newLogin');
  };

  const handleLogOutUser = () => {
    setOpenConfirmModal(false);
    setConfirmationModalLoader(true);
    dispatchRedux(logout());
  };

  useEffect(() => {
    if (mismatchInDcsAndMsData) {
      setSnackAlertFlag(true);
      setSnackAlertMsg(
        'Mismatch occurred in Company details between DCS and MS'
      );
      setSnackAlertSeverity('error');
    }
  }, [mismatchInDcsAndMsData]);

  const [snackAlertFlag, setSnackAlertFlag] = useState(false);
  const [snackAlertMsg, setSnackAlertMsg] = useState('');
  const [snackAlertSeverity, setSnackAlertSeverity] = useState('');
  const [dynamicForm, setDynamicForm] = useState('');
  const isInternalBuyerTenant = process.env.REACT_APP_AAA_CLIENT_ID === Constants.TENANT.TOS;

  useEffect(() => {
    if (props.userCompanyStatus && isInternalBuyerTenant) {
      setDynamicForm(internalBuyerUserOnboardingForm(props.userCompanyStatus?.user));
    };
  }, [props.userCompanyStatus]);

  useEffect(() => {
    if (isEmpty(props.userRegistrationSuccessMsg))
      return;
    setSnackAlertFlag(true);
    setSnackAlertMsg(props.userRegistrationSuccessMsg);
    setSnackAlertSeverity('success');
    setLoader(false);
    dispatchRedux({
      type: 'USER_REGISTRATION_SUCCESS_ALERT',
      payload: ''
    });
  }, [props.userRegistrationSuccessMsg]);

  useEffect(() => {
    if (isEmpty(props.userRegistrationErrorMsg))
      return;
    setSnackAlertFlag(true);
    setSnackAlertMsg(props.userRegistrationErrorMsg);
    setSnackAlertSeverity('error');
    setLoader(false);
    dispatchRedux({
      type: 'USER_REGISTRATION_ERROR_ALERT',
      error: ''
    });
  }, [props.userRegistrationErrorMsg]);

  const handleSuccessResponse = (response) => {
    if (isInternalBuyerTenant && response) {
      const userDetails= {};
      userDetails.firstName = response.companyData.userData.firstName;
      userDetails.lastName = response.companyData.userData.lastName;
      userDetails.contactNo = response.companyData.userData.isdCode + ' ' + response.companyData.userData.phoneNo;
      userDetails.countryCode = 'IN';
      setLoader(true);
      dispatchRedux(setUserDetails(userDetails));
      saveUserDetails(userDetails, dispatchRedux).then((res) => {
        if (res?.status === 400 || res?.status === 500) {
          setSnackAlertFlag(true);
          setSnackAlertMsg(i18next.t('TOAST_MESSAGES.SAVE_USER_DETAILS_ERR.MSG'));
          setSnackAlertSeverity('error');
        } else {
          if (res.userId) {
            dispatchRedux(getUserDetails(res.userId));
            dispatchRedux(submitTermsConsent());
          }
        }
      });
    }
    dispatchRedux({
      type: 'UPDATE_PROFILE_STATUS',
      payload: response
    });
    localStorage.removeItem('newLogin');
  };
  return (
    <React.Fragment>
      <BreadCrumb landing={true} />
      <Box mt={-2}>
        <HomepageBanner productCategories={props.productCategories} />
      </Box>
      <AllYouNeed />
      <WhatWeOffer />
      <UniqueSection />
      <CustomerJourney />
      {/* <UseCases /> */}
      {/* MS-15456 : Hiding 'Testimonials' and 'CaseStudies' block as part of BGSW tools POC */}
      {/* <Testimonials /> */}
      {/* <CaseStudies /> */}
      {/* <SellerSection accessToken={props?.accessToken} /> */}
      {/* MS-15456 : Hiding 'Register' block as part of BGSW tools POC */}
      {/* <SupportSection /> */}
      {localStorage.getItem(idConstants.tokens.aaaIdToken) && localStorage.getItem('newLogin') && (isInternalBuyerTenant ? props.userCompanyStatus?.companyRegisteredStatus !== idConstants.status.approved && dynamicForm : true) &&
        <RegisterCompany
          language='en'
          clientToken={localStorage.getItem(idConstants.tokens.aaaIdToken)}
          environment={getEnvironment(process.env.REACT_APP_ENVIRONMENT)}
          successHandler={(response) => handleSuccessResponse(response)}
          cancelHandler={() => { handleRegisterModalClose(); }}
          errorHandler={(error) => {
            if (error.message) {
              setSnackAlertFlag(true);
              setSnackAlertMsg(error.message);
              setSnackAlertSeverity('error');
            }
          }}
          role={Constants.AAA_CONFIG.BUYER_ROLE}
          dynamicForm={dynamicForm}
        />
      }
      <Snackbar
        open={snackAlertFlag}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => {
          setSnackAlertFlag(false);
        }}
      >
        <Alert severity={snackAlertSeverity}>{snackAlertMsg}</Alert>
      </Snackbar>
      {(confirmationModalLoader || loader) && <Loader />}
      {openConfirmModal && (
        <ConfirmModal
          modalContent=''
          heading={userRoleUpdatedToSeller.message}
          category={'approve'}
          callback={handleLogOutUser}
          okOperation={handleLogOutUser}
          cancelBtn='disable'
          noCloseIcon
          {...props}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (redux_state) => ({
  accessToken: redux_state.loginReducer.accessToken,
  userCompanyStatus: redux_state.CompanyDetailsReducer.userCompanyStatus,
  userRegistrationSuccessMsg: redux_state.UserReducer.userRegistrationSuccessMsg,
  userRegistrationErrorMsg: redux_state.UserReducer.userRegistrationErrorMsg,
});

export default connect(mapStateToProps, null)(Landing);
