import React, { useEffect } from 'react';
import styles from './animated-sprite-image.module.scss';
import { Box } from '@mui/material';
import i18next from 'i18next';
import DeviceBreakPoints from '../../../../deviceBreakPoints';
import { IMAGE_CDN } from '../../../../actions/configuration';

const AnimatedSpriteImage = ({ path }) => {
  const { isPhone, isMediumDeviceOnly, isTablet, isSmallDevice } = DeviceBreakPoints();

  useEffect(() => {
    if (isPhone || isMediumDeviceOnly || isTablet) {
      nullAnimation();
    }
  }, [isPhone, isMediumDeviceOnly, isTablet]);

  useEffect(() => {
    if (!isPhone && !isMediumDeviceOnly && !isTablet) {
      nullAnimation();
    }
  }, [isPhone, isMediumDeviceOnly, isTablet]);

  const nullAnimation = () => {
    const spriteImage = document.querySelectorAll('.sprite-image-container')[0];
    spriteImage.style.animation = 'none';
    spriteImage.style.animation = null;
  };


  return (
    <Box
      sx={{
        backgroundImage:
          path === i18next.t('SHARED.ABOUT_US')
            ? `url(${IMAGE_CDN}best-tools-sprite-banner-homepage-black.png)`
            : `url(${IMAGE_CDN}best-tools-sprite-banner-homepage-white.png)`
      }}
      className={
        !isSmallDevice
          ? (path === i18next.t('SHARED.ABOUT_US') ? `sprite-image-container ${styles['banner-sprite-image-aboutus']}` : `sprite-image-container ${styles['banner-sprite-image']}`)
          : (path === i18next.t('SHARED.ABOUT_US') ? `sprite-image-container ${styles['banner-sprite-image-small-aboutus']}` : `sprite-image-container ${styles['banner-sprite-image-small']}`)
      }
    ></Box>
  );
};

export default AnimatedSpriteImage;
