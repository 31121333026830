// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workflow-details-card_workflow-details-card__PyLBz {
  padding: 16px;
  text-align: center;
  margin: 8px;
  display: table-cell;
}
.workflow-details-card_workflow-details-card__icon__1hegU {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomWorkflow/WorkflowDetailsCard/workflow-details-card.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,WAAA;EACA,mBAAA;AACJ;AACI;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AACN","sourcesContent":[".workflow-details-card {\n    padding: 16px;\n    text-align: center;\n    margin: 8px;\n    display: table-cell;\n\n    &__icon {\n      border-radius: 50%;\n      width: 5rem; \n      height: 5rem;\n      display: inline-flex;\n      justify-content: center;\n      align-items: center;\n      margin-bottom: 16px; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-details-card": `workflow-details-card_workflow-details-card__PyLBz`,
	"workflow-details-card__icon": `workflow-details-card_workflow-details-card__icon__1hegU`
};
export default ___CSS_LOADER_EXPORT___;
