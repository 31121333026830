import { Container, Grid, Typography, Stack, Box } from '@mui/material';
import React, { useEffect } from 'react';
import styles from '../../SolutionSearch/ListingProducts/Banner/banner.module.scss';
import images from '../../../assets/img';
import i18next from 'i18next';
import DeviceBreakPoints from '../../../deviceBreakPoints';
import { IMAGE_CDN } from '../../../actions/configuration';

const BannerSection = (props) => {
    const { isPhone, isXlDevice } = DeviceBreakPoints();
    let heading, img, text, text2 = '';
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    switch (props?.redirected) {
        case 'casestudies': {
            heading = i18next.t('HOME_PAGE.CASE_STUDIES');
            img = images.caseStudyPage.caseStudyPageBannerImg;
            text = '';
        }
            break;
        case 'video': {
            heading = i18next.t('HOME_PAGE.HEADER_FOOTER.PRODUCT_VIDEOS');
            img = images.productVideo;
            text = i18next.t('SHARED.PRODUCT_VIDEOS_TEXT');
        }
            break;
        case 'getInTouch': {
            heading = i18next.t('HOME_PAGE.HEADER_FOOTER.GET_IN_TOUCH');
            img = images.getInTouchBannerImage;
            text = i18next.t('GET_IN_TOUCH_PAGE.GET_IN_TOUCH_DESC');
        }
            break;
        case 'support': {
            heading = i18next.t('SUPPORT_PAGE.WE_ARE_HERE');
            img = images.supportBannerImage;
            text = i18next.t('SUPPORT_PAGE.EXPLORE_MULTIPLE_OPTIONS') + '.';
            text2 = i18next.t('SUPPORT_PAGE.HELP_CATEGORIES') + '.';
        }
            break;
        case 'search': {
            img = images.searchBanner;
            heading = props?.htmlHeading;
        }
            break;
        case 'guide': {
            heading = i18next.t('HOME_PAGE.HEADER_FOOTER.PRODUCT_GUIDES');
            img = images.productGuide;
            text = i18next.t('SHARED.PRODUCT_GUIDE_TEXT');
        }
    }
    return (
        <Container className={styles['banner-container']} sx={{ backgroundImage: `url(${IMAGE_CDN}revamped-homepage-banner-background.png)`, backgroundColor: 'primaryBackground.banner' }}>
            <Stack
                alignItems={'center'}
                justifyContent='space-between'
                direction={'row'}
                className={
                    isXlDevice ? styles['padding-stack-xl'] : styles['padding-stack-not-xl']
                }
            >
                <Grid item xs={6} sm={6} md={6} lg={6} >
                    <Stack
                        justifyContent='center'
                        direction={'column'}
                        className={!isPhone && styles['padding-right-text']}
                    >
                        <Typography
                            display={'flex'}
                            component='h1'  
                            variant='bannerTitle'>
                            {heading}
                        </Typography>
                        <Typography
                            variant='bannerSubTitle'
                        >
                            {text}
                        </Typography>
                        {text2 !== '' && (
                            <Typography
                                variant='bannerSubTitle'
                            >
                                {text2}
                            </Typography>
                        )}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    className={styles['align-image-right']}
                >
                    <Box component='img' src={img}  sx={{maxWidth: !isPhone? '300px': '150px', height: '200px', padding: '21px 0', float: 'right'}} />
                </Grid>
            </Stack>
        </Container>
    );
};

export default BannerSection;
