const initialState = {   
  userDeregistered: '',
  userRegistrationSuccessMsg: '',
  userRegistrationErrorMsg: '',
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {       
    case 'USER_DEREGISTERED':
      return { ...state, userDeregistered: action.payload };
    case 'USER_REGISTRATION_SUCCESS_ALERT':
      return Object.assign({}, state, { userRegistrationSuccessMsg: action.payload });
    case 'USER_REGISTRATION_ERROR_ALERT':
      return { ...state, userRegistrationErrorMsg: action.error};
    default:
      return state;
  }
};

export default UserReducer;