import React, { useState } from 'react';
import { Container, CssBaseline, Typography, Box, Button, Grid } from '@mui/material';
import styles from './unique-section.module.scss';
import Images from '../../../assets/img';
import i18next from 'i18next';

function UniqueSection() {
    const [buttonClicked, setButtonClicked] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const customerBenefit = [
        { 'id': 1, 'name': i18next.t('HOME_PAGE.UNIQUE_SECTION.FASTER_GO_TO_MARKET'), 'icon': Images.storeImage, 'description': i18next.t('HOME_PAGE.UNIQUE_SECTION.Faster_GO_TO_MARKET_DESCRIPTION') },
        { 'id': 2, 'name': i18next.t('HOME_PAGE.UNIQUE_SECTION.ACCESS_VERIFIED_SELLERS'), 'icon': Images.windowSearch, 'description': i18next.t('HOME_PAGE.UNIQUE_SECTION.ACCESS_VERIFIED_SELLERS_DESCRIPTION') },
        { 'id': 3, 'name': i18next.t('HOME_PAGE.UNIQUE_SECTION.PAY_AS_YOU_GO'), 'icon': Images.userThumbsUp, 'description': i18next.t('HOME_PAGE.UNIQUE_SECTION.PAY_AS_YOU_GO_DESCRIPTION') }
    ];
    const sellerBenefit = [
        { 'id': 1, 'name': i18next.t('HOME_PAGE.UNIQUE_SECTION.BUILD_TO_SCALE'), 'icon': Images.buildToScale, 'description': i18next.t('HOME_PAGE.UNIQUE_SECTION.BUILD_TO_SCALE_DESCRIPTION') },
        { 'id': 2, 'name': i18next.t('HOME_PAGE.UNIQUE_SECTION.MAGNIFY_BRAND'), 'icon': Images.magnifyBrand, 'description': i18next.t('HOME_PAGE.UNIQUE_SECTION.MAGNIFY_BRAND_DESCRIPTION') },
        { 'id': 3, 'name': i18next.t('HOME_PAGE.UNIQUE_SECTION.UNLOCK_BUSINESS'), 'icon': Images.unlockBusiness, 'description': i18next.t('HOME_PAGE.UNIQUE_SECTION.UNLOCK_BUSINESS_DESCRIPTION') }
    ];

    function customerButtonClicked() {
        setButtonClicked(true);
        setIsActive(true);
    }
    function sellerButtonClicked() {
        setButtonClicked(false);
        setIsActive(false);
    }

    const renderSection = (itemToBeRendered, type) => {
        return (
            <Grid container className={`${styles['unique-section-features-wrapper']}
            ${type === 'buyer' ? buttonClicked ? styles['buyer-visible'] : styles['buyer-hidden'] :
                    buttonClicked ? styles['seller-hidden'] : styles['seller-visible']}`}>
                {
                    itemToBeRendered.map((item) => (
                        <Grid key={item.id} item md={4} sm={6} xs={6} className={styles['unique-section-grid-wrapper']}>
                            <Box className={styles['unique-section-features']}>
                                <Box component='img' className={styles['icon-cursor-styling']} src={item.icon} alt={item.name} />
                                <Box className={styles['icon-cursor-label']} color='primary'>{item.name}</Box>
                                <Box className={styles['icon-cursor-description']}>{item.description}.</Box>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid >
        );
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Box sx={{ backgroundColor: 'primaryBackground.bgBody' }}>
                <Container>
                    <Box pt={2}>
                        <Typography variant='h5' align='center'>{i18next.t('HOME_PAGE.UNIQUE_SECTION.WHAT_MAKES_US')} <Box component='span' color='primary'>{i18next.t('HOME_PAGE.UNIQUE_SECTION.UNIQUE')}</Box></Typography>
                    </Box>
                    <Box>
                        <Typography variant='body1' align='center'>{i18next.t('HOME_PAGE.UNIQUE_SECTION.UNIQUE_SUBHEADING')}!</Typography>
                    </Box>
                    <Box className={styles['button-container']}>
                        <Button variant='borderedBtn' disableRipple onClick={customerButtonClicked} className={styles['button-unique-section']}
                            sx={isActive ? { backgroundColor: 'primaryBackground.main', color: 'common.light', '&:hover': { backgroundColor: 'primaryBackground.main' } } : { color: 'common.dark' }}
                        >{i18next.t('HOME_PAGE.UNIQUE_SECTION.BUYER_BENEFITS')}</Button>
                        <Button variant='borderedBtn' disableRipple onClick={sellerButtonClicked} className={styles['button-unique-section']} sx={!isActive ? { backgroundColor: 'primaryBackground.main', color: 'common.light', '&:hover': { backgroundColor: 'primaryBackground.main' } } : { color: 'common.dark' }}
                        >{i18next.t('HOME_PAGE.UNIQUE_SECTION.SELLER_BENEFITS')}</Button>
                    </Box>
                    <Typography variant='body1'>{renderSection(customerBenefit, 'buyer')}</Typography>
                    <Typography>{renderSection(sellerBenefit, 'seller')}</Typography>
                </Container>
            </Box>
        </React.Fragment >
    );
}

export default UniqueSection;