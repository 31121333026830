// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-guide-card_product-guide-card__9\\+EtI {
  width: 275px;
  margin: 0 10px 10px 0;
  padding: 1rem;
  z-index: 1;
  border: 1px solid lightgray;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.product-guide-card_title__ooCqG {
  font-size: 18px !important;
  font-weight: 900 !important;
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  height: 50px;
  line-height: 1.2 !important;
}

.product-guide-card_description__0eujh {
  font-size: 12px !important;
  word-break: break-word !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.product-guide-card_download-icon__OkcHE {
  font-size: 32px;
}

@media only screen and (max-width: 900px) {
  .product-guide-card_product-guide-card__9\\+EtI {
    width: 250px;
  }
}
@media only screen and (max-width: 600px) {
  .product-guide-card_product-guide-card__9\\+EtI {
    width: 160px !important;
    padding: 0.3rem;
  }
  .product-guide-card_title__ooCqG {
    font-size: 10px !important;
  }
  .product-guide-card_description__0eujh {
    font-size: 8px !important;
  }
  .product-guide-card_download-icon__OkcHE {
    font-size: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductGuideVideoPage/ProductCardGrid/ProductGuideCard/product-guide-card.module.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,qBAAA;EACA,aAAA;EACA,UAAA;EACA,2BAAA;EACA,2BAAA;EACA,2BAAA;AAGJ;;AAAA;EACI,0BAAA;EACA,2BAAA;EACA,sBAAA;EACA,2BAAA;EACA,kCAAA;EACA,YAAA;EACA,2BAAA;AAGJ;;AAAA;EACI,0BAAA;EACA,iCAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,kCAAA;AAGJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI;IACI,YAAA;EAGN;AACF;AAEA;EAEI;IACI,uBAAA;IACA,eAAA;EADN;EAIE;IACI,0BAAA;EAFN;EAKE;IACI,yBAAA;EAHN;EAME;IACI,eAAA;EAJN;AACF","sourcesContent":["@import '../../../../variables.module.scss';\n\n.product-guide-card {\n    width: 275px;\n    margin: 0 10px 10px 0;\n    padding: 1rem;\n    z-index: 1;\n    border: 1px solid lightgray;\n    border-radius: 0 !important;\n    box-shadow: none !important;\n}\n\n.title {\n    font-size: 18px !important;\n    font-weight: 900 !important;\n    width: 100% !important;\n    overflow: hidden !important;\n    text-overflow: ellipsis !important;\n    height: 50px;\n    line-height: 1.2 !important;\n}\n\n.description {\n    font-size: 12px !important;\n    word-break: break-word !important;\n    display: -webkit-box;\n    -webkit-line-clamp: 3;\n    -webkit-box-orient: vertical;\n    width: 100% !important;\n    overflow: hidden !important;\n    text-overflow: ellipsis !important;\n}\n\n.download-icon {\n    font-size: 32px;\n}\n\n@media only screen and (max-width: 900px) {\n    .product-guide-card {\n        width: 250px;\n    }\n\n}\n\n\n@media only screen and (max-width: 600px) {\n\n    .product-guide-card {\n        width: 160px !important;\n        padding: 0.3rem;\n    }\n\n    .title {\n        font-size: 10px !important;\n    }\n\n    .description {\n        font-size: 8px !important;\n    }\n\n    .download-icon {\n        font-size: 20px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"product-guide-card": `product-guide-card_product-guide-card__9+EtI`,
	"title": `product-guide-card_title__ooCqG`,
	"description": `product-guide-card_description__0eujh`,
	"download-icon": `product-guide-card_download-icon__OkcHE`
};
export default ___CSS_LOADER_EXPORT___;
