// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled-checkbox_no-padding__-kFmH {
  padding: 0 !important;
}

.styled-checkbox_check-icon-pricing-plan-card__jNRYc {
  font-size: 40px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: 0px !important;
  padding-top: 5px !important;
}

.styled-checkbox_checkbox-application-modal__ZUx9i {
  border-radius: 0;
  width: 2.5;
  height: 2.5;
  margin-right: 1rem;
  margin-left: 1rem;
}

.styled-checkbox_auth-dialog-scope-checkbox__qSMAL {
  padding: 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Inputs/StyledCheckbox/styled-checkbox.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,6BAAA;EACA,0BAAA;EACA,qBAAA;EACA,oCAAA;EACA,4BAAA;EACA,2BAAA;AACF;;AACA;EACE,gBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;AAEF;;AAAA;EACE,uBAAA;AAGF","sourcesContent":[".no-padding {\n  padding: 0 !important;\n}\n\n.check-icon-pricing-plan-card {\n  font-size: 40px;\n  -webkit-background-clip: text;\n  -moz-background-clip: text;\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n  padding-left: 0px !important;\n  padding-top: 5px !important;\n}\n.checkbox-application-modal {\n  border-radius: 0;\n  width: 2.5;\n  height: 2.5;\n  margin-right: 1rem;\n  margin-left: 1rem;\n}\n.auth-dialog-scope-checkbox {\n  padding: 5px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-padding": `styled-checkbox_no-padding__-kFmH`,
	"check-icon-pricing-plan-card": `styled-checkbox_check-icon-pricing-plan-card__jNRYc`,
	"checkbox-application-modal": `styled-checkbox_checkbox-application-modal__ZUx9i`,
	"auth-dialog-scope-checkbox": `styled-checkbox_auth-dialog-scope-checkbox__qSMAL`
};
export default ___CSS_LOADER_EXPORT___;
