// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.you-may-also-like-card_card__3adR8 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 0 !important;
  box-shadow: none !important;
  min-height: 14rem !important;
  cursor: pointer;
}

.you-may-also-like-card_card-content-style__Fn\\+ns {
  text-align: center;
}

.you-may-also-like-card_svg-image__EANF- {
  width: 5rem !important;
  margin: 0 auto;
}

.you-may-also-like-card_card-title__0cZ8J {
  font-size: 1rem !important;
  margin-top: 0.5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/YouMayAlsoLikeCard/you-may-also-like-card.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,2BAAA;EACA,2BAAA;EACA,2BAAA;EACA,4BAAA;EACA,eAAA;AAGF;;AADA;EACE,kBAAA;AAIF;;AAFA;EACE,sBAAA;EACA,cAAA;AAKF;;AAHA;EACE,0BAAA;EACA,6BAAA;AAMF","sourcesContent":["@import '../../../variables.module.scss';\n\n.card {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid lightgray;\n  border-radius: 0 !important;\n  box-shadow: none !important;\n  min-height: 14rem !important;\n  cursor: pointer;\n}\n.card-content-style {\n  text-align: center;\n}\n.svg-image {\n  width: 5rem !important;\n  margin: 0 auto;\n}\n.card-title {\n  font-size: 1rem !important;\n  margin-top: 0.5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"card": `you-may-also-like-card_card__3adR8`,
	"card-content-style": `you-may-also-like-card_card-content-style__Fn+ns`,
	"svg-image": `you-may-also-like-card_svg-image__EANF-`,
	"card-title": `you-may-also-like-card_card-title__0cZ8J`
};
export default ___CSS_LOADER_EXPORT___;
