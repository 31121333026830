import axios from 'axios';
import i18next from 'i18next';
import * as Constants from '../configuration';
import idConstants from '../../data/idConstants';

export function getApiStatistics(fromDateVal, toDateVal, role, page, rowsPerPage, appId) {
  const payload = {
    'from': fromDateVal,
    'to': toDateVal,
    'role': role,
    'apps': appId ? appId : null,
    'isTryout': false,
    'tenantId': idConstants.tenantId
  };
  return (dispatch) =>
    axios({
      method: 'POST',
      url:
        Constants.API_STATS_ACTION.USAGE_TRACKING + `/${page}/${rowsPerPage}`,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_USAGE_TRACKING',
          payload: response.data,
          headers: response.headers
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response?.data?.status === 404) {
          dispatch({
            type: 'API_DATA',
            errorMsg: i18next.t('NO_DATA_AVAILABLE') + '!',
          });
        }
        else {
          dispatch({
            type: 'ERROR',
            error: error,
          });
        }
      });
}

/**
 * This code defines a function to remove leading spaces from an array or a string.
 */
const removeLeadingSpaces = (array) => {
  if (Array.isArray(array)) return array;
  else {
    if (array) return array.split(',').map((item) => item.trim());
    else return [];
  }
};

export function getApiMetrics(metrics, fromDateVal, toDateVal, role, appIds, apiId) {  
  return (dispatch) =>
    axios
      .post(
        Constants.API_STATS_ACTION.USAGE_METRICS,
        {
          metrics: removeLeadingSpaces(metrics),
          from: fromDateVal,
          to: toDateVal,
          role: role,
          apps: removeLeadingSpaces(appIds),
          isAggr: true,
          apis: removeLeadingSpaces(apiId)
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-tenant': 'mos' 
          }
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_USAGE_METRICS',
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error?.response && error?.response?.data?.status !== 404) {
          dispatch({
            type: 'ERROR',
            error: error,
          });
        }
      });
}



export const getUserDetails = (userId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        Constants.USER_DETAILS_ACTION.USER_DETAILS + `?userId=${userId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      );
      const data = response?.data?.length > 0 ? response.data[0] : { organization: {} };
      dispatch({
        type: 'SET_USER_DETAILS',
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: 'ERROR',
        error: err,
      });
    }
  };
};



export function getApplications() {
  return (dispatch) =>
    axios
      .get(
        Constants.PRODUCT_DETAIL_ACTION.USER_APPLICATION,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_USER_APPLICATIONS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}

export function getAllSubscriptions(pageNumber, pageSize, reqObj) {
  return (dispatch) =>
    axios
      .get(
        Constants.PRICING_PLAN_ACTION.SUBSCRIPTIONS + `/${pageNumber - 1}/${pageSize}`,
        {
          params: reqObj,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'ALL_SUBSCRIPTIONS',
          payload: response.data
        });
      }).catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}