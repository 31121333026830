import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Container, Box, Typography, Grid, IconButton, Button, alpha } from '@mui/material';
import MsCard from '../ProductCard';
import idConstants from '../../../data/idConstants';
import Images from '../../../assets/img';
import styles from './view-product-info.module.scss';
import isEmpty from '../../../validator/isEmpty';
import { cloudSubCategories, servicesSubCategories } from '../../../data/HomePage/categoriesList';
import YouMayAlsoLikeCard from '../YouMayAlsoLikeCard/youMayAlsoLikeCard';
import DeviceBreakPoints from '../../../deviceBreakPoints';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

function ViewProductInfo(props) {
  const { isSmallDevice, isTab, theme } = DeviceBreakPoints();
  const [offset, setOffset] = useState(0);
  const determineLimit = () => {
    if (isSmallDevice) {
      return 1;
    } else if (isTab) {
      return 3;
    } else {
      return 4;
    }
  };
  const [limit, setLimit] = useState(determineLimit());
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cardContentArray, setCardContentArray] = useState([]);

  const splittedPathName = location?.pathname?.split('/');
  const categoryNameInLowerCase = splittedPathName[splittedPathName?.length - 4]?.replace(/\-/g, '');



  let categories = [];

  useEffect(() => {
    setLimit(determineLimit());
    setOffset(0);
  }, [isSmallDevice, isTab]);

  const handleBackClick = () => {
    setOffset(offset - limit);
  };
  const handleForwardClick = () => {
    setOffset(offset + limit);
  };

  const processCategories = () => {
    const primaryCategoriesToShow = [{
      name: 'SHARED.SAAS',
      image: Images.tools.saas.icon,
      link: idConstants.saas.url,
      categoryNameInLowerCase: idConstants.saas.productCategoryName
    },
    {
      name: 'SHARED.PAAS',
      image: Images.tools.paas.icon,
      link: idConstants.paas.url,
      categoryNameInLowerCase: idConstants.paas.productCategoryName
    },
    {
      name: 'SHARED.IAAS',
      image: Images.tools.iaas.icon,
      link: idConstants.iaas.url,
      categoryNameInLowerCase: idConstants.iaas.productCategoryName
    },
    {
      name: 'SHARED.PRE_DEFINED_WORKFLOW',
      image: Images.tools.preDefinedWorkflow.icon,
      link: idConstants.preDefinedWorkflow.url,
      categoryNameInLowerCase: idConstants.preDefinedWorkflow.productCategoryName
    },
    {
      name: 'SHARED.CUSTOM_WORKFLOW',
      image: Images.tools.customWorkflow.icon,
      link: idConstants.customWorkflow.url,
      categoryNameInLowerCase: idConstants.customWorkflow.productCategoryName
    },
    {
      name: 'SHARED.METHODS',
      image: Images.tools.methods.icon,
      link: idConstants.methods.url,
      categoryNameInLowerCase: idConstants.methods.productCategoryName
    }];
    let cloudInfraCategories = [];
    pushIntoArray(cloudSubCategories, cloudInfraCategories);
    pushIntoArray(servicesSubCategories, cloudInfraCategories);
    categories = [...primaryCategoriesToShow];

  };

  const objectToPush = (item) => {
    const categoryNameInLowerCase = item?.link
      ?.substring(item?.link.lastIndexOf('/') + 1)
      ?.replace(/\-/g, '')
      ?.toLowerCase();

    return {
      name: item?.name,
      image: item?.img,
      link: item?.link,
      categoryNameInLowerCase: categoryNameInLowerCase
    };
  };

  const pushIntoArray = (toMapArray, whichArrayToPush) => {
    toMapArray?.map((item) => {
      whichArrayToPush.push(objectToPush(item));
      return true;
    });
  };

  const getThumbnailImg = (docList, productCategory) => {
    let thumbnailImg = '';
    if (
      !isEmpty(docList) &&
      !isEmpty(docList[idConstants.documentType.thumbnailImg])
    ) {
      thumbnailImg =
        docList[idConstants.documentType.thumbnailImg][0].blobFileUrl;
    } else {
      switch (productCategory) {
        case idConstants.data.categoryId:
          thumbnailImg = Images.data.thumbnail;
          break;
        case idConstants.enterpriseLicensing.categoryId:
          thumbnailImg = Images.services.enterpriseLicensing.thumbnail;
          break;
        case idConstants.managedServices.categoryId:
          thumbnailImg = Images.services.managedServices.thumbnail;
          break;
        case idConstants.cloudInfrastructure.categoryId:
          thumbnailImg = Images.services.cloudInfrastructure.thumbnail;
          break;
        case idConstants.iotSoftwareServices.categoryId:
          thumbnailImg = Images.services.iotSoftwareServices.thumbnail;
          break;
        case idConstants.professionalServices.categoryId:
          thumbnailImg = Images.services.professionalServices.thumbnail;
          break;
        case idConstants.communicationServices.categoryId:
          thumbnailImg = Images.services.communicationServices.thumbnail;
          break;
        case idConstants.solutions.categoryId:
          thumbnailImg = Images.solutions.thumbnail;
          break;
        case idConstants.hardware.categoryId:
          thumbnailImg = Images.hardware.thumbnail;
          break;
        case idConstants.sdk.categoryId:
          thumbnailImg = Images.services.sdk.thumbnail;
          break;
        case idConstants.saas.categoryId:
          thumbnailImg = Images.tools.saas.thumbnail;
          break;
        case idConstants.paas.categoryId:
          thumbnailImg = Images.tools.paas.thumbnail;
          break;
        case idConstants.iaas.categoryId:
          thumbnailImg = Images.tools.iaas.thumbnail;
          break;
        case idConstants.preDefinedWorkflow.categoryId:
          thumbnailImg = Images.tools.preDefinedWorkflow.thumbnail;
          break;
        case idConstants.customWorkflow.categoryId:
          thumbnailImg = Images.tools.customWorkflow.thumbnail;
          break;
        case idConstants.methods.categoryId:
          thumbnailImg = Images.tools.methods.thumbnail;
          break;
        default:
          thumbnailImg = Images.api.thumbnail;
          break;
      }
    }
    return thumbnailImg;
  };
  processCategories();

  const filterProducts = (products) => {
    let tempFilteredProducts = [];
    tempFilteredProducts = products;
    if (!tempFilteredProducts.find((item) => item.id === 'last card')) {
      tempFilteredProducts.push({ id: 'last card' });
    }
    setFilteredProducts(tempFilteredProducts);
  };

  useEffect(() => {
    if (props.productListFromOverview?.length) {
      setFilteredProducts(props.productListFromOverview.filter(
        (product) => product.productId !== props.productID
      ));
    } else if (props.campaignData?.length) {
      setFilteredProducts(props.campaignData);
    } else if (props?.fromYouMayAlsoLike) {
      categories = categories?.filter(
        (item) => categoryNameInLowerCase !== item?.categoryNameInLowerCase
      );
      setFilteredProducts(categories);
    } else if (props?.latestProductList?.length) {
      filterProducts(props?.latestProductList);
    } else if (props?.popularProductList?.length) {
      filterProducts(props?.popularProductList);
    } else if (props?.fromFeatureSection) {
      setFilteredProducts(props?.featureSection);
    }
  }, [props?.featureSection, props.productListFromOverview, props.campaignData, props?.fromYouMayAlsoLike, props?.latestProductList, props?.popularProductList]);


  useEffect(() => {
    setLimit(determineLimit());
    setOffset(0);
    let tempCardContentArray = [];
    filteredProducts?.map((card) => {
      if (card.id === 'last card' && (props?.heading === i18next.t('CATEGORY.LATEST_HEADING') ||
        props?.heading === i18next.t('CATEGORY.MOST_POPULAR'))) {
        tempCardContentArray.push(
          <Grid
            sx={{ color: 'primary.main', backgroundColor: 'primaryBackground.bgBody' }}
            item
            className={determineCardClassName()}
          >
            <Box component='span'><Typography component={'smallText'} variant='p' color={'primary'}>{i18next.t('FILTER_SECTION.VIEW_ALL')}</Typography></Box>
            <Button
              color='primary'
              sx={{ '&:hover': { backgroundColor: 'primaryBackground.bgBody' } }}
              className={styles['view-btn']}
              disableFocusRipple
              disableTouchRipple
              disableRipple
              onClick={() => props?.viewAllProducts()}
            >
              <ArrowCircleRightOutlinedIcon className={`${styles['svg-icon']}`} sx={{ stroke: theme.palette.common.light, strokeWidth: 1 }} />
            </Button>
          </Grid>
        );
      } else {
        tempCardContentArray.push(
          <Grid
            item
            key={card.id}
            className={
              isSmallDevice
                ? styles['card-size-mobile']
                : isTab
                  ? styles['card-item-responsive']
                  : styles['card-item']
            }
          >
            {props?.fromYouMayAlsoLike ? (
              <YouMayAlsoLikeCard
                className={styles['no-box-shadow']}
                cardTitle={i18next.t(card?.name)}
                cardImage={card?.image}
                cardLink={card?.link}
              />
            ) : props?.fromFeatureSection ? (
              props?.featureContent(card)
            ) : (
              <MsCard
                className={styles['no-box-shadow']}
                key={card.id}
                cardTitle={card.productName}
                desc={card.productShortDesc}
                categoryId={card?.productCategory?.categoryId}
                card={card}
                sellerName={card.providerName}
                thumbnailURL={getThumbnailImg(
                  card?.docTypeToDocMetaInfoListMap,
                  card?.productCategory?.categoryId
                )}
                page={props.page}
                flagCheckStyle={'true'}
                purchaseBenefit={card?.purchaseBenefit}
              ></MsCard>
            )}
          </Grid>
        );
      }
      return true;
    });
    setCardContentArray(tempCardContentArray);
  }, [filteredProducts, limit]);

  const determineCardClassName = () => {
    if (isSmallDevice) {
      return `${styles['card-border']} ${styles['card-size-mobile']}`;
    } else if (isTab) {
      return `${styles['card-border']} ${styles['card-item-responsive']}`;
    } else {
      return `${styles['card-border']} ${styles['card-item']}`;
    }
  };

  return <>
    {(cardContentArray && cardContentArray.length > 0) &&
      <Box mt={2}>
        <Container component='div' className={`${styles['relative-position']}`}>
          <Box>
            <Typography variant='h5' style={{ display: props.hideHeading ? 'none' : 'block' }}>
              {props.heading}
            </Typography>
          </Box>
          <Box my={2}>
            <Grid container>
              <Grid
                item
                xs={2}
                sm={0.5}
                md={0.5}
                lg={0.5}
                xl={0.5}
                className={isSmallDevice ? styles['arrow-style'] : styles['left-arrow-style-desktop-view']}
              >
                <IconButton
                  className={
                    cardContentArray.length <= limit
                      ? (`${styles['icon-button-none-style']}`)
                      : ''
                  }
                  disabled={offset === 0}
                  onClick={handleBackClick}
                  disableTouchRipple
                  disableFocusRipple
                  disableRipple
                  classes={{ root: styles['no-padding'] }}
                  size='large'>
                  <ArrowBackIosNewSharpIcon sx={{ fontSize: '1.8rem', stroke: theme.palette.common.light, strokeWidth: 1, '&:hover': { backgroundColor: alpha(theme.palette.common.dark, 0.04) } }}
                    className={`${isSmallDevice
                      ? styles['icon-style-svg-mobile']
                      : styles['icon-style-svg']
                      }`} />
                </IconButton>
              </Grid>
              <Grid item xs={8} sm={12} md={12} lg={12} xl={12}>
                <Grid container>
                  {cardContentArray.slice(offset, offset + limit)}
                </Grid>
              </Grid>
              <Grid
                item
                xs={2}
                sm={0.5}
                md={0.5}
                lg={0.5}
                xl={0.5}
                className={isSmallDevice ? styles['arrow-style'] :
                  styles['right-arrow-style-desktop-view']}
              >
                <IconButton
                  className={
                    offset + limit >= cardContentArray.length
                      ? (`${styles['icon-button-none-style']}`)
                      : ''
                  }
                  disabled={offset + limit >= cardContentArray.length}
                  onClick={handleForwardClick}
                  disableTouchRipple
                  disableFocusRipple
                  disableRipple
                  classes={{ root: styles['no-padding'] }}
                  size='large'>
                  <ArrowForwardIosOutlinedIcon sx={{ fontSize: '1.8rem', stroke: theme.palette.common.light, strokeWidth: 1, '&:hover': { backgroundColor: alpha(theme.palette.common.dark, 0.04) } }}
                    className={`${isSmallDevice
                      ? styles['icon-style-svg-mobile']
                      : styles['icon-style-svg']
                      }`} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Container >
      </Box >
    }
  </>;
}
export default ViewProductInfo;