// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-seller-info_contact-seller-container__ciVMI {
  background-position: center !important;
  background-size: fill !important;
  margin: 20px 20px 20px 0 !important;
}

.contact-seller-info_contact-seller-wrapper__wxPBq {
  padding: 20px 22px !important;
  display: flex !important;
}

.contact-seller-info_contact-seller-heading__QQZe3 {
  padding: 0 !important;
  padding-bottom: 10px !important;
}

.contact-seller-info_title-text-style__2ynZk {
  text-align: left !important;
  font-size: 2.5rem !important;
}

.contact-seller-info_any-query-text__9i0Lf {
  text-align: left !important;
}

.contact-seller-info_contact-seller-style__me8ey {
  display: flex;
  justify-content: flex-end;
  margin-left: auto !important;
  margin-right: 0 !important;
}

.contact-seller-info_contact-seller-button__9Q8d\\+ {
  text-decoration: none !important;
}

.contact-seller-info_contact-seller-button-style__spWqK:hover {
  background-color: none !important;
}

@media only screen and (max-width: 830px) {
  .contact-seller-info_contact-seller-container__ciVMI {
    background-image: none !important;
  }
  .contact-seller-info_title-text-style__2ynZk {
    font-size: 1.5rem !important;
  }
  .contact-seller-info_contact-seller-style__me8ey {
    justify-content: flex-start;
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Utils/ContactSellerInfo/contact-seller-info.module.scss"],"names":[],"mappings":"AAEA;EACI,sCAAA;EACA,gCAAA;EACA,mCAAA;AAGJ;;AAAA;EACI,6BAAA;EACA,wBAAA;AAGJ;;AAAA;EACI,qBAAA;EACA,+BAAA;AAGJ;;AAAA;EACI,2BAAA;EACA,4BAAA;AAGJ;;AAAA;EACI,2BAAA;AAGJ;;AAAA;EACI,aAAA;EACA,yBAAA;EACA,4BAAA;EACA,0BAAA;AAGJ;;AAAA;EACI,gCAAA;AAGJ;;AAAA;EACI,iCAAA;AAGJ;;AAAA;EACI;IACI,iCAAA;EAGN;EADE;IACI,4BAAA;EAGN;EADE;IACI,2BAAA;IACA,6BAAA;IACA,yBAAA;EAGN;AACF","sourcesContent":["@import '../../../../variables.module.scss';\n\n.contact-seller-container {\n    background-position: center !important;\n    background-size: fill !important;\n    margin: 20px 20px 20px 0 !important;\n}\n\n.contact-seller-wrapper {\n    padding: 20px 22px !important;\n    display: flex !important;\n}\n\n.contact-seller-heading {\n    padding: 0 !important;\n    padding-bottom: 10px !important;\n}\n\n.title-text-style {\n    text-align: left !important;\n    font-size: 2.5rem !important;\n}\n\n.any-query-text {\n    text-align: left !important;\n}\n\n.contact-seller-style {\n    display: flex;\n    justify-content: flex-end;\n    margin-left: auto !important;\n    margin-right: 0 !important;\n}\n\n.contact-seller-button {\n    text-decoration: none !important;\n}\n\n.contact-seller-button-style:hover {\n    background-color: none !important ;\n}\n\n@media only screen and (max-width: 830px) {\n    .contact-seller-container {\n        background-image: none !important;\n    }\n    .title-text-style {\n        font-size: 1.5rem !important;\n    }\n    .contact-seller-style {\n        justify-content: flex-start;\n        margin-right: auto !important;\n        margin-left: 0 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"contact-seller-container": `contact-seller-info_contact-seller-container__ciVMI`,
	"contact-seller-wrapper": `contact-seller-info_contact-seller-wrapper__wxPBq`,
	"contact-seller-heading": `contact-seller-info_contact-seller-heading__QQZe3`,
	"title-text-style": `contact-seller-info_title-text-style__2ynZk`,
	"any-query-text": `contact-seller-info_any-query-text__9i0Lf`,
	"contact-seller-style": `contact-seller-info_contact-seller-style__me8ey`,
	"contact-seller-button": `contact-seller-info_contact-seller-button__9Q8d+`,
	"contact-seller-button-style": `contact-seller-info_contact-seller-button-style__spWqK`
};
export default ___CSS_LOADER_EXPORT___;
