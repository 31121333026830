// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay_content-parent__6e5tv {
  overflow: hidden;
  transition: height 0.2s ease-in;
  height: 39px;
  position: absolute;
  top: 100%;
  bottom: 100%;
  width: 100%;
  z-index: 1000;
  border: 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/CustomAccordion/overlay.module.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,+BAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;AAAJ","sourcesContent":["    \n.content-parent {\n    overflow: hidden;\n    transition: height 0.2s ease-in;\n    height: 39px;\n    position: absolute;\n    top: 100%;\n    bottom: 100%;\n    width: 100%;\n    z-index: 1000;\n    border: 1px solid;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-parent": `overlay_content-parent__6e5tv`
};
export default ___CSS_LOADER_EXPORT___;
