// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-video-card_product-guide-grid__XWqNH {
  display: flex !important;
  width: 255px !important;
  height: 280px !important;
  margin: 1rem !important;
}

.product-video-card_time-grid__a1GrS {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.product-video-card_product-section-video-container__yDnvh .product-video-card_product-section-video-wrapper__xo5\\+n {
  display: flex;
  justify-content: center;
  position: relative;
}
.product-video-card_product-section-video-container__yDnvh .product-video-card_product-section-video-wrapper__xo5\\+n video {
  width: 255px;
  height: 150px;
  border: 3px solid #ececec;
  border-radius: 2px;
}

.product-video-card_product-section-video2__BQBvj {
  display: none;
}

.product-video-card_thumbnail-section-image__mnFYZ {
  width: 255px;
  height: 150px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .product-video-card_product-guide-grid__XWqNH {
    margin: 0.5rem !important;
    width: 155px !important;
    height: 220px !important;
  }
  .product-video-card_thumbnail-section-image__mnFYZ {
    width: 155px;
    height: 120px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductGuideVideoPage/ProductCardGrid/ProductVideoCard/product-video-card.module.scss"],"names":[],"mappings":"AAEA;EACI,wBAAA;EACA,uBAAA;EACA,wBAAA;EACA,uBAAA;AAGJ;;AAAA;EACI,aAAA;EACA,WAAA;EACA,yBAAA;AAGJ;;AAAI;EACI,aAAA;EACA,uBAAA;EACA,kBAAA;AAGR;AADQ;EACI,YAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AAGZ;;AACA;EACI,aAAA;AAEJ;;AACA;EACI,YAAA;EACA,aAAA;EACA,eAAA;AAEJ;;AACA;EAEI;IACI,yBAAA;IACA,uBAAA;IACA,wBAAA;EACN;EAEE;IACI,YAAA;IACA,aAAA;EAAN;AACF","sourcesContent":["@import '../../../../variables.module.scss';\n\n.product-guide-grid {\n    display: flex !important;\n    width: 255px !important;\n    height: 280px !important;\n    margin: 1rem !important;\n}\n\n.time-grid {\n    display: flex;\n    width: 100%;\n    justify-content: flex-end;\n}\n.product-section-video-container {\n    .product-section-video-wrapper {\n        display: flex;\n        justify-content: center;\n        position: relative;\n\n        video {\n            width: 255px;\n            height: 150px;\n            border: 3px solid #ececec;\n            border-radius: 2px;\n        }\n    }\n}\n.product-section-video2 {\n    display: none;\n}\n\n.thumbnail-section-image {\n    width: 255px;\n    height: 150px;\n    cursor: pointer;    \n}\n\n@media only screen and (max-width: 600px) {\n\n    .product-guide-grid {\n        margin: 0.5rem !important;\n        width: 155px !important;\n        height: 220px !important;\n    }\n\n    .thumbnail-section-image {\n        width: 155px;\n        height: 120px;\n    }\n    \n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalBoschFont": `"boschsans", sans-serif !important`,
	"product-guide-grid": `product-video-card_product-guide-grid__XWqNH`,
	"time-grid": `product-video-card_time-grid__a1GrS`,
	"product-section-video-container": `product-video-card_product-section-video-container__yDnvh`,
	"product-section-video-wrapper": `product-video-card_product-section-video-wrapper__xo5+n`,
	"product-section-video2": `product-video-card_product-section-video2__BQBvj`,
	"thumbnail-section-image": `product-video-card_thumbnail-section-image__mnFYZ`
};
export default ___CSS_LOADER_EXPORT___;
