// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card-grid_grid-container__0O68A {
  display: flex;
  flex-wrap: wrap !important;
}

@media only screen and (max-width: 900px) {
  .product-card-grid_grid-container__0O68A {
    justify-content: space-between !important;
    height: 290px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductGuideVideoPage/ProductCardGrid/product-card-grid.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,0BAAA;AACJ;;AAEA;EACI;IACI,yCAAA;IACA,aAAA;EACN;AACF","sourcesContent":[".grid-container {\n    display: flex;\n    flex-wrap: wrap !important;\n}\n\n@media only screen and (max-width: 900px) {\n    .grid-container {\n        justify-content: space-between !important;\n        height: 290px;\n    }\n}   \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid-container": `product-card-grid_grid-container__0O68A`
};
export default ___CSS_LOADER_EXPORT___;
