import i18next from 'i18next';
import images from '../../assets/img';
const CustomWorkflowData = () => {
  const designImage = [
    images.categoryAssets.customWorkflow.mapTheProcess,
    images.categoryAssets.customWorkflow.breakItDown,
    images.categoryAssets.customWorkflow.downloadWorkflowImage
  ];
  const configureImage = [images.categoryAssets.customWorkflow.inputAndOutput];
  const saveAndOrderImage = [
    images.categoryAssets.customWorkflow.save,
    images.categoryAssets.customWorkflow.submitAndOrder
  ];
  const design = [
    {
      id: 1,
      icon: designImage[0],
      title: i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.DESIGN.DETAILS', {
        returnObjects: true
      })[0].HEADING,
      description: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.DESIGN.DETAILS',
        { returnObjects: true }
      )[0].DESCRIPTION
    },
    {
      id: 2,
      icon: designImage[1],
      title: i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.DESIGN.DETAILS', {
        returnObjects: true
      })[1].HEADING,
      description: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.DESIGN.DETAILS',
        { returnObjects: true }
      )[1].DESCRIPTION
    },
    {
      id: 3,
      icon: designImage[2],
      title: i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.DESIGN.DETAILS', {
        returnObjects: true
      })[2].HEADING,
      description: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.DESIGN.DETAILS',
        { returnObjects: true }
      )[2].DESCRIPTION
    }
  ];
  const configure = [
    {
      id: 1,
      icon: configureImage[0],
      title: i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.CONFIGURE.DETAILS', {
        returnObjects: true
      })[0].HEADING,
      description: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CONFIGURE.DETAILS',
        { returnObjects: true }
      )[0].DESCRIPTION
    }
  ];
  const saveAndOrder = [
    {
      id: 1,
      icon: saveAndOrderImage[0],
      title: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.SAVE_AND_ORDER.DETAILS',
        { returnObjects: true }
      )[0].HEADING,
      description: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.SAVE_AND_ORDER.DETAILS',
        { returnObjects: true }
      )[0].DESCRIPTION
    },
    {
      id: 2,
      icon: saveAndOrderImage[1],
      title: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.SAVE_AND_ORDER.DETAILS',
        { returnObjects: true }
      )[1].HEADING,
      description: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.SAVE_AND_ORDER.DETAILS',
        { returnObjects: true }
      )[1].DESCRIPTION
    }
  ];
  const caseStudyDetails = [
    {
      id: 1,
      title: i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.DETAILS', {
        returnObjects: true
      })[0].HEADING,
      description1: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.DETAILS',
        { returnObjects: true }
      )[0].DESCRIPTION1,
      description2: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.DETAILS',
        { returnObjects: true }
      )[0].DESCRIPTION2
    },
    {
      id: 2,
      title: i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.DETAILS', {
        returnObjects: true
      })[1].HEADING,
      description1: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.DETAILS',
        { returnObjects: true }
      )[1].DESCRIPTION1
    },
    {
      id: 3,
      title: i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.DETAILS', {
        returnObjects: true
      })[2].HEADING,
      description1: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.DETAILS',
        { returnObjects: true }
      )[2].DESCRIPTION1,
      description2: i18next.t(
        'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.DETAILS',
        { returnObjects: true }
      )[2].DESCRIPTION2
    }
  ];
  return { design, configure, saveAndOrder, caseStudyDetails };
};
export default CustomWorkflowData;
