import { isValidEmail, isValidPhoneNumber } from '../validator/customValidator';
import isEmpty from '../validator/isEmpty';
import i18next from 'i18next';

export const initialGetInTouchDetailsState = {
    name: '',
    workEmail: '',
    phone: '',
    orgName: '',
    message: '',
    preferredModeToReach: '',
    enquiryType: '',
    categoryOfApplication: ''
};

export const initialGetInTouchDetailsErrorState = {
    name: '',
    workEmail: '',
    phone: '',
    orgName: '',
    message: '',
    preferredModeToReach: '',
    enquiryType: '',
    categoryOfApplication: ''
};

export const validateDetails = (event, detailsError) => {
    const { name, value } = event.target;
    const errorDetails = { ...detailsError };
    switch (name) {
        case 'name':
            errorDetails[name] = isEmpty(value) && '';
            break;
        case 'phone':
            errorDetails[name] = isEmpty(value) ? '' : !isValidPhoneNumber(value) ? i18next.t('GET_IN_TOUCH_PAGE.INVALID_PHONE')+'.' : '';
            break;
        case 'workEmail':
            errorDetails[name] = isEmpty(value) ? '' : !isValidEmail(value) ? i18next.t('GET_IN_TOUCH_PAGE.INVALID_EMAIL')+'.' : '';
            break;
        case 'orgName':
            errorDetails[name] = isEmpty(value) && '';
            break;
        case 'message':
            errorDetails[name] = isEmpty(value) && '';
            break;
        case 'enquiryType':
            errorDetails[name] = isEmpty(value) && '';
            break;
    }
    return { ...errorDetails };
};