import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderContainer from './header';
import Footer from '../components/BaseLayout/Footer';
import BreadCrumb from '../components/BaseLayout/BreadCrumb';
import Category from '../components/Category';
import IdConstants from '../data/idConstants';
import Loader from '../components/Shared/Utils/Loader';
import { getAllMasterData, getSubCategories, getPublishedLatestProducts, getPopularProducts } from '../actions/CategoryAction';
import { CategoryDetailsContext, categoryDetails } from '../context/categoryDetails';
import SnackBar from '../components/Shared/Utils/SnackBar';
import store from '../store.js';
import camelCaseText from '../utils/camelCase';
import { getFilterCategories } from '../actions/LandingAction';
import { useHistory } from 'react-router-dom';
import { replaceSpaceWithHyphenInLowerCaps } from '../utils';

function CategoryContainer(props) {

    const [categoryId, setCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(categoryDetails);
    const history = useHistory();

    const errorCallback = (value) => {
        setError(value);
    };

    const resetError = () => {
        setError(null);
    };

    const updateStore = (actionKey, value) => {
        store.dispatch({
            type: actionKey,
            payload: value
        });
    };

    useEffect(() => {
      const path = window?.location?.pathname;
      const categoryName = path?.split('/')[1];

      path !== '/listing' &&
        path?.split('/')?.length === 3 &&
        ![
          replaceSpaceWithHyphenInLowerCaps(
            IdConstants.cloudServices.productCategoryName
          ),
          replaceSpaceWithHyphenInLowerCaps(
            IdConstants.services.productCategoryName
          )
        ].includes(categoryName) &&
        props.history.push('/page-not-found');
    }, []);

    useEffect(() => {
        if (categoryId)
            props.getFilterCategories('category=' + categoryId);
    }, [categoryId]);

    useEffect(() => {
        const pathname = window.location.pathname;
        if (
            pathname.includes('/api')
        ) {
            setCategoryId(IdConstants.api.categoryId);
            setCategoryName(IdConstants.api.productCategoryName);
            setSubCategoryName('');
        } else if (pathname.includes('/data')) {
            setCategoryId(IdConstants.data.categoryId);
            setCategoryName(IdConstants.data.productCategoryName);
            setSubCategoryName('');
        } else if (pathname.includes('/solutions')) {
            setCategoryId(IdConstants.solutions.categoryId);
            setCategoryName(IdConstants.solutions.productCategoryName);
            setSubCategoryName('');
        }
        else if (pathname.includes('/cloud-services') || pathname.includes('/services')) {
            const cameledCase = camelCaseText(pathname.split('/')[2].replace(/-/g, ' '));
            if(IdConstants[cameledCase]){
            setCategoryId(IdConstants[cameledCase].categoryId);
            setSubCategoryName(IdConstants[cameledCase].productCategoryName);}
            else history.push('/page-not-found');
        }
        else if (pathname.includes('/hardware')) {
            setCategoryId(IdConstants.hardware.categoryId);
            setCategoryName(IdConstants.hardware.productCategoryName);
            setSubCategoryName('');
        } else if(pathname.includes(IdConstants.saas.url)) {
            setCategoryId(IdConstants.saas.categoryId);
            setCategoryName(IdConstants.saas.productCategoryName);
            setSubCategoryName('');
        } else if(pathname.includes(IdConstants.paas.url)) {
            setCategoryId(IdConstants.paas.categoryId);
            setCategoryName(IdConstants.paas.productCategoryName);
            setSubCategoryName('');
        } else if(pathname.includes(IdConstants.iaas.url)) {
            setCategoryId(IdConstants.iaas.categoryId);
            setCategoryName(IdConstants.iaas.productCategoryName);
            setSubCategoryName('');
        } else if(pathname.includes(IdConstants.preDefinedWorkflow.url)) {
            setCategoryId(IdConstants.preDefinedWorkflow.categoryId);
            setCategoryName(IdConstants.preDefinedWorkflow.productCategoryName);
            setSubCategoryName('');
        } else if(pathname.includes(IdConstants.customWorkflow.url)) {
            setCategoryId(IdConstants.customWorkflow.categoryId);
            setCategoryName(IdConstants.customWorkflow.productCategoryName);
            setSubCategoryName('');
        } else if(pathname.includes(IdConstants.methods.url)) {
            setCategoryId(IdConstants.methods.categoryId);
            setCategoryName(IdConstants.methods.productCategoryName);
            setSubCategoryName('');
        } else {
            history.push('/page-not-found');
        }
    }, [window.location.pathname]);

    const setCategoryPage = async () => {
        const masterDataResponse = await getAllMasterData(categoryId, errorCallback);
        const subCategoryResponse = await getSubCategories(categoryId, errorCallback);
        const latestProductsResponse = await getPublishedLatestProducts('', categoryId, errorCallback, 0, data.maxCardsLength, '', 'recent');
        const popularProductsResponse = await getPopularProducts(categoryId, errorCallback, 0, data.maxCardsLength);
        setLoader(false);
        setData({
            ...data,
            subCategories: masterDataResponse?.subcategory,
            subCategoryCounts: subCategoryResponse?.counts,
            latestProducts: latestProductsResponse?.products,
            latestProductsCount: latestProductsResponse?.counts && Object.values(latestProductsResponse?.counts),
            popularProducts: popularProductsResponse?.data?.products,
            popularProductsCount: popularProductsResponse?.counts,
            categoryId: categoryId,
            categoryName: subCategoryName ? subCategoryName : categoryName
        });
    };

    useEffect(() => {
        setLoader(true);
        if (categoryId) {
            if (props?.categoryDetails?.subCategories?.length && (props?.categoryDetails?.categoryId && categoryId === props?.categoryDetails?.categoryId)) {
                setLoader(false);
                setData({
                    ...props?.categoryDetails
                });
            } else {
                setCategoryPage();
            }
        }
    }, [categoryId]);

    useEffect(() => {
        if (data?.subCategories?.length)
            updateStore('UPDATE_CATEGORY_DETAILS', data);
    }, [data]);

    return (
        <>
            {loader && <Loader />}
            <HeaderContainer />
            <BreadCrumb value={subCategoryName ? subCategoryName : categoryName} />
            <CategoryDetailsContext.Provider value={data}>
        {
          <Category
            filterCategories={props.filterCategories}
            userDetails={props.user}
          />
        }
            </CategoryDetailsContext.Provider>
            <Footer />
            {
                (error) && <SnackBar
                    severity='error'
                    changeState={() => resetError()}
                    message={error}
                />
            }
        </>
    );
}
const mapStateToProps = (redux_state) => ({
    user: redux_state.loginReducer.user,
    categoryDetails: redux_state.CategoryReducer.categoryDetails,
    filterCategories: redux_state.LandingReducer.filterCategories
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getFilterCategories
            },
            dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);