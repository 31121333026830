import React from 'react';
import { Card, Button, Box, Typography, Tooltip } from '@mui/material';
import styles from './product-guide-card.module.scss';
import { productGuideList } from '../../../../data/productGuideVideoData';
import Loader from '../../../Shared/Utils/Loader';
import i18next from 'i18next';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import DeviceBreakPoints from '../../../../deviceBreakPoints';

const ProductGuideCard = () => {

    const [loader, setLoader] = React.useState(false);
    const { theme } = DeviceBreakPoints();

    const downloadDoc = (url) => {
        setLoader(true);
        const req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.responseType = 'blob';
        req.onload = function () {
            var blob = new Blob([req.response], { type: 'application/octetstream' });
            var urlLink = window.URL || window.webkitURL;
            var link = urlLink.createObjectURL(blob);
            var anchor = document.createElement('a');
            anchor.setAttribute('download', url.split('/').pop());
            anchor.setAttribute('href', link);
            document.body.appendChild(anchor);
            anchor.click();
            setLoader(false);
            document.body.removeChild(anchor);
        };
        req.send();
    };

    const ProductGuide = (props) => {

        return (
            <Card sx={{backgroundColor : 'primaryBackground.bgBody' }} className={styles['product-guide-card']}>
                <Typography color='common.dark' variant='h6' className={styles['title']}>
                    {i18next.t(props.list.question)}
                </Typography>
                <Typography variant='body1' className={styles['description']}>
                    {i18next.t(props.list.solution)}
                </Typography>
                <Box
                    display='flex'
                    justifyContent='flex-end'
                    alignItems='flex-end'
                >
                    <Tooltip title={i18next.t('SHARED.DOWNLOAD_BUTTON')}>
                        <Button
                            onClick={() => downloadDoc(props.list.link)}
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                        >
                            <DownloadForOfflineOutlinedIcon className={styles['download-icon']} sx={{stroke: theme.palette.common.light, strokeWidth: 1}}/>
                        </Button>
                    </Tooltip>
                </Box>
            </Card>

        );
    };

    return (
        <>
            {
                productGuideList.map((list) => {
                    return <ProductGuide list={list} />;
                }
                )
            }
            {loader && <Loader />}
        </>
    );
};

export default ProductGuideCard;
