import axios from 'axios';
import * as Constants from './configuration';
import i18next from 'i18next';
import { regenerateAAATokens } from './LoginAction';

export function saveUserDetails(reqObject, dispatch) {
    return axios({
        method: 'POST',
        url:
        Constants.REGISTER_ACTION.SAVE_REGISTER_USER_DETAILS,
        data: reqObject,
        headers: {
        'Content-Type': 'application/json'
        }
    })
    .then((response) => {
        dispatch({
            type: 'USER_COMPANY_STATUS',
            payload: response?.data,
        });
        return response.data;
    })
        .catch((error) => {
        dispatch({
            type: 'USER_REGISTRATION_ERROR_ALERT',
            error: i18next.t('INTERNAL_BUYER.TOAST_MESSAGES.USER_REGISTRATION_ERROR'),
        });
        return error?.response;
    });
};

export const submitTermsConsent = () => async dispatch => {
    await axios.post(`${Constants.REGISTER_ACTION.ACCEPT_USER_COMPANY_TERMS}`, {}, {
        headers: {
        'content-type': 'application/json'
        }
    }).then(async response => {
        await dispatch(setCompanyId());
    }).catch(error => {
        dispatch({
        type: 'USER_REGISTRATION_ERROR_ALERT',
        error: i18next.t('INTERNAL_BUYER.TOAST_MESSAGES.USER_REGISTRATION_ERROR')
        });
    });
};

export function setCompanyId() {
    return async (dispatch) => 
    await axios({
        method: 'POST',
        url:
        Constants.REGISTER_ACTION.CREATE_COMPANY,
        data: {},
        headers: {
        'Content-Type': 'application/json'
        }
    })
    .then(async (response) => {
        await dispatch(regenerateAAATokens());
        dispatch({
            type: 'USER_COMPANY_STATUS',
            payload: response?.data
        });
        dispatch({
            type: 'USER_REGISTRATION_SUCCESS_ALERT',
            payload: i18next.t('INTERNAL_BUYER.TOAST_MESSAGES.USER_REGISTERED_SUCCESSFULLY')
        });
    })
    .catch((error) => {
        dispatch({
            type: 'USER_REGISTRATION_ERROR_ALERT',
            error: i18next.t('INTERNAL_BUYER.TOAST_MESSAGES.USER_REGISTRATION_ERROR')
        });
    });
};

export function setUserDetails(obj) {
    return (dispatch) =>
        dispatch({
        type: 'USER_DETAILS',
        payload: obj,
        });
};