import {
  Container,
  Grid,
  Toolbar,
  Button,
  Box,
  Typography,
  alpha,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import * as Constants from '../../actions/configuration/index';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import styles from './category.module.scss';
import images from '../../assets/img';
import { CategoryDetailsContext } from '../../context/categoryDetails';
import ViewProductInfo from '../Shared/ViewProductInfo';
import idConstants from '../../data/idConstants';
import { categoryLogoData } from '../../data/categoryLogoData.js';
import CustomWorkflow from '../CustomWorkflow';
import WorkFlowEditor from '@top99/workflow-editor';
import DeviceBreakPoints from '../../deviceBreakPoints';
import { IMAGE_CDN } from '../../actions/configuration';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ContactSellerInfo from '../Shared/Utils/ContactSellerInfo';
import {
  isObjectEmpty,
  // checkIfUserHasBuyerRole,
  submitOrderToMiddleware,
} from '../../utils/helperFunctionsTop99';
import { useDispatch, useSelector } from 'react-redux';
import CostCenterDetails from '../Shared/CostCenterDetails';

function Category(props) {
  const { isMediumDevice, isSmallDevice, isDesktopDevice, theme } =
    DeviceBreakPoints();
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const categoryDetailsContext = useContext(CategoryDetailsContext);
  const [featuresSection, setFeaturesSection] = useState([]);
  const [showWorkflowEditor, setShowWorkflowEditor] = useState(false);
  const [consumerAppId, setConsumerAppId] = useState('');
  const categoryName = categoryDetailsContext?.categoryName
    .toUpperCase()
    .replace(/ /g, '_');
  const history = useHistory();
  const isCustomWorkflowCategory =
    categoryName ===
    idConstants.customWorkflow.productCategoryName
      .toUpperCase()
      .replace(/ /g, '_');

  const getDataSize = () => {
    if (isDesktopDevice) {
      return 4;
    } else {
      return 3;
    }
  };

  const splitTitle = (title) => {
    const [left, center, right] = title.split(/<b>|<\/b>/);

    return (
      <Box component='span'>
        {left}
        {center && (
          <Box component='span'>
            <Typography variant='boldText'>{center}</Typography>
          </Box>
        )}
        {right ? right : ''}
      </Box>
    );
  };

  useEffect(() => {
    if (
      categoryDetailsContext?.subCategoryCounts &&
      categoryDetailsContext?.subCategories?.length
    ) {
      let filteredItem = [];
      let lastItem = {};
      for (const [key, value] of Object.entries(
        categoryDetailsContext?.subCategoryCounts
      )) {
        if (key === idConstants.otherSubCategory.apiItem) {
          lastItem.category = categoryDetailsContext?.subCategories?.filter(
            (subCategoryItem) => subCategoryItem.id === key
          )[0];
          lastItem.count = Number(value);
        } else {
          filteredItem.push({
            category: categoryDetailsContext?.subCategories?.filter(
              (subCategoryItem) => subCategoryItem.id === key
            )[0],
            count: Number(value),
          });
        }
      }
      if (lastItem && Object.keys(lastItem).length) filteredItem.push(lastItem);
      let tempData = [];
      for (
        let index = 0;
        index < filteredItem?.length;
        index += getDataSize()
      ) {
        tempData.push(filteredItem?.slice(index, index + getDataSize()));
      }
      setFilteredSubCategories(tempData);
    }

    return () => {
      setFilteredSubCategories([]);
      setFeaturesSection([]);
    };
  }, [categoryDetailsContext, isDesktopDevice]);

  useEffect(() => {
    if (categoryDetailsContext?.categoryName) {
      let tempFeaturesSection = [];
      let featuresArrayText = i18next.t(
        `CATEGORY.${categoryName}.FEATURES_SECTION`,
        { returnObjects: true }
      );
      if (Array.isArray(featuresArrayText)) {
        featuresArrayText?.map((item, index) => {
          let featureItem = {};
          featureItem.id = index;
          featureItem.description = item;
          if (
            images.categoryAssets[categoryDetailsContext?.categoryId] &&
            images.categoryAssets[categoryDetailsContext?.categoryId][
              `featureImg${index + 1}`
            ]
          )
            featureItem.logo =
              images.categoryAssets[categoryDetailsContext?.categoryId][
                `featureImg${index + 1}`
              ];
          tempFeaturesSection.push(featureItem);
          return true;
        });
      }
      setFeaturesSection(tempFeaturesSection);
    }
  }, [categoryDetailsContext?.categoryName]);

  const getImage = (name) => {
    return images.categoryAssets[name];
  };

  const getBackgroundImage = (item) => {
    let categoryName = categoryDetailsContext?.categoryName
      ?.toLowerCase()
      .replace(/ /g, '');
    if (
      categoryLogoData[categoryName] &&
      categoryLogoData[categoryName][item?.category?.id]
    )
      return categoryLogoData[categoryName][item?.category?.id];
  };

  const handleButtonClick = () => {
    setShowWorkflowEditor(true);
  };

  const goToListingPage = (id, subCategoryName) => {
    const hyphenedSubCategoryName = subCategoryName
      ?.toLowerCase()
      ?.replace(/ /g, '-');
    const navigateTo =
      window.location.pathname + '/' + hyphenedSubCategoryName + '/listing';
    history.push({
      pathname: navigateTo,
      state: { prePopulateFrom: { url: location.pathname, id: id } },
    });
  };

  const viewAllProducts = () => {
    history.push({
      pathname: window.location.pathname + '/listing',
      state: { prePopulateFrom: { url: location.pathname } },
    });
  };

  const featureContent = (item) => {
    const getFeatureItemClassName = () => {
      if (isSmallDevice) {
        return styles['feature-item-responsive'];
      } else if (isMediumDevice) {
        return styles['feature-item-tab-view'];
      } else {
        return styles['feature-item'];
      }
    };
    return (
      <Grid
        sx={{
          background: theme.palette.secondary.light,
          border: 'none !important',
          justifyContent: 'center !important',
        }}
        item
        className={getFeatureItemClassName()}
      >
        <Box
          component='div'
          sx={{ background: theme.palette.primaryBackground.main }}
          className={styles['logo-item']}
        >
          <Box
            component='img'
            src={item.logo}
            className={styles['logo-image']}
            alt={i18next.t('CATEGORY.FEATURE_IMAGE')}
          />
        </Box>
        <Box component='div' className={styles['description-feature']}>
          <Typography component='smallText' variant='body1'>
            {item.description}
          </Typography>
        </Box>
      </Grid>
    );
  };

  const getGridClassName = () => {
    if (isSmallDevice) return styles['sub-category-item-responsive'];
    else {
      if (isMediumDevice) return styles['sub-category-item-tab-view'];
      else return styles['sub-category-item'];
    }
  };
  const getApplicationId = async () => {
    const token = localStorage.getItem(idConstants?.tokens?.aaaIdToken);
    if (token) {
      try {
        const response = await axios.get(
          Constants.PRODUCT_DETAIL_ACTION.USER_APPLICATION,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              // 'X-Tenant': 'tos',
            },
            data: {},
          }
        );
        setConsumerAppId(response.data[0].consumerAppId);

        return response.data[0].consumerAppId;
      } catch (error) {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      }
    }
  };

  useEffect(() => {
    console.log(categoryName);
    if (categoryName === 'CUSTOM_WORKFLOW') {
      getApplicationId();
    }
  }, [categoryName]);

  const dispatch = useDispatch();
  const [costCenterPopUp, setCostCenterPopUp] = useState(false);
  const openCostCenterPopup = () => {
    setCostCenterPopUp(true);
  };
  const closeCostCenterPopUp = () => {
    setCostCenterPopUp(false);
  };
  const [submitOrderPayloadFromWfe, setSubmitOrderPayloadFromWfe] = useState(
    {}
  );

  useEffect(() => {
    if (!isObjectEmpty(submitOrderPayloadFromWfe)) {
      openCostCenterPopup();
    }
  }, [submitOrderPayloadFromWfe]);

  const { orderDetails, pspPaymentFailureResponse } = useSelector((state) => ({
    orderDetails: state?.OrderSummaryReducer?.orderDetails,
    pspPaymentFailureResponse:
      state?.OrderSummaryReducer?.pspPaymentFailureResponse,
  }));

  useEffect(() => {
    if (orderDetails || pspPaymentFailureResponse) {
      history.push({
        pathname: '/order-details',
        state: {
          isFreePolicyPlan: true,
        },
      });
    }
  }, [orderDetails, pspPaymentFailureResponse]);

  const handleWorkflowOrder = (costCenter) => {
    const workflowOrderPayload = submitOrderPayloadFromWfe;
    workflowOrderPayload.user['costCenter'] = costCenter;
    submitOrderToMiddleware(workflowOrderPayload, dispatch);
  };

  return (
    <>
      {costCenterPopUp && (
        <CostCenterDetails
          open={costCenterPopUp}
          closeCostCenterPopUp={closeCostCenterPopUp}
          handlePlaceOrder={handleWorkflowOrder}
        />
      )}
      <Box
        className={styles['category-container']}
        sx={{
          backgroundImage: isSmallDevice
            ? 'none'
            : `url(${IMAGE_CDN}revamped-homepage-banner-background.png)`,
          backgroundColor: 'primaryBackground.banner',
          color: theme.palette.common.light,
        }}
      >
        <Container>
          <Toolbar className={!isMediumDevice ? '' : styles['toolbar-small']}>
            {categoryName && (
              <Grid container>
                <Grid
                  item
                  className={
                    isSmallDevice
                      ? styles['text-style-responsive']
                      : styles['text-style']
                  }
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Box
                    className={
                      isSmallDevice
                        ? isCustomWorkflowCategory
                          ? styles['custom-workflow-banner-text-responsive']
                          : styles['banner-text-responsive']
                        : styles['banner-text']
                    }
                  >
                    <Trans>
                      <Typography
                        component='h1'
                        variant='bannerTitle'
                        sx={{ paddingRight: '0px', lineHeight: '30px' }}
                      >
                        {i18next.t(`CATEGORY.${categoryName}.BANNER_TEXT`)}
                        {isCustomWorkflowCategory && (
                          <Typography
                            component='span'
                            variant='bannerTitle'
                            sx={{
                              fontWeight: 'bold',
                              paddingRight: '0px',
                              lineHeight: '30px',
                            }}
                          >
                            {i18next.t(
                              'CATEGORY.CUSTOM_WORKFLOW.BANNER_ADDITIONAL_TEXT'
                            )}
                          </Typography>
                        )}
                      </Typography>
                      <Box component='span'> </Box>
                    </Trans>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Box
                    component='img'
                    src={getImage(categoryName.toLowerCase())}
                    alt={i18next.t(`CATEGORY.${categoryName}.BANNER_ALT_IMAGE`)}
                    className={
                      isSmallDevice
                        ? styles['img-banner-responsive']
                        : styles['img-banner']
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Toolbar>
        </Container>
      </Box>
      {categoryName === 'CUSTOM_WORKFLOW' &&
        (props?.userDetails?.userName ? (
          <Box>
            {!showWorkflowEditor && (
              <CustomWorkflow onButtonClick={handleButtonClick} />
            )}
            {showWorkflowEditor && (
              <WorkFlowEditor
                environment='development'
                language='en'
                workflowType='custom'
                userDetails={props.userDetails}
                categories={[
                  'Acceptance Testing',
                  'Requirement Analysis',
                  'Deployment and Maintenance',
                  'Code Generation',
                  'Configuration Management - Smart DNC',
                  'Configuration Management',
                  'Detailed Design',
                  'High-Level Design',
                  'System Testing',
                  'Traceability',
                  'Unit Testing',
                ]}
                readonly={!props?.userDetails?.userName}
                submitOrderPayloadUpdate={setSubmitOrderPayloadFromWfe}
                orderTrackingId={consumerAppId}
                token={
                  idConstants?.tokens?.aaaIdToken
                    ? localStorage.getItem(idConstants.tokens.aaaIdToken)
                    : ''
                }
              />
            )}
          </Box>
        ) : (
          <>
            <Trans>
              <Typography
                component='h2'
                variant='bannerTitle'
                sx={{
                  lineHeight: '20px',
                  marginTop: '30px',
                  color: 'black',
                }}
              >
                {i18next.t(`CATEGORY.CUSTOM_WORKFLOW.LOGIN_GUIDE_TEXT`)}
              </Typography>
              <Box component='span'> </Box>
            </Trans>
          </>
        ))}
      {categoryName !== 'CUSTOM_WORKFLOW' && (
        <Box sx={{ backgroundColor: 'primaryBackground.bgBody' }} pb={3}>
          <>
            <Container
              className={isMediumDevice ? '' : styles['container-padding']}
            >
              {categoryName && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={styles['desc-style-text1']}
                  >
                    {isCustomWorkflowCategory && <CustomWorkflow />}
                    <Typography variant='body1'>
                      {splitTitle(i18next.t(`CATEGORY.${categoryName}.TEXT1`))}
                    </Typography>
                  </Grid>
                </>
              )}
              {categoryName &&
                i18next.t(`CATEGORY.${categoryName}.WHAT_WE_OFFER_TEXT`) && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={
                        isDesktopDevice
                          ? styles['category-section-heading']
                          : styles['category-section-heading-responsive']
                      }
                    >
                      <Typography variant='h5'>
                        {i18next.t('CATEGORY.WHAT_WE_OFFER_HEADING')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={styles['desc-style-text2']}
                      sx={{ paddingTop: '0 !important' }}
                    >
                      <Typography variant='body1'>
                        {i18next.t(
                          `CATEGORY.${categoryName}.WHAT_WE_OFFER_TEXT`
                        )}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Container
                className={
                  isMediumDevice
                    ? styles['products-container-responsive']
                    : styles['products-container']
                }
              >
                {categoryDetailsContext?.latestProducts?.length > 0 &&
                  !isCustomWorkflowCategory && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ViewProductInfo
                        heading={i18next.t('CATEGORY.LATEST_HEADING')}
                        hideHeading
                        latestProductList={
                          categoryDetailsContext?.latestProducts
                        }
                        maxAvailableProducts={
                          categoryDetailsContext?.latestProductsCount
                        }
                        maxCardsLength={categoryDetailsContext?.maxCardsLength}
                        viewAllProducts={() => viewAllProducts()}
                      />
                    </Grid>
                  )}
                <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                  {!isCustomWorkflowCategory && (
                    <Button
                      variant='filledBtn'
                      sx={{ padding: '8px 24px' }}
                      onClick={() => viewAllProducts()}
                    >
                      {i18next.t('HOME_PAGE.HEADER_FOOTER.EXPLORE_ALL')}
                    </Button>
                  )}
                </Box>
              </Container>

              {filteredSubCategories.length > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    isDesktopDevice
                      ? styles['category-section-heading']
                      : styles['category-section-heading-responsive']
                  }
                >
                  <Typography variant='h5'>
                    {i18next.t('CATEGORY.CATEGORIES_HEADING')}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {(() => {
                  return filteredSubCategories?.map((chunk) => (
                    <Grid
                      container
                      key={chunk}
                      className={styles['sub-category-container']}
                    >
                      {chunk?.map((item) => (
                        <Grid
                          sx={{
                            background: getBackgroundImage(item)
                              ? `linear-gradient(0deg, ${alpha(
                                  theme.palette.primaryBackground.banner,
                                  0.8
                                )}, ${alpha(
                                  theme.palette.primaryBackground.banner,
                                  0.8
                                )}), url(${getBackgroundImage(item)}) no-repeat`
                              : theme.palette.primaryBackground.banner,
                            color: theme.palette.common.light,
                          }}
                          item
                          key={item?.category?.id}
                          className={getGridClassName()}
                        >
                          <Box
                            className={
                              isDesktopDevice
                                ? styles['sub-category-tag']
                                : isSmallDevice
                                ? styles['sub-category-tag-responsive']
                                : `${styles['sub-category-tag-tab-view']} ${styles['sub-category-tag-responsive']}`
                            }
                          >
                            <Typography variant='boldOnlyText'>
                              {item.category?.name}
                            </Typography>
                          </Box>
                          <Box
                            component={'div'}
                            className={
                              isSmallDevice
                                ? styles['view-tag-responsive']
                                : styles['view-tag']
                            }
                            onClick={() =>
                              goToListingPage(
                                item.category?.id,
                                item?.category?.name
                              )
                            }
                          >
                            <Typography
                              className={styles['cursor-style']}
                              component='smallText'
                              variant='p'
                            >
                              {i18next.t('CATEGORY.VIEW')}{' '}
                              {`${categoryDetailsContext?.categoryName}`}
                              {item.count > 1 &&
                              idConstants.api.categoryId ===
                                categoryDetailsContext?.categoryId
                                ? 's'
                                : ''}
                            </Typography>
                            <IconButton
                              className={`${styles['right-arrow']}`}
                              sx={{ color: 'common.light' }}
                              disableRipple
                            >
                              <ArrowForwardIosOutlinedIcon
                                sx={{
                                  fontSize: isSmallDevice
                                    ? '0.6rem'
                                    : '0.75rem',
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ));
                })()}
              </Grid>
              {!isSmallDevice &&
                categoryName &&
                featuresSection?.length > 0 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={
                        isDesktopDevice
                          ? styles['category-section-heading']
                          : styles['category-section-heading-responsive']
                      }
                    >
                      <Typography variant='h5'>
                        {i18next.t(`CATEGORY.FEATURES_HEADING`)}
                      </Typography>
                    </Grid>
                  </>
                )}
              {!isSmallDevice && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {(() => {
                    return (
                      <Grid
                        container
                        className={styles['sub-category-container']}
                      >
                        {featuresSection?.map((item) => featureContent(item))}
                      </Grid>
                    );
                  })()}
                </Grid>
              )}
              <Container
                sx={{ margin: '-16px !important' }}
                className={
                  isMediumDevice
                    ? styles['products-container-responsive']
                    : styles['products-container']
                }
              >
                {isSmallDevice && featuresSection?.length > 0 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ViewProductInfo
                      heading={i18next.t(`CATEGORY.FEATURES_HEADING`)}
                      fromFeatureSection={true}
                      featureSection={featuresSection}
                      featureContent={(item) => featureContent(item)}
                    />
                  </Grid>
                )}
              </Container>
            </Container>
            {categoryName && (
              <ContactSellerInfo
                {...props}
                displaySubText={i18next.t(`CATEGORY.${categoryName}.TEXT2`)}
              />
            )}
            <Container
              className={
                isMediumDevice
                  ? styles['products-container-responsive']
                  : styles['products-container']
              }
            >
              {categoryDetailsContext?.popularProducts?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ViewProductInfo
                    heading={i18next.t('CATEGORY.MOST_POPULAR')}
                    popularProductList={categoryDetailsContext?.popularProducts}
                    maxAvailableProducts={
                      categoryDetailsContext?.popularProductsCount
                    }
                    maxCardsLength={categoryDetailsContext?.maxCardsLength}
                    viewAllProducts={() => viewAllProducts()}
                  />
                </Grid>
              )}
            </Container>
          </>
        </Box>
      )}
    </>
  );
}

export default Category;
