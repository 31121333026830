import React, { useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box, Typography } from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import breadcrumbData from '../../../data/breadcrumbData';
import idConstants from '../../../data/idConstants';
import i18next from 'i18next';
import DeviceBreakPoints from '../../../deviceBreakPoints';

function BreadCrumb(props) {
  const { theme } = DeviceBreakPoints();

  const useStyles = makeStyles({
    breadCrumbStyling: {
      textDecoration: 'none',
      fontSize: '0.75rem',
      paddingRight: '10px',
      color: theme.palette.common.dark + '!important',
      '&:hover': {
        color: theme.palette.primary.dark + '!important',
      },
    },
    breadCrumbStylingFirstElement: {
      margin: '0rem',
      paddingRight: '10px',
    },
    breadCrumbHomeStyling: {
      fontSize: '0.75rem',
      paddingRight: '10px',
    },
    disableColor: {
      color: theme.palette.common.dark + '!important',
      cursor: 'auto',
    },
    // this below set of code is added as part of the MS-11693 bug
    // custom css for the right arrow of the breadcrumb item definied manualy
    separator: {
      fontSize: '12px !important',
      margin: '0 !important',
      verticalAlign: 'middle !important',
      display: 'inline-block !important',
      paddingRight: '6px !important',
      color: theme.palette.common.dark,
    },
    // custom css for the link of the bread crumb item to match the link css across all the breadcrumb item
    enablePointerEvents: {
      pointerEvents: 'all',
      paddingRight: '10px',
      color: theme.palette.common.dark + '!important',
      '&:hover': {
        color: theme.palette.primary.dark + '!important',
      },
    },
    // custom css for the breadcrumbItem text defined manually
    breadCrumbProductName: {
      cursor: 'pointer !important',
      paddingRight: '-4px',
      color: theme.palette.common.dark + '!important',
      '&:hover': {
        color: theme.palette.primary.dark + '!important',
      },
    },
  });

  const classes = useStyles();
  const [displayData, setdisplayData] = React.useState([]);
  useEffect(() => {
    const selectedData = [];
    const filteredData = [];
    const productInfo = [];
    if (props.landing === true) {
      filteredData.push(breadcrumbData.landing);
    } else {
      filteredData.push(breadcrumbData.landing);
      breadcrumbData.otherItems.forEach((item) => {
        const isApplicationProductPresent =
          idConstants.applicationBreadCrumb.path.substring(
            0,
            idConstants.applicationBreadCrumb.path.lastIndexOf('/')
          );

        if (
          window.location.pathname
            .toLowerCase()
            .indexOf(item.path.toLowerCase()) !== -1
        ) {
          // condition to restrict from data & product both being added
          if (
            !filteredData.find(
              (data) =>
                data.path ===
                `/${idConstants.data.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.api.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.enterpriseLicensing.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.managedServices.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.cloudInfrastructure.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.solutions.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.saas.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.paas.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.iaas.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.preDefinedWorkflow.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.customWorkflow.productCategoryName.toLowerCase()}` ||
                data.path ===
                `/${idConstants.methods.productCategoryName.toLowerCase()}` ||
                data.path === isApplicationProductPresent
            )
          )
            if (
              window.location.pathname.charAt(item.path.length) === '/' ||
              window.location.pathname.charAt(item.path.length) === ''
            )
              filteredData.push(item);
        }
      });
    }
    let propsalNumberIndexBreadcrumb = -1;
    filteredData.forEach((item, index) => {
      if (item.name === 'My Proposals') {
        // for checking if the breadcrumb is for the MyProposals page
        // getting the index for  MyProposals breadcrumb item
        // then changing the overview item in the index to proposal number
        propsalNumberIndexBreadcrumb = index;
      }
      if (item.name === 'Product') {
        if (item.path.startsWith(window.location.pathname)) {
          productInfo.push(item);
        }
      }
    });
    if (
      propsalNumberIndexBreadcrumb != -1 &&
      filteredData[propsalNumberIndexBreadcrumb + 1] != undefined
    ) {
      // changing the name of the item indexed next to 'My Proposal' to the 'Proposal Nunber'
      filteredData[propsalNumberIndexBreadcrumb + 1].name =
        window.location.pathname
          .substring(window.location.pathname.lastIndexOf('/') + 1)
          .split('id')[1];
    }

    if (productInfo.length !== 0) {
      filteredData.forEach((item) => {
        if (productInfo[0].name !== item.name) {
          selectedData.push(item);
        }
      });
      productInfo.forEach((item) => {
        selectedData.push(item);
      });
      setdisplayData(selectedData);
    } else {
      setdisplayData(filteredData);
    }
  }, [window.location.pathname]);
  const renderBreadCrumbItem = (item, props) => {
    // creating the breadcrumb items for the data product only,
    // also sending the props of props.dataProduct to render only for the data products
    // this doesnot render for the /search of data ... ie, is obtained on clicking of the data breadcrumbItem
    // this is created as the props.children logic was making the elipse for the "data>productname>tabname" entirely instead of just the last part of item or the particular item name
    // with this implementation the code is much flexible to write separate breadcrumb item definitions
    // this below set of code is added as part of the MS-11693 bug
    const currentUrl = window.location.pathname;
    if (props?.isProductCategory) {
      return (
        <>
          <Link
            component={RouterLink}
            to={props.linkStateCategory}
            underline='none'
            className={classes.enablePointerEvents}
          >
            <Typography variant='p' component='span'>
              {props.category}
            </Typography>
          </Link>
          <Typography component='p' variant='p' className={`${classes.separator}`}>
            <ArrowForwardIosOutlinedIcon sx={{ width: '0.5em !important', height: '0.5em !important', opacity: '0.5 !important' }} fontSize='small' />
          </Typography>
          <Link
            underline='none'
            to={
              props.linkStatebreadCrumbNavigateUrl
            }
            component={RouterLink}
            replace={true}
            className={classes.enablePointerEvents}
          >
            <Typography variant='p' component='span'>
              {props.productName}
            </Typography>
          </Link>
          <Typography component='p' variant='p' className={`${classes.separator}`}>
            <ArrowForwardIosOutlinedIcon sx={{ width: '0.5em !important', height: '0.5em !important', opacity: '0.5 !important' }} fontSize='small' />
          </Typography>
          <Typography variant='p' component='span' className={classes.breadCrumbProductName}>
            {props.finalTabName}
          </Typography>
        </>
      );
    } else if (
      currentUrl.includes('/services/cloud-infrastructure') ||
      currentUrl.includes('/services/peripheral-services') ||
      currentUrl.includes('/services/mobility-software-services')
    ) {
      const CategoryNameToDisplay = currentUrl.includes('/services/mobility-software-services')
        ? 'Mobility Software Services'
        : props.value;
      return (
        <>
          <Link
            component={RouterLink}
            to={{
              pathname: `/${idConstants.cloudServices.productCategoryName?.toLowerCase()}`,
              state: {
                text: '',
                categoryId: idConstants.cloudServices.categoryId,
                productCategoryName: idConstants.cloudServices.productCategoryName,
                solutionSearch: true,
                showSubCategories: true
              },
            }}
            underline='none'
            className={classes.enablePointerEvents}
          >
            <Typography variant='p' component='span' className={classes.breadCrumbProductName}>
              {idConstants.cloudServices.productCategoryName}
            </Typography>
          </Link>
          <Typography component='p' variant='p' className={`${classes.separator}`}>
            <ArrowForwardIosOutlinedIcon sx={{ width: '0.5em !important', height: '0.5em !important', opacity: '0.5 !important' }} fontSize='small' />
          </Typography>
          <Typography variant='p' component='span' className={classes.breadCrumbProductName}>
            {CategoryNameToDisplay}
          </Typography>
        </>
      );
    } else if (
      currentUrl.includes('/listing')
    ) {
      let subCategories = currentUrl.split('/')[2];
      let category = currentUrl.split('/')[1];
      let redirectUrl;
      let displayName;
      if (currentUrl.includes('/cloud-services') || currentUrl.includes('/services')) {
        redirectUrl = `/${category}/${subCategories}`;
        displayName = subCategories.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      }
      else {
        redirectUrl = item.path;
        displayName = item.name;
      }
      return (
        <>
          <Link
            component={RouterLink}
            to={{
              pathname: redirectUrl,
              state: props.state
            }}
            underline='none'
            className={classes.enablePointerEvents}
          >
            <Typography variant='p' component='span' className={classes.breadCrumbProductName}>
              {displayName}
            </Typography>
          </Link>
          <Typography component='p' variant='p' className={`${classes.separator}`}>
            <ArrowForwardIosOutlinedIcon sx={{ width: '0.5em !important', height: '0.5em !important', opacity: '0.5 !important' }} fontSize='small' />
          </Typography>
          <Typography variant='p' component='span' className={classes.breadCrumbProductName}>
            {i18next.t('SHARED.LISTING')}
          </Typography>
        </>
      );
    } else if (currentUrl.includes('/search')) {
      return (
        <>
          <Link
            component={RouterLink}
            to={{
              pathname: `/search${props?.textValue ? `?key=${props.textValue}` : ''}`,
              state: props?.state
            }}
            underline='none'
            className={classes.enablePointerEvents}
          >
            <Box component={'span'}>{i18next.t('FILTER_SECTION.SEARCH_TEXT')}</Box>
          </Link>
          <Box component={'p'} className={`${classes.separator}`}>
            <ArrowForwardIosOutlinedIcon sx={{ width: '0.5em !important', height: '0.5em !important', opacity: '0.5 !important' }} fontSize='small' />
          </Box>
          <Typography variant='p' component='span' className={classes.breadCrumbProductName}>{props?.productCategoryName}</Typography>
        </>
      );
    }
    // This below else is the default render for the breadcrumbitems with 'Data' , 'Product', 'API', 'Application', 'Feature'
    else {
      let linkValue;
      if (props.value) {
        if (
          props.value.toLowerCase() ===
          idConstants.api.productCategoryName.toLowerCase()
        ) {
          linkValue = idConstants.api.productCategoryName;
        } else {
          linkValue = props.value.replace(/\/$/, '');
        }
      } else {
        linkValue = props.children ?? item.name;
      }

      return (
        <Link
          component={RouterLink}
          to={{ pathname: item.path, state: props.state }}
          underline='none'
          className={classes.disableColor}
        >
          {linkValue}
        </Link>
      );

    }
  };

  const getBreadcrumbsItemClassName = (index) => {
    if (displayData?.length === 1) return classes.breadCrumbHomeStyling;
    else {
      if (index === 0) {
        return `${classes.breadCrumbStyling} ${classes.breadCrumbStylingFirstElement}`;
      } else {
        return `${classes.breadCrumbStyling}`;
      }
    }
  };

  return (
    <React.Fragment>
      {displayData.map((item, index) => {
        return (
          <BreadcrumbsItem
            to={item.path || item.prodDetailFlowUrl}
            className={
              ()=> getBreadcrumbsItemClassName(index)
            }
            sx={{ color: 'common.dark' }}
            key={item.path}
          >
            {(item.name === 'Data' || item.name === 'Search' ||
              item.name === 'Product' ||
              item.name === 'API' ||
              item.name === 'Application' ||
              item.name === 'Enterprise Licensing' ||
              item.name === 'Cloud Infrastructure' ||
              item.name === 'Managed Services' ||
              item.name === 'Solutions' ||
              item.name === 'Cloud Services' ||
              item.name === 'Services' ||
              item.name === 'Hardware' ||
              item.name === 'Professional Services' ||
              item.name === idConstants.saas.productCategoryName ||
              item.name === idConstants.paas.productCategoryName ||
              item.name === idConstants.iaas.productCategoryName ||
              item.name === idConstants.preDefinedWorkflow.productCategoryName ||
              item.name === idConstants.customWorkflow.productCategoryName ||
              item.name === idConstants.methods.productCategoryName)
              ? renderBreadCrumbItem(item, props)
              : item.name}
          </BreadcrumbsItem>
        );
      })}
    </React.Fragment>
  );
}

export default BreadCrumb;
