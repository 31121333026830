import React , { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, Typography, Link, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import i18next from 'i18next';
import style from './contact-support-dialog.module.scss';

function ContactSupportDialog(props) {
    const [supportData, setSupportData]= useState({});
    const supportHeading = [ i18next.t('SUPPORT_TOOLS.L1_SUPPORT'), i18next.t('SUPPORT_TOOLS.L1_ESCALATION'), i18next.t('SUPPORT_TOOLS.L2_ESCALATION') ];

    useEffect(() => {
        setSupportData(props.supportData);
    },[props.supportData]);

    return (
        <>
            {supportData.length > 0 &&
                <Dialog
                    open={props.openDialog}
                    PaperProps={{
                        square: true,
                    }}
                    sx={{
                        backdropFilter: 'blur(2px)',
                        zIndex: 10000
                    }}>
                        <CloseIcon sx={{color:'secondary.dark', cursor: 'pointer'}} title={i18next.t('FORM.CLOSE')} className={style['dialog-close-icon']} onClick={() => props.setOpenDialog(false)} />
                        <DialogTitle className= {style['text-center-alignment']}>
                            <Typography variant='h4' lineHeight={'60px'}>{i18next.t('HOME_PAGE.HEADER_FOOTER.SUPPORT')}</Typography>
                            <Typography className= {style['text-center-alignment']}>{i18next.t('SUPPORT_TOOLS.BODY')}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                {supportData?.map((data, index) => (
                                    <Grid container sx={{ borderColor: 'common.dark'}} className={style['dialog-grid-container']} key={data.email}>
                                    <Grid item xs={12} md={9} sm={9} className={style['left-content']}>
                                        <Typography variant='h6'>{supportHeading[index]}</Typography>
                                        <Typography mt={1}>{data.name}</Typography>
                                        <Link underline= 'always' sx={{ pointerEvents: 'none'}}>
                                            {data.email}
                                        </Link>
                                        <Typography mt={2}>{data.contactNumber}</Typography>
                                        <Link href= {data.contactUrl} target='_blank' underline= 'always'>
                                            {data.contactUrl}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={3} className={style['right-content']}>
                                        <Link href= {'mailTo:'+ data.email}>
                                            <Button variant='filledBtn'>{i18next.t('SUPPORT_TOOLS.WRITE_TO_US')}</Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                </Dialog>
            }
        </>
    );
};

export default (ContactSupportDialog);