// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb_separator-style__VGfXl {
  font-size: 12px !important;
  margin: 0 !important;
  vertical-align: middle !important;
  display: inline-block !important;
  padding-right: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Breadcrumb/breadcrumb.module.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,oBAAA;EACA,iCAAA;EACA,gCAAA;EACA,6BAAA;AACJ","sourcesContent":[".separator-style {\n    font-size: 12px !important;\n    margin: 0 !important;\n    vertical-align: middle !important;\n    display: inline-block !important;\n    padding-right: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator-style": `breadcrumb_separator-style__VGfXl`
};
export default ___CSS_LOADER_EXPORT___;
