// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion_content__a8pq5 {
  padding: 0.5rem;
}

.accordion_content-parent__wYEAd {
  overflow: hidden;
  transition: height 0.8s ease-in;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/CustomAccordion/accordion.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,gBAAA;EACA,+BAAA;AACJ","sourcesContent":[".content {\n    padding: 0.5rem;\n}\n    \n.content-parent {\n    overflow: hidden;\n    transition: height 0.8s ease-in;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `accordion_content__a8pq5`,
	"content-parent": `accordion_content-parent__wYEAd`
};
export default ___CSS_LOADER_EXPORT___;
